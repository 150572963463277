import React from "react";
import LoadingOverlay from "react-loading-overlay";
import { CardComponent } from "../../Components/Card/CardComponent";

function TeamsList() {
  return (
    <LoadingOverlay
      active={false}
      spinner
      text="This is being built - right this very moment. Release is scheduled for Friday..."
      styles={{
        overlay: (base) => ({
          ...base,
          background: "rgba(255, 255, 255, 0.8)",
        }),
        content: (base) => ({
          ...base,

          color: "black",
        }),

        wrapper: {
          width: "100%",
          height: "100%",
        },
      }}
    >
      <div>
        <CardComponent />
      </div>
    </LoadingOverlay>
  );
}

export default TeamsList;
