import { useState } from "react";
import { UserAuth } from "../../Context/AuthContext";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Formik } from "formik";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../API/Firebase/firebase";
import { useEffect } from "react";
/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
export default function BasicCardRegistration() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  let [searchParams, setSearchParams] = useSearchParams();
  const activationCode = searchParams.get("c");

  useEffect(() => {
    //console.log("params", activationCode);
  }, [activationCode]);

  const { id } = useParams();

  const { createBasicUser } = UserAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    // Prevent page from submitting and refreshing
    e.preventDefault();
    setError("");
    const getCard = async () => {
      console.log("getCard started");

      // console.log("userID", user.uid);
      const cardDataRef = doc(db, "cards", id);
      const cardDataSnapshot = await getDoc(cardDataRef);
      if (
        cardDataSnapshot.exists() &&
        cardDataSnapshot.data().activationCode === activationCode
      ) {
        // console.log("Card exists", cardDataSnapshot.data());
        const card = cardDataSnapshot.data();
        // console.log("activationCode on card", activationCode);
        await createBasicUser(email, password, card);
        // Redirect to login page
        navigate(`/cards`);
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }
    };
    getCard();
  };

  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-gray-50">
        <body class="h-full">
        ```
      */}
      <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-12 w-auto"
            src="https://firebasestorage.googleapis.com/v0/b/hypa-27b28.appspot.com/o/assets%2FhypacardIcon.png?alt=media&token=76590290-74ed-49c4-86aa-dae9f5ff20f5"
            alt="Your Company"
          />
          <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
            Your Hypacard is waiting
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            {" "}
            <a
              href="#"
              className="font-medium text-blue-600 hover:text-blue-500"
            >
              Register to get access
            </a>
          </p>
        </div>

        <div className="mt-2 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-4 px-4 shadow sm:rounded-lg sm:px-10">
            <Formik
              initialValues={{
                cardID: id,
                activationCode: activationCode,
                email: "",
                password: "",
              }}
              validate={(values) => {
                const errors = {};
                if (!values.email) {
                  errors.email = "Required";
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                    values.email
                  )
                ) {
                  errors.email = "Invalid email address";
                }
                return errors;
              }}
              onSubmit={handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                isSubmitting,
                /* and other goodies */
              }) => (
                <form onSubmit={handleSubmit} className="">
                  <div className="pb-4 hidden">
                    <label
                      htmlFor="cardID"
                      className="block text-sm font-semibold text-gray-700"
                    >
                      Card ID : {id}
                    </label>
                    <div className="mt-1 hidden">
                      <input
                        id="cardID"
                        name="cardID"
                        type="cardID"
                        autoComplete="cardID"
                        value={id}
                        required
                        className="block w-full text-center appearance-none rounded-md   px-3 py-2 placeholder-gray-400 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                      />
                    </div>
                  </div>

                  <div className="hidden">
                    <label
                      htmlFor="activationCode"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Card Activation Code
                    </label>
                    <div className="mt-1">
                      <input
                        value={activationCode}
                        id="activationCode"
                        name="activationCode"
                        type="activationCode"
                        autoComplete="activationCode"
                        required
                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Email address
                    </label>
                    <div className="mt-1">
                      <input
                        onChange={(e) => setEmail(e.target.value)}
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        required
                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                      />
                    </div>
                  </div>

                  <div>
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Password
                    </label>
                    <div className="mt-1">
                      <input
                        onChange={(e) => setPassword(e.target.value)}
                        id="password"
                        name="password"
                        type="password"
                        autoComplete="current-password"
                        required
                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                      />
                    </div>
                  </div>
                  <div className="h-6">
                    {error && (
                      <p className="text-red-500 text-xs text-center">
                        This email is already in use. Please try another.
                      </p>
                    )}
                  </div>

                  <div>
                    <button
                      type="submit"
                      className="flex w-full justify-center rounded-md border border-transparent bg-blue-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    >
                      Claim your account
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
}
