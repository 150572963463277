/*
  This requires following config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { EnvelopeIcon, PhoneIcon } from "@heroicons/react/20/solid";
import { useEffect, useState } from "react";

// API USER DATA
import {
  doc,
  onSnapshot,
  where,
  collection,
  query,
  getDocs,
  getDoc,
} from "firebase/firestore";
import { auth, db } from "../../../API/Firebase/firebase";
import { UserAuth } from "../../../Context/AuthContext";
import { Outlet, useNavigate } from "react-router-dom";
import { ButtonPrimary } from "../../../Components/Button/Index";
import PopUpModal from "../../../Components/Modal/PopupModal";

import Tabs from "../../../Components/Navigation/Tabs";
import { Filter } from "./Filter";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ProfileNavLayout() {
  const navigate = useNavigate();
  // Event Data

  // Count Active Events
  const [activeEventCount, setActiveEventCount] = useState(0);

  const [isLoading, setIsLoading] = useState(true);
  // Modal: Activate Card
  const [openActivateModal, setOpenActivateModal] = useState(false);

  // API: User's Cards
  const { user, userProfile } = UserAuth();
  // console.log("USERINFO", user);
  const [cards, setCards] = useState([]);
  const [cardIDs, setCardIDs] = useState([]);
  const [userData, setUserData] = useState([]);
  const [dataToggle, setDataToggle] = useState(false);
  const [cardCount, setCardCount] = useState(0);
  const getUser = async () => {
    const userRef = doc(db, "accounts", user.uid);
    const userSnap = await getDoc(userRef);
    //console.log("##USER DATA: ", userSnap.data().cards);
    if (dataToggle === false) {
      if (userSnap.exists()) {
        // console.log("##User data SNAP: ", userSnap.data());
        setDataToggle(true);
        setIsLoading(false);
        setUserData(userSnap.data());

        const funcCardIDs = userSnap.data();
        // console.log("funcCardIDs", funcCardIDs);
        setCardIDs(funcCardIDs);
        // setCardCount(userSnap.data().cards.length);
        console.log("##USER CARDS: ", userSnap.data().cards);

        return funcCardIDs;
      } else {
        console.log("##error");
        setIsLoading(false);
      }
    }
  };

  const getCards = async (funcCardIDs) => {
    // console.log("P111111RINTING CARDS");
    const cardRef = collection(db, "cards");
    // console.log("WORKING - CARD ID1s", funcCardIDs);

    const cardQuery = query(cardRef, where("cardID", "in", funcCardIDs));
    // console.log("WORKING - CARD IDs2", funcCardIDs);
    const cardSnap = await getDocs(cardQuery);

    cardSnap.forEach((doc) => {
      // console.log("##CARD DATA: ", doc.data());
      setCards((cards) => [...cards, doc.data()]);
    });
  };

  if (dataToggle === false) {
    // console.log("##USER DATA: ", userData);
    getUser().then((funcCardIDs) => getCards(funcCardIDs));
  }

  const tabs = [
    {
      name: "Cards",
      href: "/cards",
      count: cardCount,
      current: false,
    },
    {
      name: "Group Settings",
      href: "/group",
      count: 0,
      current: false,
      role: 100,
    },
  ];

  const [activeTab, setActiveTab] = useState(tabs[0].name);

  useEffect(() => {
    console.log("activeTab", activeTab);
  }, [activeTab]);

  return (
    <>
      <div>
        <div className="md:grid md:grid-cols-3 md:gap-6"></div>
      </div>

      <div className="md:col-span-1">
        <div className="px-4 sm:px-0 text-start justify-between flex -mt-4 sm:mt-0">
          <h3 className="text-4xl font-medium text-gray-900">My Cards</h3>

          <div className="space-x-2 justify-end flex hidden sm:block">
            {/* <ButtonPrimary
              title={"Connect Cards"}
              handleClick={() => setOpenActivateModal(true)}
            /> */}
            {user?.role === 100 ? (
              <ButtonPrimary
                title={"Add Card"}
                color={"blue"}
                textColor={"white"}
                handleClick={() => {
                  navigate("/cards/new");
                }}
              />
            ) : null}
          </div>
        </div>
      </div>

      <div className="block" aria-hidden="true">
        <div className="py-2"></div>
      </div>

      <div className="pb-2">
        <div className="">
          <div className="">
            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
              <Tabs tabs={tabs} setActiveTab={setActiveTab} />
            </nav>
            <div>{activeTab === "Cards" && <Filter />}</div>
          </div>
        </div>
      </div>
      <Outlet context={{ cards, isLoading }} />
    </>
  );
}
