import {Chart as ChartJS ,BarElement,LineElement, CategoryScale, LinearScale, PointElement, Tooltip } from 'chart.js'
import React, { useEffect } from 'react'
import { Bar, Line } from 'react-chartjs-2'

ChartJS.register(
    BarElement,
    LineElement,
    CategoryScale,
    LinearScale,
    PointElement,
    Tooltip
)

export const BarChart = () => {
 
    return (
      
       
                <div style={{ width: '100%', height: '200px'}}>
            <Bar
                data={{
                        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July',
                            'September', 'October', 'November', 'December'],
                    datasets: [
                        {
                            label: 'Connections',
                            data: [0, 10, 5, 2, 20, 30, 45, 4, 10, 5, 2, 5],
                            fill: false,
                            backgroundColor: '#4E6AFF',
                            borderColor: '#4E6AFF',
                        },
                    ],
                    }}
                   
                    options={{
                     maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            display: true,
                        },
                    },
                    
                }}
            
                    />
                    </div> 
       
       
    )
}