import React, { useState } from "react";
import { useEffect } from "react";
import { UserAuth } from "../../Context/AuthContext";

const AnalyticsIndex = () => {
  return (
    <div>
      <Statistics />
    </div>
  );
};

export default AnalyticsIndex;

function Statistics() {
  // API User's Cards

  const { getCards, cards } = UserAuth();

  useEffect(() => {
    getCards();
  }, []);
  useEffect(() => {
    getCardAnalytics(cards);
  }, [cards]);

  const getCardAnalytics = async (data) => {
    setCardOpenSummary(0);
    setContactsAddedSummary(0);
    data?.map((doc) => {
      // console.log("doc", doc);
      if (doc?.counters?.cardVisits !== undefined) {
        setCardOpenSummary((prev) => prev + doc?.counters?.cardVisits);
        // console.log("cardVisits", doc?.counters?.cardVisits);
      } else {
        setCardOpenSummary((prev) => prev + 0);
        // console.log("cardVisits", doc?.counters?.cardVisits);
      }
      if (doc?.counters?.contactsAdded !== undefined) {
        setContactsAddedSummary((prev) => prev + doc?.counters?.contactsAdded);
      } else {
        setContactsAddedSummary((prev) => prev + 0);
      }
      return doc;
    });
  };

  const [cardOpenSummary, setCardOpenSummary] = useState(0);
  const [contactsAddedSummary, setContactsAddedSummary] = useState(0);

  function isNumeric(x) {
    let value = !isNaN(x) ? x : parseFloat(x.replace(/[\$,]/g, ""));
    return { isNum: !isNaN(value), value };
  }

  function sortAll(data, orderDirection, valueToOrderBy) {
    // Filter the nulls in an array and the rest in another
    let nulls = data.filter((item) => item[valueToOrderBy] == null);
    let toSort = data.filter((item) => item[valueToOrderBy]);

    // Sort the non-null values
    let sorted = toSort.sort((a, b) => {
      // Check if both values are numeric
      let aa = isNumeric(a[valueToOrderBy]);
      let bb = isNumeric(b[valueToOrderBy]);

      // If numerics
      if (aa.isNum && bb.isNum) {
        return aa.value - bb.value;
      }

      // If strings
      return a[valueToOrderBy].toLowerCase() > b[valueToOrderBy].toLowerCase()
        ? 1
        : -1;
    });

    // The sorting direction
    if (orderDirection === "desc") {
      sorted.reverse();
    }

    //  Add the nulls at the end of the returned array
    return sorted.concat(nulls);
  }

  return (
    <div className="pb-20">
      <div className="md:col-span-1">
        <div className="px-4 sm:px-0 text-start">
          <h3 className="text-4xl font-medium leading-6 text-gray-900">
            Analytics
          </h3>
        </div>
      </div>
      <div className="text-left mt-5">
        <p className="font-semibold py-2">Totals</p>
        <dl className="grid grid-cols-1 gap-5 sm:grid-cols-3">
          <div className="overflow-hidden rounded-lg bg-indigo-800 px-4 py-5 shadow sm:p-6">
            <dt className="truncate text-sm font-medium text-white">
              Total Profile Opens
            </dt>
            <dd className="mt-1 text-3xl font-semibold tracking-tight text-white">
              {cardOpenSummary > 0 ? cardOpenSummary : 0}
            </dd>
          </div>
          <div className="overflow-hidden rounded-lg bg-indigo-800 px-4 py-5 shadow sm:p-6">
            <dt className="truncate text-sm font-medium text-white">
              Total Contacts Added
            </dt>
            <dd className="mt-1 text-3xl font-semibold tracking-tight text-white">
              {contactsAddedSummary > 0 ? contactsAddedSummary : 0}
            </dd>
          </div>
          <div className="overflow-hidden rounded-lg bg-indigo-800 px-4 py-5 shadow sm:p-6">
            <dt className="truncate text-sm font-medium text-white">
              Contact Acquisition Rate
            </dt>
            <dd className="mt-1 text-3xl font-semibold tracking-tight text-white">
              {contactsAddedSummary > 0 ? (
                <>
                  {((contactsAddedSummary / cardOpenSummary) * 100).toFixed(1)}
                </>
              ) : (
                <>NA</>
              )}
            </dd>
          </div>
        </dl>
      </div>

      {cards
        .slice()
        .sort((a, b) => b?.counters?.cardVisits - a?.counters?.cardVisits)
        .map((card) => (
          <div className="text-left mt-5">
            <div className="overflow-hidden rounded-lg bg-gray-100 px-4 py-5 shadow sm:p-6 h-10 flex items-center mb-2 ">
              {card?.profilePictureUrl !== "" ? (
                <img
                  className="h-8 w-8 rounded-full ring-2 ring-white sm:h-9 sm:w-9"
                  src={card?.profilePictureUrl}
                  alt=""
                />
              ) : (
                <div className="w-8 h-8 rounded-full ring-2 ring-white sm:h-9 sm:w-9 bg-blue-800 flex items-center justify-center">
                  <p className="text-white text-sm font-bold">
                    {card?.firstName?.substring(0, 1)}{" "}
                    {card?.lastName?.substring(0, 1)}
                  </p>
                </div>
              )}
              <p className="ml-2 text-indigo-800">
                {card?.firstName} {card?.lastName}
              </p>

              {card?.email && (
                <p className="text-indigo-800 ml-2 flex flex-row flex justify-center items-center text-center">
                  {" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-5 h-5 mr-1 ml-2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                    />
                  </svg>
                  {card?.email}
                </p>
              )}
            </div>
            <dl className="grid grid-cols-1 gap-5 sm:grid-cols-3">
              <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                <dt className="truncate text-sm font-medium text-gray-500">
                  Total Profile Opens
                </dt>
                <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
                  {card?.counters?.cardVisits > 0 ? (
                    <>{card?.counters?.cardVisits}</>
                  ) : (
                    <>0</>
                  )}
                </dd>
              </div>
              <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                <dt className="truncate text-sm font-medium text-gray-500">
                  Total Contacts Added
                </dt>
                <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
                  {card?.counters?.contactsAdded > 0 ? (
                    <>{card?.counters?.contactsAdded}</>
                  ) : (
                    <>0</>
                  )}
                </dd>
              </div>
              <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                <dt className="truncate text-sm font-medium text-gray-500">
                  Contact Acquisition Rate
                </dt>
                <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
                  {card?.counters?.cardVisits > 0 &&
                  card?.counters?.contactsAdded > 0 ? (
                    <>
                      {(
                        (card?.counters?.contactsAdded /
                          card?.counters?.cardVisits) *
                        100
                      ).toFixed(1) + "%"}
                    </>
                  ) : (
                    <>NA</>
                  )}
                </dd>
              </div>
            </dl>
          </div>
        ))}
    </div>
  );
}
