import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
// Animation on card load
import { motion } from "framer-motion";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";

import {
  Bars3Icon,
  CalendarDaysIcon,
  CalendarIcon,
  ChartBarIcon,
  HomeIcon,
  ListBulletIcon,
  MagnifyingGlassCircleIcon,
  MapIcon,
  MegaphoneIcon,
  UserGroupIcon,
  WrenchIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";

export default function BasicAppLayout() {
  const navigate = useNavigate();

  return (
    <>
      <BrowserView>
        <p>Open on mobile to activate</p>
      </BrowserView>
      <MobileView>
        <div className="h-screen pt-8">
          <motion.div
            initial={{ opacity: 0, scale: 1 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.4 }}
          >
            <div className="overflow-y-auto h-full">
              <Outlet />
            </div>
          </motion.div>
          <div className="fixed bottom-0 w-full bg-white z-100 h-14 w-full">
            <div className="grid grid-cols-3 md:gap-6 w-full  flex justify-start items-start ">
              <div
                onClick={() => {
                  navigate("/app/edit");
                }}
                className="col-span-1 shadow-lg h-full h-14 text-center flex justify-center items-center"
              >
                Profile
              </div>
              <div
                onClick={() => {
                  navigate("/app");
                }}
                className="col-span-1 shadow-lg h-14 h-full text-center flex justify-center items-center"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-5 h-5 mr-1"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3.75 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 013.75 9.375v-4.5zM3.75 14.625c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5a1.125 1.125 0 01-1.125-1.125v-4.5zM13.5 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 0113.5 9.375v-4.5z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6.75 6.75h.75v.75h-.75v-.75zM6.75 16.5h.75v.75h-.75v-.75zM16.5 6.75h.75v.75h-.75v-.75zM13.5 13.5h.75v.75h-.75v-.75zM13.5 19.5h.75v.75h-.75v-.75zM19.5 13.5h.75v.75h-.75v-.75zM19.5 19.5h.75v.75h-.75v-.75zM16.5 16.5h.75v.75h-.75v-.75z"
                  />
                </svg>
                Share
              </div>
              <div
                onClick={() => {
                  navigate("/app/contacts");
                }}
                className="col-span-1 shadow-lg h-14 text-center flex justify-center items-center"
              >
                <ListBulletIcon className="mr-1 h-6 w-6" aria-hidden="true" />
                Contacts
              </div>
            </div>
          </div>
        </div>
      </MobileView>
    </>
  );
}
