import React from "react";
import { useNavigate } from "react-router-dom";
import TeamsList from "./TeamsList";

function TeamsIndex() {
  const navigate = useNavigate();
  return (
    <div>
      <div className="sm:flex sm:items-center text-left pb-10">
        <div className="sm:flex-auto">
          <h1 className="text-4xl font-medium leading-6 text-gray-900">
            Teams
          </h1>
          <p className="mt-2 text-normal text-gray-700">
            Manage and set networking goals across your teams.
          </p>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <button
            onClick={() => {
              navigate("/teams/create");
            }}
            type="button"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:w-auto"
          >
            Create Team
          </button>
        </div>
      </div>
      <TeamsList />
    </div>
  );
}

export default TeamsIndex;
