import { ErrorMessage, Form, Formik, useField } from "formik";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../../../Context/AuthContext";
import * as Yup from "yup";
import hypacardIcon from "../../../Assets/Images/hypacardIcon.png";
import { nanoid } from "nanoid";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CheckIcon } from "@heroicons/react/24/outline";
import ProfilePictureImage from "../../../Components/Profile/ProfilePictureImage";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../API/Firebase/firebase";
/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/

const CustomInputField = ({ placeholder, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <div className="col-span-6 sm:col-span-3 space-y-1 pb-1">
      <label
        htmlFor="email"
        className="block text-sm font-medium leading-6 text-gray-900 text-left"
      >
        {props.label}
      </label>
      <div className="mt-2">
        <input
          id={field.name}
          name={field.name}
          type={field.type}
          placeholder={placeholder}
          {...field}
          {...props}
          required
          className="block w-full rounded-lg border-0 py-6 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6 bg-gray-50"
        />
        <p className="text-red-500 text-xs text-left h-8 pt-2">
          {meta.touched && meta.error && <ErrorMessage name={field.name} />}
        </p>
      </div>
    </div>
  );
};

const CustomDateSelectField = ({ placeholder, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <div className="col-span-6 sm:col-span-3 space-y-1 pb-1">
      <label
        htmlFor="email"
        className="block text-sm font-medium leading-6 text-gray-900 text-left"
      >
        {props.label}
      </label>
      <div className="mt-2">
        <DatePicker
          id={field.name}
          name={field.name}
          type={field.type}
          placeholder={placeholder}
          {...field}
          {...props}
          required
          showTimeSelect
          dateFormat="MMMM d, yyyy h:mm aa"
          className="block w-full rounded-lg border-0 py-6 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6 bg-gray-50"
        />
        <p className="text-red-500 text-xs text-left h-8 pt-2">
          {meta.touched && meta.error && <ErrorMessage name={field.name} />}
        </p>
      </div>
    </div>
  );
};

const LoadingSpinner = () => {
  return (
    <div role="status">
      <svg
        aria-hidden="true"
        class="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-white"
        viewBox="0 0 100 101"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
          fill="currentColor"
        />
        <path
          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
          fill="currentFill"
        />
      </svg>
      <span class="sr-only">Loading...</span>
    </div>
  );
};

export default function CreateEvent() {
  // Team Members
  const { cards, userProfile } = UserAuth();
  const organizationID = userProfile?.organizations[0];
  const [selectedCardsAlert, setSelectedCardsAlert] = useState(false);
  const [selectedCards, setSelectedCards] = useState([]);
  const [cardIDs, setCardIDs] = useState([]);
  // Testing
  useEffect(() => {
    console.log("SelectedCards", selectedCards);
  }, [selectedCards]);

  const [error, setError] = useState("");
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const created = new Date();

  const eventID = nanoid();

  const EVENT_DATA = {
    kind: "calendar#event",
    id: eventID,
    eventID: eventID,
    status: "confirmed",
    created: created,
    updated: created,
    organizationID: organizationID,
    summary: "",
    description: "",
    location: "",
    creator: {
      email: "",
      displayName: "",
      id: "",
    },
    organizer: {
      email: "",
      displayName: "",
      id: "",
      cardID: "",
    },
    start: {
      dateTime: "",
      timeZone: "",
    },
    end: {
      dateTime: "",
      timeZone: "",
    },
    attendees: [
      {
        email: "",
        displayName: "",
        id: "",
        cardID: "",
      },
    ],
  };

  const [eventForm, setEventForm] = useState(EVENT_DATA);
  const { summary, description, location, attendees } = eventForm;

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setEventForm({ ...eventForm, [name]: value });
    console.log("EventForm", eventForm);
  };

  const formValidation = Yup.object({
    summary: Yup.string().required("Required"),
    description: Yup.string().required("Required"),
  });

  const handleSubmit = async (e) => {
    setLoading(true);
    // Add form data to the eventForm state
    const newEvent = {
      ...eventForm,
      start: {
        dateTime: startDate,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
      end: {
        dateTime: endDate,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
      attendees: selectedCards,
      cardIDs: cardIDs,
    };
    // console.log("New Event", newEvent);
    // Call https callable createEvent
    const createEvent = httpsCallable(functions, "createEvent");
    try {
      console.log("Creating Event...", newEvent);
      createEvent(newEvent).then((result) => {
        navigate(`/events/${newEvent.eventID}?eventID=${newEvent.eventID}`, {
          state: { eventID: newEvent.eventID },
        });
        console.log("Event Created", result);
        setLoading(false);
      });
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  return (
    <>
      <div className="flex min-h-full">
        <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24 w-full">
          <div className="mx-auto w-full  ">
            <div className="flex flex-col items-start justify-center w-full">
              <img
                className="h-10 w-auto"
                src={hypacardIcon}
                alt="Your Company"
              />
              <h2 className="mt-6 text-4xl font-bold tracking-tight text-gray-900 text-left">
                Create a<br />
                Hypacard Event
              </h2>
            </div>

            <Formik
              enableReinitialize
              initialValues={{ summary, description, location }}
              onSubmit={handleSubmit}
              validationSchema={formValidation}
            >
              {(formik) => (
                <Form className="space-y-2" onSubmit={formik.handleSubmit}>
                  <div className="mt-8 w-full">
                    <div className="mt-6 grid grid-cols-3 gap-4">
                      <div className="mt-6  col-start-1 col-span-2">
                        <CustomInputField
                          key="summary"
                          label="EVENT TITLE"
                          name="summary"
                          type="text"
                          placeholder="Title"
                          onChange={handleOnChange}
                        />
                        <CustomInputField
                          key="description"
                          label="DESCRIPTION"
                          name="description"
                          type="text"
                          placeholder="Description"
                          onChange={handleOnChange}
                        />
                        <CustomInputField
                          key="location"
                          label="LOCATION"
                          name="location"
                          type="text"
                          placeholder="Location"
                          onChange={handleOnChange}
                        />
                      </div>
                      <div className="mt-6 col-start-3 col-span-1">
                        <CustomDateSelectField
                          key="start"
                          label="START DATE"
                          name="start"
                          type="text"
                          placeholder="Start Date"
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                        />
                        <CustomDateSelectField
                          key="end"
                          label="END DATE"
                          name="end"
                          type="text"
                          placeholder="End Date"
                          selected={endDate}
                          onChange={(date) => setEndDate(date)}
                        />
                      </div>
                    </div>

                    <div className="mt-6">
                      <p className="block text-sm font-medium leading-6 text-gray-900 text-left">
                        ADD CARD MEMBERS TO EVENT
                      </p>
                      <div className="w-full text-left py-4 flex flex-row flex-wrap pt-2">
                        {cards.map((card) => (
                          <div
                            onClick={() => {
                              // If card exists in selectedCards, then remove it
                              if (selectedCards.includes(card)) {
                                setSelectedCards(
                                  selectedCards.filter((c) => c !== card)
                                );

                                if (selectedCards.length === 0) {
                                  setSelectedCardsAlert(true);
                                }

                                return;
                              }
                              // Else add it
                              else {
                                setSelectedCardsAlert(false);
                                setSelectedCards([...selectedCards, card]);
                                setCardIDs([...cardIDs, card.cardID]);
                              }
                            }}
                            className="pb-8"
                          >
                            <div
                              // Format so that if card exists in selectedCards, then it has opacity 100
                              className={`${
                                selectedCards.includes(card)
                                  ? "opacity-100"
                                  : "opacity-50"
                              }  w-60 text-left mx-2 flex flex-row justify-start text-center items-center cursor-pointer hover:opacity-100
                      hover:ring-2 hover:ring-blue-500 hover:ring-offset-4 rounded-full`}
                            >
                              <div
                                className="relative h-20  rounded-full 
                                "
                              >
                                <div
                                  // Format so that if card exists in selectedCards, then it has a blue tick in the top right corner of the image
                                  className={`${
                                    selectedCards.includes(card)
                                      ? "opacity-100"
                                      : "opacity-0"
                                  } z-20 absolute top-0 left-0 h-6 w-6 rounded-full bg-green-500 flex items-center justify-center`}
                                >
                                  <CheckIcon
                                    className="h-4 w-4 text-white"
                                    aria-hidden="true"
                                  />
                                </div>
                                <ProfilePictureImage
                                  height="20"
                                  card={card}
                                  noLogo={true}
                                  noBorder={true}
                                />
                              </div>
                              <div className="flex flex-col justify-start items-center">
                                <div className="pt-2 text-sm truncate ml-2">
                                  {card?.firstName} {`\n`}
                                  {card?.lastName}
                                </div>
                                {card?.role ? (
                                  <div className="truncate mt-2 text-sm text-white font-semibold p-1 px-2 bg-blue-700 rounded-full">
                                    {card?.role}
                                  </div>
                                ) : (
                                  <div className="h-8"></div>
                                )}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className="flex items-center justify-between mt-4">
                        <button
                          type="submit"
                          className="flex w-full justify-center items-center rounded-3xl bg-indigo-600 px-3 h-16 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                          {loading ? <LoadingSpinner /> : "Create Event"}
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
}
