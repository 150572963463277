import { Fragment, useState } from "react";
import { UserAuth } from "../../Context/AuthContext";
import { Dialog, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  CalendarDaysIcon,
  CalendarIcon,
  ChartBarIcon,
  HomeIcon,
  ListBulletIcon,
  MagnifyingGlassCircleIcon,
  MapIcon,
  MegaphoneIcon,
  Square3Stack3DIcon,
  Squares2X2Icon,
  UserGroupIcon,
  WrenchIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import companyLogo from "../../Assets/Images/hypacardLogoFull.png";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { FeedbackFish } from "@feedback-fish/react";

const navigation = [
  // {
  //   name: "Dashboard",
  //   href: "/dashboard",
  //   icon: Squares2X2Icon,
  //   current: false,
  // },
  { name: "Cards", href: "/cards", icon: Square3Stack3DIcon, current: false },
  {
    name: "Teams",
    href: "/teams",
    icon: UserGroupIcon,
    current: false,
  },

  {
    name: "Events",
    href: "/events",
    icon: CalendarDaysIcon,
    current: false,
  },

  {
    name: "Connections",
    href: "/contacts",
    icon: ListBulletIcon,
    current: false,
  },
  {
    name: "Analytics",
    href: "/analytics",
    icon: ChartBarIcon,
    current: false,
  },
  { name: "Settings", href: "/settings", icon: WrenchIcon, current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function AppRoute() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { user, logout, userProfile } = UserAuth();

  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await logout();
      navigate("/");
      // console.log("You are logged out");
    } catch (e) {
      // console.log("Error signing out", e.message);
    }
  };

  console.log("USER PROFILE", userProfile);

  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-white">
        <body class="h-full overflow-hidden">
        ```
      */}
      <div className="flex h-full w-full">
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-40 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-white focus:outline-none">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                      <button
                        type="button"
                        className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="h-0 flex-1 overflow-y-auto pt-5 pb-4">
                    <div className="flex flex-shrink-0 items-center px-4">
                      <img
                        className="h-12 w-auto"
                        src={companyLogo}
                        alt="Your Company"
                      />
                    </div>
                    <nav aria-label="Sidebar" className="mt-5">
                      <div className="space-y-1 px-2">
                        <button className="flex flex-col items-start px-2 py-2 text-base text-left font-medium rounded-md w-full text-gray-600 hover:bg-gray-50 hover:text-gray-900  ">
                          Organisation ID:
                          <div className="my-1 p-2 bg-green-300 rounded-xl">
                            {userProfile?.organizations[0]}
                          </div>
                        </button>
                        {navigation.map((item) => (
                          <button
                            key={item.name}
                            onClick={() => {
                              navigate(item.href);
                              setSidebarOpen(false);
                            }}
                            className={classNames(
                              item.current
                                ? "bg-gray-100 text-gray-900 w-full"
                                : "text-gray-600 hover:bg-gray-50 hover:text-gray-900 w-full ",
                              "group flex items-center px-2 py-2 text-base font-medium rounded-md w-full"
                            )}
                          >
                            <item.icon
                              className={classNames(
                                item.current
                                  ? "text-gray-500"
                                  : "text-gray-400 group-hover:text-gray-500",
                                "mr-4 h-6 w-6"
                              )}
                              aria-hidden="true"
                            />
                            {item.name}
                          </button>
                        ))}
                      </div>
                    </nav>
                  </div>
                  <FeedbackFish projectId="7da8fd237eb606" serId={user?.email}>
                    <div className="flex flex-shrink-0 border-t border-gray-200 p-4">
                      <div className="group block w-full flex-shrink-0">
                        <button className="flex flex-row">
                          <div className="flex items-center">
                            <div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-6 h-6"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 01-.825-.242m9.345-8.334a2.126 2.126 0 00-.476-.095 48.64 48.64 0 00-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0011.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155"
                                />
                              </svg>
                            </div>

                            <p className="ml-2 text-base font-medium text-gray-700 group-hover:text-gray-900">
                              Send Feedback
                            </p>
                          </div>
                        </button>
                      </div>
                    </div>
                  </FeedbackFish>
                  <div className="flex flex-shrink-0 border-t border-gray-200 p-4">
                    <div className="group block flex-shrink-0">
                      <div className="flex items-center">
                        <div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75"
                            />
                          </svg>
                        </div>
                        <button
                          className="flex flex-row"
                          onClick={handleLogout}
                        >
                          <p className="ml-2 text-base font-medium text-gray-700 group-hover:text-gray-900">
                            Logout
                          </p>
                        </button>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
              <div className="w-14 flex-shrink-0" aria-hidden="true">
                {/* Force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:flex lg:flex-shrink-0">
          <div className="flex w-64 flex-col">
            {/* Sidebar component, swap this element with another sidebar if you like */}
            <div className="flex min-h-0 flex-1 flex-col border-r border-gray-200 bg-gray-100">
              <div className="flex flex-1 flex-col overflow-y-auto pt-5 pb-4">
                <div className="flex flex-shrink-0 items-center px-4">
                  <img
                    className="h-14 w-auto"
                    src={companyLogo}
                    alt="Your Company"
                  />
                </div>
                <nav className="mt-5 flex-1" aria-label="Sidebar">
                  <div className="space-y-1 px-2">
                    <button className="flex flex-col items-start px-2 py-2 text-base text-left font-medium rounded-md w-full text-gray-600 hover:bg-gray-50 hover:text-gray-900">
                      Organisation ID:{" "}
                      <div className="my-1 p-2 bg-green-300 rounded-xl">
                        {userProfile?.organizations[0]}
                      </div>
                    </button>
                    {navigation.map((item) => (
                      <button
                        key={item.name}
                        onClick={() => {
                          navigate(item.href);
                        }}
                        className={[
                          `w-full`,
                          classNames(
                            item.current
                              ? "bg-gray-200 text-gray-900 w-full"
                              : "text-gray-600 hover-text-blue-700 hover:bg-white hover:text-blue-800 w-full  hover:ring-2 hover:ring-inset hover:ring-blue-500",
                            "group flex items-center px-2 py-2 text-sm font-medium rounded-md w-full"
                          ),
                        ]}
                      >
                        <item.icon
                          className={classNames(
                            item.current
                              ? "text-gray-500"
                              : "text-gray-400 group-hover:text-blue-700",
                            "mr-3 h-6 w-6"
                          )}
                          aria-hidden="true"
                        />
                        {item.name}
                      </button>
                    ))}
                  </div>
                </nav>
              </div>
              <FeedbackFish projectId="7da8fd237eb606" serId={user?.email}>
                <div className="flex flex-shrink-0 border-t border-gray-200 p-4">
                  <div className="group block w-full flex-shrink-0">
                    <button className="flex flex-row">
                      <div className="flex items-center">
                        <div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 01-.825-.242m9.345-8.334a2.126 2.126 0 00-.476-.095 48.64 48.64 0 00-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0011.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155"
                            />
                          </svg>
                        </div>

                        <p className="ml-2 text-base font-medium text-gray-700 group-hover:text-gray-900">
                          Send Feedback
                        </p>
                      </div>
                    </button>
                  </div>
                </div>
              </FeedbackFish>
              <div className="flex flex-shrink-0 border-t border-gray-200 p-4">
                <div className="group block w-full flex-shrink-0">
                  <button className="flex flex-row" onClick={handleLogout}>
                    <div className="flex items-center">
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75"
                          />
                        </svg>
                      </div>

                      <p className="ml-2 text-base font-medium text-gray-700 group-hover:text-gray-900">
                        Logout
                      </p>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex min-w-0 flex-1 flex-col overflow-hidden">
          <div className="lg:hidden">
            <div className="flex items-center justify-between border-b border-gray-200 bg-gray-50 px-4 py-1.5">
              <div>
                <img
                  className="h-12 w-auto"
                  src={companyLogo}
                  alt="Your Company"
                />
              </div>
              <div>
                <button
                  type="button"
                  className="-mr-3 inline-flex h-12 w-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900"
                  onClick={() => setSidebarOpen(true)}
                >
                  <span className="sr-only">Open sidebar</span>
                  <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
          <div className="relative z-0 flex flex-1 overflow-hidden">
            <main className="relative z-0 flex-1 overflow-y-auto focus:outline-none">
              {/* Start main area*/}
              <div className="absolute inset-0 py-6 px-4 sm:px-6 lg:px-8 pt-10">
                <Outlet />
              </div>
              {/* End main area */}
            </main>
          </div>
        </div>
      </div>
    </>
  );
}
