import React from "react";

function ProfilePictureImage({ card, height, logoHeight, noLogo, noBorder }) {
  return (
    <div>
      {card?.profilePictureUrl && card?.profilePictureUrl !== "" ? (
        <>
          <div className="relative inline-block text-left">
            <img
              className={`w-${height} h-${height} rounded-full p-1 bg-white ${
                noBorder ? null : `border-white border-4 border-inside`
              } object-cover `}
              src={card?.profilePictureUrl}
              alt=""
            />
            {card?.companyLogoUrl &&
            card?.companyLogoUrl !== "" &&
            noLogo !== true ? (
              <div
                className={`w-${logoHeight} h-${logoHeight} bg-white absolute z-120 -right-4 bottom-4 rounded-full`}
              >
                <img
                  className={`w-${logoHeight} h-${logoHeight} rounded-full border-4 border-white object-cover bg-white `}
                  src={card?.companyLogoUrl}
                  alt=""
                />
              </div>
            ) : null}
          </div>
        </>
      ) : (
        <>
          {card?.firstName ? (
            <div
              className={`relative h-${height} w-${height} rounded-full ${
                noBorder ? null : `border-white border-4`
              }  bg-blue-800 flex items-center justify-center`}
            >
              <p className="text-white text-2xl font-bold">
                {card?.firstName?.substring(0, 1)}
                {card?.lastName?.substring(0, 1)}
                {card?.companyLogoUrl !== "" ? (
                  card?.companyLogoUrl &&
                  card?.companyLogoUrl !== "" &&
                  noLogo !== true ? (
                    <div
                      className={`h-${logoHeight} w-${logoHeight} bg-white z-100 absolute bottom-0 right-0 rounded-full`}
                    >
                      <img
                        className={`h-${logoHeight} w-${logoHeight} absolute bottom-0 right-0 rounded-full border-4 border-white object-cover bg-white `}
                        src={card?.companyLogoUrl}
                        alt=""
                      />
                    </div>
                  ) : null
                ) : null}
              </p>
            </div>
          ) : (
            <div className="h-28 "></div>
          )}
        </>
      )}
    </div>
  );
}

export default ProfilePictureImage;
