/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/

import { Link } from "react-router-dom";
import { UserAuth } from "../../Context/AuthContext";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Tabs({ tabs, setActiveTab }) {
  const { userProfile, user } = UserAuth();

  return (
    <div>
      <div className="hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
          defaultValue={tabs?.find((tab) => tab?.current)?.name}
        >
          {tabs?.map((tab) => {
            if (tab?.role === 100 && user?.role !== 100) {
              // console.log("userTABrole", user);
              return <></>;
            } else {
              return (
                <option key={tab?.name}>
                  <Link
                    to={tab?.href}
                    key={tab?.name}
                    onClick={() => {
                      setActiveTab(tab?.name);
                    }}
                  >
                    {tab?.name}
                  </Link>
                </option>
              );
            }
          })}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {tabs?.map((tab) => {
              if (tab?.role === 100 && user?.role !== 100) {
                // console.log("userTABrole", user);
                return <></>;
              } else {
                return (
                  <Link
                    to={tab?.href}
                    key={tab?.name}
                    onClick={() => {
                      setActiveTab(tab?.name);
                    }}
                    className={classNames(
                      tab.current
                        ? "border-blue-500 text-blue-600"
                        : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200",
                      "whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm"
                    )}
                    aria-current={tab?.current ? "page" : undefined}
                  >
                    {tab?.name}
                    {tab?.count ? (
                      <span
                        className={classNames(
                          tab?.current
                            ? "bg-blue-100 text-blue-600"
                            : "bg-gray-100 text-gray-900",
                          "hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block"
                        )}
                      >
                        {tab?.count}
                      </span>
                    ) : null}
                  </Link>
                );
              }
            })}
          </nav>
        </div>
      </div>
    </div>
  );
}
