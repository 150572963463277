// Prebuilt AuthContext.js
import { createContext, useContext, useEffect, useState } from 'react'
import {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    signOut,
    onAuthStateChanged,
    sendPasswordResetEmail,
    getAuth,
    signInWithPhoneNumber,
} from 'firebase/auth'
import { auth, db, functions } from '../API/Firebase/firebase'
import { doc, setDoc, onSnapshot, updateDoc, arrayUnion, collection, getDoc, query, getDocs, orderBy, where } from "firebase/firestore"; 
import { useNavigate } from 'react-router-dom';

// Random id generator
import { customAlphabet } from 'nanoid'
import { httpsCallable } from 'firebase/functions';

const alphabet = '123456789ABCDEFGHJKLMNOPQRSTUVWXYZ';
const nanoid = customAlphabet(alphabet, 8);

// Creating context from react
const UserContext = createContext()

auth.onAuthStateChanged((user) => {
    if (user && user?.role === undefined) {
        console.log("User is signed in.");
        
        user.getIdTokenResult().then((idTokenResult) => {
            // Confirm the user is an Admin.
            user.role = idTokenResult?.claims?.role;
            // console.log('userROLE', user?.role);
      
       
        }).catch((error) => {
            // console.log(error);
        });

    } else {
   
        console.log("User is signed out.");
    }
});


// Creating a custom hook to use the context
export const AuthContextProvider = ({ children }) => {
    const [user, setUser] = useState(null)
    const [admin, setAdmin] = useState(false)
    const [limitedAccessUser, setLimitedAccessUser] = useState(null)
    const [account, setAccount] = useState({})
    const [authLoading, setAuthLoading] = useState(false)
    const [userProfile, setUserProfile] = useState(null)
    const [signedIn, setSignedIn] = useState(false)
    const [cards, setCards] = useState(null)
    const [cardsInAccount, setCardsInAccount] = useState([])
    


 

    

    // Firebase functions to register, sign in and sign out
    const createUser = async (email, password) => {
        // Add navigate here?
        const authUser = await createUserWithEmailAndPassword(auth, email, password)
        // console.log('User Profile Created', authUser)
   
        const userRef = doc(db, "users", authUser.user.uid);
        const createUserDoc = async () => {
            await setDoc(userRef, {
                email: authUser?.user?.email || '',
                phone: authUser?.user?.phoneNumber || '',
                uid: authUser.user.uid,
                created: Date.now(),
                updated: Date.now(),
                lastLogin: Date.now(),
                role: { admin: true },
                // Add more fields here
            }).then(() => {
                console.log("Document successfully written!");
                const addRole = httpsCallable(functions, 'addRole');
                addRole({ email: authUser.user.email, role: 100 })
                    .then((result) => {
                        
                        if (result.data.message) {
                       
                            // console.log(result.data.message);
                        }
                        
                        return result
                    }).then(() => authUser.user.getIdToken(true)).then(() => authUser.user.getIdTokenResult(true)).then((idTokenResult) => {
                        // console.log('IDTOKENCLAIM', idTokenResult.claims.role);
                       const roleCode = idTokenResult.claims.role
                        // console.log('USERTOKEN', user)
                        const userObject = {
                            ...authUser.user,
                            role: roleCode
                        }
                        setUser(userObject)

                    
                }
                )
                   
                 
                       
                        
                onAuthStateChanged(auth, async (user) => {
                    if (user) {
                        user.getIdToken(true)
                            .then((idToken) => {
                                // console.log('idToken', idToken)
                                localStorage.setItem('idToken', idToken)
                                user.getIdTokenResult()
                                    .then((idTokenResult) => {
                                        // console.log('idTokenResult', idTokenResult.claims.role)
                                        localStorage.setItem('idTokenResult', JSON.stringify(idTokenResult.claims.role))
                                       user.getIdToken(true)
                                    }
                                    )
                            }
                                
                            )
                    }
                })
                                
            })
        }

        

        
        const cardID = nanoid()
        const orgID = nanoid()
      
        const accountsRef = doc(db, "accounts", authUser.user.uid,);
        const createAccountDoc = async () => {
            const userAuthUID = authUser.user.uid
            await setDoc(accountsRef, {
            uid: authUser.user.uid,
            accountId: authUser.user.uid,
            organizations: arrayUnion(orgID),
            created: Date.now(),
            updated: Date.now(),
            lastLogin: Date.now(),
            // Add more fields here
            })
            return userAuthUID
        }

        const createOrgDoc = async () => {
            const orgRef = doc(db, "organizations", orgID);
            await setDoc(orgRef, {
                creatorUid: authUser.user.uid,
                id: orgID,
                companyId: orgID,
                company: "",
                companyDescription: "",
                companyLogoUrl: "",
                companyCoverPhotoUrl: "",
                coverPhotoUrl: "",
                companyWebsite: "hypacard.com",
                companyPhone: "",
                companyAddress: "",
                companyEmail: "",
                companyCity: "",
                companyState: "",
                companyZip: "",
                companyCountry: "",
                companyCreated: Date.now(),
                companyUpdated: Date.now(),
                companyCards: [],
                companyMembers: arrayUnion(authUser.user.uid),
                companyAdmins: arrayUnion(authUser.user.uid),
                companyInvites: [],
                companyRequests: [],
                companyBanned: [],
                companyDeleted: [],
                companyArchived: [],
                companyTemplates: [],
                companySettings: [],
            })
        }
        createUserDoc().then(createOrgDoc()).then(createAccountDoc().then((userAuthUID)=> getUserAccount(userAuthUID)).then(setAuthLoading(false)).then(() => user.getIdToken(true)))
    }

    const createBasicUser = async (email, password, card) => {
        console.log('Basic User Profile activated')
        const authUser = await createUserWithEmailAndPassword(auth, email, password)
        // console.log('User Profile Created', authUser)
        const userRef = doc(db, "users", authUser.user.uid);
        const createBasicUserDoc = async () => {
            await setDoc(userRef, {
                email: authUser.user.email,
                uid: authUser.user.uid,
                created: Date.now(),
                updated: Date.now(),
                lastLogin: Date.now(),
                role:{ basic:true},
                // Add more fields here
            }).then(() => {
                console.log("Document successfully written!");
                const addRole = httpsCallable(functions, 'addRole');
                addRole({ email: authUser.user.email, role: 200 })
                    .then((result) => {
                        user.getIdToken(true)
                            .then((idToken) => {
                                // console.log('idToken', idToken)
                                localStorage.setItem('idToken', idToken)
                                    
                                onAuthStateChanged(auth, async (user) => {
                                    if (user) {
                                        const token = await user.getIdTokenResult()
                                        // console.log('token', token)
                                        const { role } = token.claims
                                        // console.log('role', role)  
                                    }
                                })
                            })
                            .catch((error) => {
                                //console.log(error)
                            })
                    })
            }
            )
        }
            




        const accountsRef = doc(db, "accounts", authUser.user.uid,);
        const createAccountDoc = async () => {
            const userAuthUID = authUser.user.uid
            await setDoc(accountsRef, {
            uid: authUser.user.uid,
                accountId: authUser.user.uid,
            limitedAccessUser: true,
            organizations: arrayUnion(card.organization),
            created: Date.now(),
            updated: Date.now(),
                lastLogin: Date.now(),
            // Add more fields here
            })
            return userAuthUID
        }

        const addUserToOrgDoc = async () => {
            const orgRef = doc(db, "organizations", card.organization);
            await updateDoc(orgRef, {
                companyMembers: arrayUnion(authUser.user.uid),
            })
        }
         
         const addBasicUserToCardDoc = async () => {
             const cardRef = doc(db, "cards", card.cardID);
             await updateDoc(cardRef, {
                 limitedAccessUser: authUser.user.uid,
                 userCreatedDate: Date.now(),
             })
             return card
         }
             
        const addBasicUserToOrganizationCardDoc = async () => {   
            const orgRef = doc(db, "organizations", card.organization, "cards", card.cardID);
            await updateDoc(orgRef, {
                limitedAccessUser: authUser.user.uid,
                limitedAccessUserCreatedDate: Date.now(),
            })
        }


        
        
    const addBasicCardToAccount = async (card) => {
        // console.log('addCardToAccount called', card)
        const accountsRef = doc(db, "accounts", auth.currentUser.uid, "cards", card.cardID );
        const updateBasicAccountDoc = async () => {
            console.log('addCardToBasicAccount called')
            const accountId = auth.currentUser.uid
            const cardObject = {
            ...card,
            updated: Date.now(),
            lastLogin: Date.now(),
            uid: accountId,
            id: card.cardID,
            cardID: card.cardID,
            organization: card.organization,
            companyCoverPhotoUrl: card.companyCoverPhotoUrl,
            limitedAccessUser: authUser.user.uid,
        }
            await setDoc(accountsRef, cardObject)
            return true
        }
        return updateBasicAccountDoc()
    }
        
        const addBasicUserToAdminAccountDoc = async () => {
              console.log('updating Super User account')
             const accountCardRef = doc(db, "accounts", card.uid, "cards", card.cardID);
             await updateDoc(accountCardRef, {
                 limitedAccessUser: authUser.user.uid,
                 updated: Date.now(),
             })
             return card
         }

              createBasicUserDoc().then(addUserToOrgDoc()).then(addBasicUserToCardDoc()).then(createAccountDoc().then(addBasicUserToOrganizationCardDoc()).then(addBasicUserToAdminAccountDoc()).then((userAuthUID) => getUserAccount(userAuthUID)).then(addBasicCardToAccount(card)).then(setLimitedAccessUser(true)).then(setAuthLoading(false))
              )
    }




    
    


       


   

    const getUserAccount = async (userAuthUID) => {
        const accountRef = doc(db, "accounts", userAuthUID);

        if (userAuthUID !== undefined) {
        const unsubscribe = onSnapshot(accountRef, (doc) => {
            if (doc?.exists()) {
                const accountDetails = doc?.data();
                // console.log("Account Details:", accountDetails);
                console.log('Account found')
                setUserProfile(doc.data())
                setAccount(doc.data())
                return accountDetails
            } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
            }
        });
            return unsubscribe
            
        }
    }


    const signIn = async (email, password) => {
      
         // User
        console.log('SignIn')
        setAuthLoading(true)
     
        await signInWithEmailAndPassword(auth, email, password).then
            (async (userCredential) => {
               
                // console.log('UID CREDENTIAL', userCredential.user.uid)
        
                setAuthLoading(false)
            
                setUser(userCredential.user)
                 getUserAccount(userCredential.user.uid)
                return userCredential.user.uid

            })
                .then
                    ((UserProfileDetails) => {
                        // console.log('User Signed In', UserProfileDetails)
                        // console.log('User Signed In', user)
                        user.getIdToken(true)
                   
                    }).then(setSignedIn(true))
               .catch((error) => {
                    // console.log(error)
                    setAuthLoading(false)
             
               }
                )
    }


 

    const logout = () => {
        // console.log('logout called')
        return signOut(auth)
    }

    

    
    const getCards = async () => {

        if (user?.role !== undefined) {
            if (user?.role === 100) {
                // Get organization cards
                 console.log("ROLE === 100");
                // get doc from firestore collection
                const accountRef = doc(db, "accounts", user?.uid);
                const accountDocRef = await getDoc(accountRef);
               
                // get orgID from doc
                const accountOrg = accountDocRef.data().organizations[0];
                // get organization's card collection reference
                const cardColRef = collection(db, "organizations", accountOrg, "cards");
                // get cards from organization doc collection
                const q = query(cardColRef, orderBy("firstName"));

                // subscribe to card changes
                const unsubscribe = onSnapshot(q, (querySnapshot) => {
                    const cardsInAccount = [];
                    querySnapshot.forEach((doc) => {
                        cardsInAccount.push({ ...doc.data(), id: doc.cardID });
                    });
                
                    
            

                    setCardsInAccount(cardsInAccount)
                    locateCards(cardsInAccount)
                })
                
                 return unsubscribe;
            } else {          
               console.log("ROLE === 200");
                const accountColRef = collection(db, "accounts", user?.uid, "cards");
                const q = query(accountColRef, orderBy("firstName"));
                const unsubscribe = onSnapshot(q, (querySnapshot) => {
                    const cardsInAccount = [];
                    querySnapshot.forEach((doc) => {
                    cardsInAccount.push({ ...doc.data(), id: doc.id });
                    });
                    // const reversed = cards.reverse();
                    setCardsInAccount(cardsInAccount);
                    // setIsLoading(false);
                });
      return unsubscribe; 
            }

        } else {
            console.log("user has no role assigned")
        }
  }
    
    

         const locateCards = async (cardsInAccount) => {
            
            if (cardsInAccount.length > 0) {
                if (!cardsInAccount || !cardsInAccount.length) return [];
                const batches = [];
                const cardsRef = collection(db, "cards");
                while (cardsInAccount.length) {
                    // Firestore limits batches to 10
                    const batch = cardsInAccount.splice(0, 10);
                 
                    // Add the batch request to the queue
                    batches.push(
                        getDocs(
                            query(
                                cardsRef,
                                where(
                                    "cardID",
                                    "in",
                                    batch.map((card) => card?.cardID)
                                )
                            )
                        )
                    );
                }
                const results = await Promise.all(batches);
                const cardsArray = [];
                results.forEach((result) => {
                    result.forEach((doc) => {
                        cardsArray.push({ ...doc.data(), id: doc?.cardID });
                    });
                       
                    // Arrange by firstName
                    cardsArray.sort((a, b) => {
                        return a.firstName > b.firstName ? 1 : -1;
                    });
                setCards(cardsArray);
                });
  
    
            }
        }
    
    
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
         
            setUser(currentUser)
            getUserAccount(currentUser?.uid)

    
            
           
            // setAuthLoading(false)
        })
   
        return () => {
            unsubscribe()
        }

    }, [])



    
    const resetPasswordFunction = (email) => {
        const auth = getAuth();
        sendPasswordResetEmail(auth, email)
        .then(() => {
            // Password reset email sent!
            // ..
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            // ..
        });
        
    }

    // Document Snapshots
    const addBasicCardToBasicAccount = async (card, limitedAccessUserID) => {
        // console.log('addCardToAccount called', card)
        const accountsRef = doc(db, "accounts", limitedAccessUserID, "cards", card.cardID );
        const updateBasicAccountDoc = async () => {
    
            const cardObject = {
            ...card,
            updated: Date.now(),
            lastLogin: Date.now(),
   
        }
     
            await updateDoc(accountsRef, cardObject)
            return true
        }
        return updateBasicAccountDoc()
    }


   

    // Document CRUD functions
    

    // Update Edit Profile Card
    const updateCard = async (id, card, orgID) => {
        // console.log('updateCard called', card)
        await updateDoc(doc(db, 'cards', id), card).then(() => {
            // console.log('Card Updated')
            addCardToOrganizationProfile(card, id, orgID)
        }).then(() => {
            console.log('Card Added to Organization Profile')

            addCardToAccount(card, id, orgID)

            
            
        }).then(() => {
            if (card.limitedAccessUser) {
             console.log('Limited Access User Added to Card')
          
            addBasicCardToBasicAccount(card, card.limitedAccessUser)
        }
                 
        })
    }
            
    
   
       // Update Edit Profile Card
    const updateBasicCard = async (id, card, orgID) => {
        // console.log('updateCard called BASIC', card)
        await updateDoc(doc(db, 'cards', id), card).then(() => {
             console.log('Card Updated BASIC')
            addCardToOrganizationProfile(card, id, orgID)
        }).then(() => {
            // console.log('Card Added to Organization Profile. Limited access user:', card.limitedAccessUser)
              addBasicCardToBasicAccount(card, auth.currentUser.uid)
        
        })
    }
   



    const updateOrganization = async (id, organizationObject) => {
        // console.log('updateOrganization called', organizationObject)
        await updateDoc(doc(db, 'organizations', `${id}`), organizationObject).then(() => {
            console.log('Organization Updated')
            });

    };

    const addCardToAccount = async (card, cardID, orgID) => {
        // console.log('addCardToAccount called', card)
        const accountsRef = doc(db, "accounts", card.uid, "cards", cardID );
        const updateAccountDoc = async () => {
            console.log('addCardToAccount called')
            const accountId = auth.currentUser.uid
            const cardObject = {
            ...card,
            updated: Date.now(),
            lastLogin: Date.now(),
            uid: accountId,
            id: cardID,
            cardID: cardID,
            organization: orgID,
          
        }
     
            await setDoc(accountsRef, cardObject)
            return true
        }
        return updateAccountDoc()
    }

    // Update Account
    const addCardToDatabaseProfile = async (userId, card, orgID) => {
        // console.log('addCardToDatabaseProfile called', card)
        const cardID = nanoid()
        const accountId = auth.currentUser.uid
        const cardObject = {
            ...card,
            cardID: cardID,
            uid: accountId,
            organization: orgID,
            companyCoverPhotoUrl: "",
        }
     
        createCard(cardObject, accountId, cardID, orgID).then(
            () => {
                if (card.employeeCard === true) {
                    console.log('Employee Card Added')
                    return true
                } else {
                    addCardToAccount(cardObject, cardID, orgID)
                    return true
                }
            }
        ).then(() => addCardToOrganizationProfile(cardObject, cardID, orgID))
        
    };
    
    const addCardToOrganizationProfile = async (card, cardID, orgID) => {
        // console.log('addCardToOrganizationProfile called', card, 'orgID', orgID, 'cardID', cardID)
        const orgRef = doc(db, "organizations", orgID, "cards", cardID);
        await setDoc(orgRef, {
           
                updated: Date.now(),
                cardID: cardID,
                uid: auth.currentUser.uid,
                firstName: card.firstName || '',
                lastName: card.lastName || '',
                profilePictureUrl: card.profilePictureUrl,
                role: card.role || "",
                accessLevel: card.accessLevel || 'restricted',
                email: card.email || '',
        })
    }
       
    
            
        
 

    // Update User Card Link
    const createCard = async (cardObject, accountID, cardID) => {
        await setDoc(doc(db, 'cards', cardID), cardObject)
        
    };




    return (
        <UserContext.Provider value={{cards, getCards, setUser, admin, setAdmin, signedIn, userProfile, authLoading, createUser, createBasicUser, limitedAccessUser, user, account, logout, signIn, updateCard, updateBasicCard, createCard, addCardToDatabaseProfile, resetPasswordFunction, updateOrganization, getUserAccount }}>
            {children}
        </UserContext.Provider>
    )
}

// Makes context available throughout application
export const UserAuth = () => {
    return useContext(UserContext)
}