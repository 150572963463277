/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  const colors = require('tailwindcss/colors')
  
  module.exports = {
    // ...
    theme: {
      extend: {
        colors: {
          cyan: colors.cyan,
        },
      },
    },
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { useEffect, useState } from "react";
import { UserGroupIcon, UserPlusIcon } from "@heroicons/react/24/outline";
import {
  BuildingOfficeIcon,
  CheckCircleIcon,
  ChevronRightIcon,
} from "@heroicons/react/20/solid";
import { useParams } from "react-router-dom";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../API/Firebase/firebase";

const cards = [
  {
    id: 1,
    firstName: "Molly",
    lastName: "Sanders",
    href: "#",
    connections: "12",
    imageUrl:
      "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.6&w=256&h=256&q=80",
  },
  {
    id: 2,
    firstName: "Molly",
    lastName: "Sanders",
    href: "#",
    connections: "12",
    imageUrl:
      "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.6&w=256&h=256&q=80",
  },
  {
    id: 3,
    firstName: "Molly",
    lastName: "Sanders",
    href: "#",
    connections: "12",
    imageUrl:
      "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.6&w=256&h=256&q=80",
  },
  {
    id: 4,
    firstName: "Molly",
    lastName: "Sanders",
    href: "#",
    connections: "12",
    imageUrl:
      "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.6&w=256&h=256&q=80",
  },

  // More cards...
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function EventDetailPage() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [error, setError] = useState(null);
  // get params from eventID in url
  const [eventData, setEventData] = useState({});
  const [contacts, setContacts] = useState([]);
  const { eventID } = useParams();

  // Count total contacts made

  const totals = [
    {
      name: "Team Members Attending",
      href: "#",
      icon: UserGroupIcon,
      amount: eventData?.attendees?.length,
    },
    {
      name: "Connections Made",
      href: "#",
      icon: UserPlusIcon,
      amount: contacts.length,
    },
    // More items...
  ];

  useEffect(() => {
    const getEvent = httpsCallable(functions, "getEvent");
    try {
      getEvent(eventID)
        .then((result) => {
          setEventData(result.data);
        })
        .then(() => {
          const getEventConnections = httpsCallable(
            functions,
            "getEventConnections"
          );
          getEventConnections(eventID).then((result) => {
            setContacts(result.data);
          });
        });
    } catch (error) {
      setError(error.message);
    }
  }, [eventID]);

  return (
    <>
      <div className="min-h-full">
        <div className="flex flex-1 flex-col">
          <main className="flex-1 pb-8">
            {/* Page header */}
            <div className="bg-white ">
              <div className="px-4 sm:px-6 lg:mx-auto lg:max-w-6xl lg:px-8">
                <div className="py-6 md:flex md:items-center md:justify-between lg:border-b lg:border-gray-200">
                  <div className="min-w-0 flex-1">
                    {/* Profile */}
                    <div className="flex items-center">
                      <div>
                        <div className="flex items-center">
                          <h1 className="ml-3 text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:leading-9">
                            {eventData?.summary}
                          </h1>
                        </div>
                        <dl className="mt-6 flex flex-col sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap">
                          <dt className="sr-only">Company</dt>
                          <dd className="flex items-center text-sm font-medium capitalize text-gray-500 sm:mr-6">
                            <BuildingOfficeIcon
                              className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                              aria-hidden="true"
                            />
                            {eventData?.location}
                          </dd>
                          <dt className="sr-only">Account status</dt>
                          <dd className="mt-3 flex items-center text-sm font-medium capitalize text-gray-500 sm:mr-6 sm:mt-0">
                            {
                              //if event end date is in the past, event is over
                              //if event start date is in the future, event is upcoming
                              //if event start date is in the past and end date is in the future, event is ongoing
                              eventData?.end?.dateTime < new Date() ? (
                                "Event is complete"
                              ) : eventData?.start?.dateTime > new Date() ? (
                                "Event is upcoming"
                              ) : (
                                <>
                                  {" "}
                                  <CheckCircleIcon
                                    className="mr-1.5 h-5 w-5 flex-shrink-0 text-green-400"
                                    aria-hidden="true"
                                  />{" "}
                                  Event is live{" "}
                                </>
                              )
                            }
                          </dd>
                        </dl>
                      </div>
                    </div>
                  </div>
                  <div className="mt-6 flex space-x-3 md:mt-0 md:ml-4 opacity-20">
                    <button
                      type="button"
                      className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                    >
                      Edit*
                    </button>
                    <button
                      type="button"
                      className="inline-flex items-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                    >
                      Add Team Members*
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-8 text-left">
              <div className="mx-auto max-w-6xl px-4 sm:px-6 lg:px-8">
                <h2 className="text-lg font-medium leading-6 text-gray-900">
                  Summary
                </h2>
                <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
                  {/* Card */}
                  {totals.map((total) => (
                    <div
                      key={total.name}
                      className="overflow-hidden rounded-lg bg-white shadow"
                    >
                      <div className="p-5">
                        <div className="flex items-center">
                          <div className="flex-shrink-0">
                            <total.icon
                              className="h-6 w-6 text-gray-400"
                              aria-hidden="true"
                            />
                          </div>
                          <div className="ml-5 w-0 flex-1">
                            <dl>
                              <dt className="truncate text-sm font-medium text-gray-500">
                                {total.name}
                              </dt>
                              <dd>
                                <div className="text-lg font-medium text-gray-900">
                                  {total.amount}
                                </div>
                              </dd>
                            </dl>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              {/* Activity list (smallest breakpoint only) */}
              <div className="shadow sm:hidden">
                <ul
                  role="list"
                  className="mt-2 divide-y divide-gray-200 overflow-hidden shadow sm:hidden"
                >
                  {eventData?.attendees?.map((card) => (
                    <li key={card?.id}>
                      <a
                        href={card?.href}
                        className="block bg-white px-4 py-4 hover:bg-gray-50"
                      >
                        <span className="flex items-center space-x-4">
                          <span className="flex flex-1 space-x-2 truncate">
                            <img
                              className="h-16 w-16 rounded-full sm:hidden"
                              src={card?.profilePictureUrl}
                              alt=""
                            />
                            <span className="flex flex-col truncate text-sm text-gray-500">
                              <span className="truncate">
                                {card?.firstName} {card?.lastName}
                              </span>
                              <span>
                                <span className="font-medium text-gray-900">
                                  {card?.connections}
                                </span>
                              </span>
                            </span>
                          </span>
                          <ChevronRightIcon
                            className="h-5 w-5 flex-shrink-0 text-gray-400"
                            aria-hidden="true"
                          />
                        </span>
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              {/* Activity table (small breakpoint and up) */}
              <div className="hidden sm:block">
                <div className="mx-auto max-w-6xl px-4 sm:px-6 lg:px-8">
                  <div className="mt-2 flex flex-col">
                    <div className="min-w-full overflow-hidden overflow-x-auto align-middle shadow sm:rounded-lg">
                      <table className="min-w-full divide-y divide-gray-200">
                        <thead>
                          <tr>
                            <th
                              className="bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900"
                              scope="col"
                            >
                              Team Members
                            </th>
                            <th
                              className="bg-gray-50 px-6 py-3 text-right text-sm font-semibold text-gray-900"
                              scope="col"
                            >
                              Connections Made
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {eventData?.attendees?.map((card) => (
                            <tr key={card?.id} className="bg-white">
                              <td className="w-full max-w-0 whitespace-nowrap px-6 py-4 text-sm text-gray-900">
                                <div className="flex">
                                  <a
                                    href={card?.href}
                                    className="group inline-flex space-x-2 truncate text-sm text-center items-center h-full"
                                  >
                                    <img
                                      className="h-8 w-8 rounded-full"
                                      src={card?.profilePictureUrl}
                                      alt=""
                                    />
                                    <p className="truncate text-gray-500 group-hover:text-gray-900 ">
                                      {card?.firstName} {card?.lastName}
                                    </p>
                                  </a>
                                </div>
                              </td>
                              <td className="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500">
                                <span className="font-medium text-gray-900">
                                  {
                                    // Count the number of connections made by each attendee's cardID compared to the connectedBy value in the contacts array
                                    contacts?.filter(
                                      (contact) =>
                                        contact?.connectedBy === card?.cardID
                                    ).length
                                  }
                                </span>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <h2 className="mx-auto mt-8 max-w-6xl px-4 text-lg font-medium leading-6 text-gray-900 sm:px-6 lg:px-8">
                Connections
              </h2>
              {/* Activity list (smallest breakpoint only) */}
              <div className="shadow sm:hidden">
                <ul
                  role="list"
                  className="mt-2 divide-y divide-gray-200 overflow-hidden shadow sm:hidden"
                >
                  {contacts?.map((card) => (
                    <li key={card?.id}>
                      <a
                        href={card?.href}
                        className="block bg-white px-4 py-4 hover:bg-gray-50"
                      >
                        <span className="flex items-center space-x-4">
                          <span className="flex flex-1 space-x-2 truncate">
                            <img
                              className="h-16 w-16 rounded-full sm:hidden"
                              src={card?.profilePictureUrl}
                              alt=""
                            />
                            <span className="flex flex-col truncate text-sm text-gray-500">
                              <span className="truncate">
                                {card?.firstName} {card?.lastName}
                              </span>
                              <span>
                                <span className="font-medium text-gray-900">
                                  {card?.connections}
                                </span>
                              </span>
                            </span>
                          </span>
                          <ChevronRightIcon
                            className="h-5 w-5 flex-shrink-0 text-gray-400"
                            aria-hidden="true"
                          />
                        </span>
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              {/* Activity table (small breakpoint and up) */}
              <div className="hidden sm:block">
                <div className="mx-auto max-w-6xl px-4 sm:px-6 lg:px-8">
                  <div className="mt-2 flex flex-col">
                    <div className="min-w-full overflow-hidden overflow-x-auto align-middle shadow sm:rounded-lg">
                      <table className="min-w-full divide-y divide-gray-200">
                        <thead>
                          <tr>
                            <th
                              className="bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900"
                              scope="col"
                            >
                              Connection
                            </th>
                            <th
                              className="bg-gray-50 px-6 py-3 text-right text-sm font-semibold text-gray-900"
                              scope="col"
                            >
                              Connected By
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {contacts?.map((card) => (
                            <tr key={card?.id} className="bg-white">
                              <td className="w-full max-w-0 whitespace-nowrap px-6 py-4 text-sm text-gray-900">
                                <div className="flex">
                                  <a
                                    href={card?.href}
                                    className="group inline-flex space-x-2 truncate text-sm text-center items-center h-full"
                                  >
                                    <p className="truncate text-gray-500 group-hover:text-gray-900 ">
                                      {card?.firstName} {card?.lastName}
                                    </p>

                                    <p className="truncate text-gray-500 group-hover:text-gray-900 ">
                                      {"    "}
                                      P: {card?.phone}
                                      {"    "}
                                      E: {card?.email}
                                    </p>
                                  </a>
                                </div>
                              </td>
                              <td className="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500">
                                <span className="font-medium text-gray-900">
                                  {card?.connectionFirstName}{" "}
                                  {card?.connectionLastName}
                                </span>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
