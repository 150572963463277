/*
  This requires following config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { EnvelopeIcon, PhoneIcon } from "@heroicons/react/20/solid";
import { useEffect, useState } from "react";

// API USER DATA
import {
  doc,
  onSnapshot,
  where,
  collection,
  query,
  getDocs,
  getDoc,
} from "firebase/firestore";
import { auth, db, functions } from "../../../API/Firebase/firebase";
import { UserAuth } from "../../../Context/AuthContext";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ButtonPrimary } from "../../../Components/Button/Index";
import PopUpModal from "../../../Components/Modal/PopupModal";

import Tabs from "../../../Components/Navigation/Tabs";
import CalendarIndex from "../EventCalendar/Calendar";
import EventsTable from "../Table/EventsTable";
import { httpsCallable } from "firebase/functions";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function EventsList() {
  // Organization ID
  const { userProfile } = UserAuth();
  const organizationID = userProfile?.organizations[0];
  const [error, setError] = useState(null);
  const [events, setEvents] = useState([]);
  useEffect(() => {
    // Get event data for organization
    const getEvents = httpsCallable(functions, "getEvents");
    try {
      console.log("Creating Event...", organizationID);
      getEvents(organizationID).then((result) => {
        console.log("Events", result);
        setEvents(result.data);
      });
    } catch (error) {
      setError(error.message);
    }
  }, []);

  const navigate = useNavigate();
  // Event Data
  const EVENT_DATA = [
    {
      id: 1,
      title: "Event 1",
      date: "2021-10-10",
      time: "10:00",
      location: "Location 1",
      description: "Description 1",
      image: "https://picsum.photos/200/300",
      link: "https://www.google.com",
      cards: [
        {
          id: 1,
          firstName: "Card 1",
          lastName: "Card 1",
          imageUrl: "https://picsum.photos/200/300",
        },
        {
          id: 2,
          firstName: "Card 2",
          lastName: "Card 2",
          imageUrl: "https://picsum.photos/200/300",
        },
      ],
    },
    {
      id: 2,
      title: "Event 2",
      date: "2021-10-10",
      time: "10:00",
      location: "Location 1",
      description: "Description 1",
      image: "https://picsum.photos/200/300",
      link: "https://www.google.com",
      cards: [
        {
          id: 1,
          firstName: "Card 1",
          lastName: "Card 1",
          imageUrl: "https://picsum.photos/200/300",
        },
        {
          id: 2,
          firstName: "Card 1",
          lastName: "Card 1",
          imageUrl: "https://picsum.photos/200/300",
        },
        {
          id: 3,
          firstName: "Card 1",
          lastName: "Card 1",
          imageUrl: "https://picsum.photos/200/300",
        },
        {
          id: 4,
          firstName: "Card 1",
          lastName: "Card 1",
          imageUrl: "https://picsum.photos/200/300",
        },

        {
          id: 5,
          firstName: "Card 2",
          lastName: "Card 2",
          imageUrl: "https://picsum.photos/200/300",
        },
      ],
    },
    {
      id: 3,
      title: "Event 1",
      date: "2021-10-10",
      time: "10:00",
      location: "Location 1",
      description: "Description 1",
      image: "https://picsum.photos/200/300",
      link: "https://www.google.com",
      cards: [
        {
          id: 1,
          firstName: "Card 1",
          lastName: "Card 1",
          imageUrl: "https://picsum.photos/200/300",
        },
        {
          id: 2,
          firstName: "Card 2",
          lastName: "Card 2",
          imageUrl: "https://picsum.photos/200/300",
        },
      ],
    },
  ];

  // Count Active Events
  const [activeEventCount, setActiveEventCount] = useState(0);

  useEffect(() => {
    let count = 0;
    if (events === null || events === undefined || events === []) {
      setActiveEventCount(0);
      return;
    }
    events.forEach((event) => {
      if (events?.length > 0) {
        count++;
      }
    });
    setActiveEventCount(count);
  }, [events]);

  return (
    <>
      {/* <EventsTable /> */}
      <Event events={events} />
      <CreateEvent navigate={navigate} />
    </>
  );
}

function Event({ events }) {
  return (
    <ul
      role="list"
      className="grid grid-cols-1 gap-6 sm:grid-cols-1 lg:grid-cols-2"
    >
      {events?.map((event) => (
        <li
          key={event?.id}
          className="col-span-1 rounded-lg bg-transparent shadow-xl border-2 text-left p-2"
        >
          <div className="flex w-full items-center justify-between space-x-6 p-2">
            <div className="grid grid-cols-6 w-full">
              <div className="col-span-5">
                <h4 className="truncate text-xl font-semibold text-gray-700">
                  {event?.summary}
                </h4>
                <h4 className="truncate text-md font-medium text-gray-400">
                  {
                    // Convert date and time to readable format
                    new Date(event?.start?.dateTime).toLocaleString("en-US", {
                      weekday: "long",
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                      hour12: true,
                    })
                  }{" "}
                  to <br />
                  {
                    // Convert date and time to readable format
                    new Date(event?.end?.dateTime).toLocaleString("en-US", {
                      weekday: "long",
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                      hour12: true,
                    })
                  }
                </h4>
              </div>
              {/* SETTINGS COG */}
              {/* <div className="col-span-1 flex flex-col items-start">
                <button
                  onClick={() => {}}
                  className="flex flex-1 items-start justify-end w-full h-full text-gray-400 hover:text-gray-500"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M10.343 3.94c.09-.542.56-.94 1.11-.94h1.093c.55 0 1.02.398 1.11.94l.149.894c.07.424.384.764.78.93.398.164.855.142 1.205-.108l.737-.527a1.125 1.125 0 011.45.12l.773.774c.39.389.44 1.002.12 1.45l-.527.737c-.25.35-.272.806-.107 1.204.165.397.505.71.93.78l.893.15c.543.09.94.56.94 1.109v1.094c0 .55-.397 1.02-.94 1.11l-.893.149c-.425.07-.765.383-.93.78-.165.398-.143.854.107 1.204l.527.738c.32.447.269 1.06-.12 1.45l-.774.773a1.125 1.125 0 01-1.449.12l-.738-.527c-.35-.25-.806-.272-1.203-.107-.397.165-.71.505-.781.929l-.149.894c-.09.542-.56.94-1.11.94h-1.094c-.55 0-1.019-.398-1.11-.94l-.148-.894c-.071-.424-.384-.764-.781-.93-.398-.164-.854-.142-1.204.108l-.738.527c-.447.32-1.06.269-1.45-.12l-.773-.774a1.125 1.125 0 01-.12-1.45l.527-.737c.25-.35.273-.806.108-1.204-.165-.397-.505-.71-.93-.78l-.894-.15c-.542-.09-.94-.56-.94-1.109v-1.094c0-.55.398-1.02.94-1.11l.894-.149c.424-.07.765-.383.93-.78.165-.398.143-.854-.107-1.204l-.527-.738a1.125 1.125 0 01.12-1.45l.773-.773a1.125 1.125 0 011.45-.12l.737.527c.35.25.807.272 1.204.107.397-.165.71-.505.78-.929l.15-.894z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                  </svg>
                </button>
              </div> */}
              <div className="col-span-6 py-6">
                <div className="col-span-6 flex flex-col items-end ml-2">
                  <Cards cards={event?.cards} />
                </div>
                <div className="col-span-6 flex flex-col items-end ml-2">
                  <h4 className="truncate text-md font-medium text-gray-400">
                    {event?.attendees?.length} staff attending
                  </h4>
                </div>
              </div>

              <div className="col-span-6 pt-2">
                <Link
                  to={`/events/${event?.id}?eventID=${event?.id}`}
                  className="rounded-3xl w-full justify-end flex text-end border border-transparent text-md py-1 font-medium text-blue-800 hover:text-blue-400"
                >
                  <span className="">View Event</span>
                </Link>
              </div>
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
}

function Cards({ cards }) {
  return (
    <div className="flex -space-x-2 overflow-hidden">
      {cards?.map((card) => (
        <img
          className="inline-block h-8 w-8 rounded-full ring-2 ring-white"
          src="https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
          alt=""
        />
      ))}
    </div>
  );
}

function CreateEvent({ card, navigate }) {
  return (
    <div className="py-10">
      <button
        onClick={() => {
          navigate(`/events/new`);
        }}
        type="button"
        className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
      >
        <svg
          className="mx-auto h-12 w-12 text-gray-400"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M13.5 16.875h3.375m0 0h3.375m-3.375 0V13.5m0 3.375v3.375M6 10.5h2.25a2.25 2.25 0 002.25-2.25V6a2.25 2.25 0 00-2.25-2.25H6A2.25 2.25 0 003.75 6v2.25A2.25 2.25 0 006 10.5zm0 9.75h2.25A2.25 2.25 0 0010.5 18v-2.25a2.25 2.25 0 00-2.25-2.25H6a2.25 2.25 0 00-2.25 2.25V18A2.25 2.25 0 006 20.25zm9.75-9.75H18a2.25 2.25 0 002.25-2.25V6A2.25 2.25 0 0018 3.75h-2.25A2.25 2.25 0 0013.5 6v2.25a2.25 2.25 0 002.25 2.25z"
          />
        </svg>

        <span className="mt-2 block text-sm font-medium text-gray-900">
          Create a new event
        </span>
      </button>
    </div>
  );
}
