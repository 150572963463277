/*
  This example requires Tailwind CSS v3.0+
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { collection, onSnapshot } from "firebase/firestore";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { auth, db } from "../../API/Firebase/firebase";
import { ButtonPrimary } from "../../Components/Button/Index";
import { UserAuth } from "../../Context/AuthContext";
import { ExportCSV } from "../../Functions/Exports/CSV/Index";

const people = [
  {
    id: "1",
    firstName: "Lindsay Walton",
    title: "Front-end Developer",
    email: "lindsay.walton@example.com",
    phone: "0411 111 111",
    role: "Member",
  },

  // More people...
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ContactIndex() {
  const { userProfile, user } = UserAuth();
  const checkbox = useRef();
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [selectedPeople, setSelectedPeople] = useState([]);

  useLayoutEffect(() => {
    const isIndeterminate =
      selectedPeople?.length > 0 && selectedPeople?.length < connections.length;
    // setChecked(selectedPeople?.length === connections.length);
    // setIndeterminate(isIndeterminate);
    setIndeterminate(false);
    checkbox.current.indeterminate = isIndeterminate;
  }, [selectedPeople]);

  function toggleAll() {
    setSelectedPeople(checked || indeterminate ? [] : connections);
    setChecked(!checked && !indeterminate);
    setIndeterminate(false);
  }
  console.log("user", user);
  const [connections, setConnections] = useState([]);
  useEffect(() => {
    if (user.role === 100) {
      const contactRef = collection(
        db,
        "organizations",
        userProfile?.organizations[0],
        "connections"
      );
      const contactDoc = onSnapshot(contactRef, (querySnapshot) => {
        const contacts = [];
        querySnapshot.forEach((doc) => {
          contacts.push({ ...doc.data(), id: doc.id });
          console.log("Contact", doc.data());
        });
        setConnections(contacts);

        return contactDoc;
      });
    } else {
      const contactRef = collection(
        db,
        "accounts",
        userProfile?.uid,
        "connections"
      );
      const contactDoc = onSnapshot(contactRef, (querySnapshot) => {
        const contacts = [];
        querySnapshot.forEach((doc) => {
          contacts.push({ ...doc.data(), id: doc.id });
          console.log("Contact", doc.data());
        });
        setConnections(contacts);

        return contactDoc;
      });
    }
  }, []);

  return (
    <div className="px-4 ">
      <div className="flex flex-col">
        <div className="sm:flex sm:items-center text-left pb-10">
          <div className="sm:flex-auto">
            <h1 className="text-4xl font-semibold text-gray-900">
              Connections
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              A list of all the connections including their name, phone number,
              email and role.
            </p>
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <ExportCSV connections={connections}>
              <ButtonPrimary
                title="Export CSV"
                color={"blue"}
                textColor={"white"}
              ></ButtonPrimary>
            </ExportCSV>
          </div>
        </div>
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="relative overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              {selectedPeople?.length > 0 && (
                <div className="absolute top-0 left-12 flex h-12 items-center space-x-3 bg-gray-50 sm:left-16">
                  <button
                    type="button"
                    className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30"
                  >
                    Bulk edit
                  </button>
                  <button
                    type="button"
                    className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30"
                  >
                    Delete all
                  </button>
                </div>
              )}
              <table className="min-w-full table-fixed divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="relative w-12 px-6 sm:w-16 sm:px-8"
                    >
                      <input
                        type="checkbox"
                        // className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-blue-800 focus:ring-blue-500 sm:left-6"
                        className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-50 bg-gray-50 text-blue-800  sm:left-6"
                        ref={checkbox}
                        checked={checked}
                        onChange={toggleAll}
                      />
                    </th>
                    <th
                      scope="col"
                      className="min-w-[12rem] py-3.5 pr-3 text-left text-sm font-semibold text-gray-900"
                    >
                      Name
                    </th>

                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Role
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Connection
                    </th>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                    >
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {connections.map((person) => (
                    <tr
                      key={person.email}
                      className={
                        selectedPeople?.includes(person)
                          ? "bg-gray-50"
                          : undefined
                      }
                    >
                      {/* <td className="relative w-12 px-6 sm:w-16 sm:px-8">
                        {selectedPeople?.includes(person) && (
                          <div className="absolute inset-y-0 left-0 w-0.5 bg-blue-800" />
                        )}
                        <input
                          type="checkbox"
                          className="absolute  left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-blue-800 focus:ring-blue-500 sm:left-6"
                          value={person.email}
                          checked={selectedPeople?.includes(person)}
                          onChange={(e) =>
                            setSelectedPeople(
                              e.target.checked
                                ? [...selectedPeople, person]
                                : selectedPeople?.filter((p) => p !== person)
                            )
                          }
                        />
                      </td> */}
                      <td className="relative w-12 px-6 sm:w-16 sm:px-8"></td>
                      <td
                        className={classNames(
                          "whitespace-nowrap py-4 pr-3 text-sm font-medium text-left flex flex-col h-20",
                          selectedPeople?.includes(person)
                            ? "text-blue-700"
                            : "text-gray-900"
                        )}
                      >
                        <p>{person?.firstName}</p>
                        <div
                          className={
                            selectedPeople?.includes(person)
                              ? "text-blue-800"
                              : "text-gray-500"
                          }
                        >
                          <p>{person?.email ? person?.email : "No email"}</p>
                          <p> {person?.phone ? person?.phone : "No phone"}</p>
                        </div>
                      </td>

                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-left">
                        {person?.role ? person?.role : "No role assigned"}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-left">
                        {person?.connectionFirstName}
                      </td>
                      {/* <td className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                        <a
                          href="#"
                          className="text-blue-800 hover:text-blue-900"
                        >
                          Edit
                          <span className="sr-only">, {person?.firstName}</span>
                        </a>
                      </td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
