import { useState } from "react";
import { getAuth, updateEmail, sendPasswordResetEmail } from "firebase/auth";
import { Formik } from "formik";
import { useEffect } from "react";
import { UserAuth } from "../../Context/AuthContext";
import toast, { ToastBar, Toaster } from "react-hot-toast";
/*
  This requires following config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
export default function SettingsIndex() {
  const [email, setEmail] = useState("");
  const { resetPasswordFunction } = UserAuth();

  const auth = getAuth();

  useEffect(() => {
    setEmail(auth.currentUser.email);
  }, [auth.currentUser.email]);

  const handleSubmit = (values) => {
    const email = values.email;
    updateEmail(auth.currentUser, email)
      .then(() => {
        // Email updated!
        // ...
        // console.log("email updated!", email);
      })
      .catch((error) => {
        // An error occurred
        // ...
      });
  };

  const handleResetPassword = () => {
    console.log("reset password");
    resetPasswordFunction(email);
    toast("Password reset email sent!");
  };

  return (
    <>
      <div>
        <div className="md:grid md:grid-cols-3 md:gap-6"></div>
      </div>

      <Toaster>
        {(t) => (
          <ToastBar
            toast={t}
            icon={t.icon}
            containerStyle={{
              top: 20,
              left: 20,
              bottom: 20,
              right: 20,
            }}
            style={{
              background: "orange",
              color: "white",

              zIndex: 1,
              borderRadius: 10,
            }}
          />
        )}
      </Toaster>
      <div className="md:col-span-1">
        <div className="px-4 sm:px-0 text-start">
          <h3 className="text-4xl font-medium leading-6 text-gray-900">
            Settings
          </h3>
        </div>
      </div>

      <div className="hidden sm:block" aria-hidden="true">
        <div className="py-5">
          <div className="border-t border-gray-200" />
        </div>
      </div>

      <div className="mt-10 sm:mt-0">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0 text-left">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Account settings
              </h3>
              <p className="mt-1 text-sm text-gray-600">
                <p className="mt-1 text-sm text-gray-600">
                  This is your hypacard.com account information. This is
                  distinct from your card information.
                </p>
              </p>
            </div>
          </div>
          <div className="mt-5 md:col-span-2 md:mt-0">
            <Formik
              initialValues={{
                email: "",
              }}
              enableReinitialize
              onSubmit={(values, { setSubmitting }) => {
                handleSubmit(values);
                console.log("clicked");
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                /* and other goodies */
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="overflow-hidden shadow sm:rounded-md text-left">
                    <div className="bg-white px-4 py-5 sm:p-6">
                      <div className="grid grid-cols-6 gap-6">
                        <div className="col-span-6 sm:col-span-6">
                          <label
                            htmlFor="first-name"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Email Address
                          </label>
                          <input
                            onChange={handleChange}
                            onBlur={handleBlur}
                            type="text"
                            name="email"
                            id="email"
                            placeholder={email}
                            value={values.email}
                            autoComplete="email"
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                          />
                        </div>
                        <div className="col-span-6 sm:col-span-6">
                          <div className="bg-white py-3 text-left">
                            <button
                              type="submit"
                              className=" float-center px-10 py-3  justify-center rounded-md border border-transparent bg-blue-600  px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>

      <div className="hidden sm:block" aria-hidden="true">
        <div className="py-5">
          <div className="border-t border-gray-200" />
        </div>
      </div>

      <div className="mt-10 sm:mt-0">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0 text-left">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Password security
              </h3>
              <p className="mt-1 text-sm text-gray-600">
                <p className="mt-1 text-sm text-gray-600"></p>
              </p>
            </div>
          </div>
          <div className="mt-5 md:col-span-2 md:mt-0">
            <div className="overflow-hidden sm:rounded-md text-left -">
              <div className="bg-white px-4 py-3 text-left sm:px-6">
                <button
                  onClick={handleResetPassword}
                  className=" float-center px-5 py-3  justify-center rounded-md border border-transparent bg-blue-600  px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                >
                  Reset Password
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="hidden sm:block" aria-hidden="true">
        <div className="py-5">
          <div className="border-t border-gray-200" />
        </div>
      </div>
    </>
  );
}
