import { collection, getDocs, query } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { auth, db } from "../../../API/Firebase/firebase";
import ContactIndex from "../../Contacts/Index";

export function ContactsBasicIndex() {
  const [connections, setConnections] = useState([]);
  // Modal: Activate Card
  const [openActivateModal, setOpenActivateModal] = useState(false);
  const [dataPulled, setDataPulled] = useState(false);
  // useEffect(() => {
  //   getUser().then((funcCardIDs) => getCards(funcCardIDs));
  // }, [dataToggle]);
  const [isLoading, setIsLoading] = useState(false);

  if (dataPulled === false) {
    const getCardConnections = async () => {
      const user = await auth.currentUser;
      const accountCardCol = collection(db, "accounts", user.uid, "cards");
      const accountCardQuery = query(accountCardCol);
      const accountCardSnapshot = await getDocs(accountCardQuery);
      const cardData = [];
      const accountCardData = accountCardSnapshot.docs.map((doc) => {
        cardData.push(doc.data());
      });
      // console.log("userID", user.uid);
      const connectionsCardCol = collection(
        db,
        "cards",
        cardData[0]?.cardID,
        "connections"
      );
      const connectionsCardQuery = query(connectionsCardCol);
      const connectionsCardSnapshot = await getDocs(connectionsCardQuery);
      const collectionData = [];
      const connectionsCardData = connectionsCardSnapshot.docs.map((doc) => {
        collectionData.push(doc.data());
      });
      setConnections(collectionData);
    };
    getCardConnections();
    setDataPulled(true);
  }

  useEffect(() => {
    // console.log("connections", connections);
  }, [connections]);
  return <ContactIndex />;
}
