import React from "react";
// Loading animation
import Lottie from "react-lottie";
import animationData from "../../../Assets/Animations/Lotties/liveDot.json";

export default function LiveDot() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div className="">
      <Lottie options={defaultOptions} height={50} width={50} />
    </div>
  );
}
