import React, { Fragment, useRef, useState } from "react";
import { ModalSheet } from "../../../Components/Modal/Index";
import { useParams, useNavigate } from "react-router-dom";
import {
  doc,
  getDoc,
  setDoc,
  updateDoc,
  arrayUnion,
  increment,
  addDoc,
  collection,
  Timestamp,
  serverTimestamp,
} from "firebase/firestore";
import { app, db, functions } from "../../../API/Firebase/firebase";
import hypacardIcon from "../../../Assets/Images/hypacardIcon.png";
// Animation on card load
import { motion } from "framer-motion";

// Form Modal
import { Formik } from "formik";

// Random id generator
import { customAlphabet } from "nanoid";
import { saveVCard } from "../../../Functions/Card/VCard";
import { useEffect } from "react";
import { ProfileCardIndex } from "../../../Components/Profile/Index";
import HypaSimpleLoadingAnimation from "../../../Functions/Loading/Index";
import PopUpModal from "../../../Components/Modal/PopupModal";
import ProfilePictureImage from "../../../Components/Profile/ProfilePictureImage";
import { DownloadInstructions } from "./DetectDevice/Index";

// Phone
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { Helmet } from "react-helmet";
import { date } from "yup";
import { getFunctions, httpsCallable } from "firebase/functions";

const alphabet = "123456789ABCDEFGHJKLMNOPQRSTUVWXYZ";
const nanoid = customAlphabet(alphabet, 8);

function PublicProfileIndex() {
  let { id } = useParams();
  const [card, setCardData] = useState([]);
  const [pageVisited, setCardVisited] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isOpen, setOpen] = useState(false);
  const navigate = useNavigate();

  const getCard = async () => {
    const cardRef = doc(db, "cards", id);
    const cardSnap = await getDoc(cardRef);
    if (cardSnap.exists()) {
      // console.log("##Card data SNAP: ", cardSnap.data());
      setCardData(cardSnap.data());
      setIsLoading(false);
    } else {
      navigate("/activate/" + id, {
        state: { id: id },
      });
      // console.log("##No such card!3 SNAP");
    }
  };

  const addAccountCardCount = async () => {
    const cardAccountCol = doc(db, "accounts", card?.uid, "cards", id);
    // console.log("run2");
    const cardDocAccountIncrementVisit = await updateDoc(cardAccountCol, {
      "counters.cardVisits": increment(1),
    });
    cardDocAccountIncrementVisit();
  };

  //  console.log("##Card Visited");

  const countCardVisit = async () => {
    const cardRef = doc(db, "cards", id);
    await updateDoc(cardRef, {
      "counters.cardVisits": increment(1),
    });

    // add card visit for each team that card is in
    if (card?.teams !== undefined) {
      // card.teams.forEach(async (team) => {
      //   const teamRef = doc(
      //     db,
      //     "teams",
      //     team.id,
      //     "cardVisits",
      //     // insert date string
      //     new Date().toISOString().slice(0, 10)
      //   );
      //   await setDoc(teamRef, {
      //     "counters.cardVisits": increment(1),
      //   });
      // });
      // Add card visit for each team that card is in to the team's cardVisits collection for each day
      card.teams.forEach(async (team) => {
        const date = new Date().toISOString();
        const teamRef = doc(
          db,
          "teams",
          team.id,
          "cardVisits",
          // insert date string
          date
        );
        const teamSnap = await getDoc(teamRef);
        if (teamSnap.exists()) {
          await updateDoc(teamRef, {
            cardVisits: increment(1),
            cardID: id,
            firstName: card.firstName,
            lastName: card.lastName,
            date: date,
          });
        } else {
          await setDoc(teamRef, {
            cardVisits: increment(1),
            cardID: id,
            firstName: card.firstName,
            lastName: card.lastName,
            date: date,
          });
        }
      });
    }
  };

  const addBasicAccountCardCount = async () => {
    const basicAccountRef = doc(
      db,
      "accounts",
      card?.limitedAccessUser,
      "cards",
      id
    );
    const updateBasicAccountCardCol = await updateDoc(basicAccountRef, {
      "counters.cardVisits": increment(1),
    });

    updateBasicAccountCardCol();
  };

  if (pageVisited === false && card?.uid !== undefined) {
    setCardVisited(true);
    countCardVisit().then(() => {
      addAccountCardCount().then(() => {
        if (card?.limitedAccessUser !== undefined) {
          addBasicAccountCardCount();
        }
      });
    });
  }

  useEffect(() => {}, [pageVisited]);

  // Not sure why I added this instead of just using getCard() directly
  // if (card.length === 0) {
  //   getCard();
  // }

  useEffect(() => {
    getCard();
  }, []);

  useEffect(() => {
    setOpen(card?.contactExchangeActive || false);
    // console.log(card);
    // console.log("##Card data: ", card);
  }, [isLoading]);

  return (
    <div className="overflow-y-auto h-full bg-white w-full">
      <Helmet>
        {
          // add the following line to the head of the document
          <script>FreshworksWidget('destroy')</script>
        }
      </Helmet>
      {isLoading ? (
        <HypaSimpleLoadingAnimation />
      ) : (
        <>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3 }}
          >
            <ProfileCardIndex card={card} id={id} setOpen={setOpen} />
          </motion.div>

          <Modal
            id={id}
            accountID={card.uid}
            card={card}
            isOpen={isOpen}
            setOpen={setOpen}
          />
        </>
      )}
    </div>
  );
}

export default PublicProfileIndex;

const Modal = ({ id, accountID, card, isOpen, setOpen }) => {
  const contactID = nanoid();
  const [phoneValue, setPhoneValue] = useState();

  const navigate = useNavigate();
  const [countryCode, setCountryCode] = useState("");
  const getCountryCode = async () => {
    const response = await fetch("https://ipapi.co/json/");
    const data = await response.json();
    setCountryCode(data.country);
    console.log(countryCode, "country code");
  };

  useEffect(() => {
    getCountryCode();
  }, []);

  const addContactToFirestore = async (values) => {
    // console.log("##Values: ", values);
    const contactRef = await setDoc(doc(db, "cards", contactID), {
      accountID: contactID,
      firstName: values?.firstName || "",
      lastName: values?.lastName || "",
      email: values?.email || "",
      phone: values?.phone || "",
      accountClaimed: false,
      connectedBy: id,
      connectedByAccountUid: card?.uid,
      connectionFirstName: card?.firstName || "",
      connectionLastName: card?.lastName || "",
      connectionEmail: card?.email || "",
      connectionPhone: card?.phone || "",
      connectionClaimed: false,
      connectionTeam: card?.team || "",
      connectionRole: card?.role || "",
      connectionCompany: card?.company || "",
      connectionCountry: card?.country || "",
      connectionCity: card?.city || "",
      connectionState: card?.state || "",
      connectionZip: card?.zip || "",
      connectionAddress: card?.address || "",
      connectionProfilePictureUrl: card?.profilePictureUrl || "",
    });
    return contactID;
  };

  const AddContactToUserCard = async (contactID, values) => {
    setDoc(doc(db, "cards", id, "connections", contactID), {
      cardID: contactID,
      contactID: contactID,
      connectedBy: id,
      connectedByAccountUid: card?.uid,
      connectionFirstName: card?.firstName || "",
      connectionLastName: card?.lastName || "",
      connectionEmail: card?.email || "",
      connectionPhone: card?.phone || "",
      connectionClaimed: false,
      connectionTeam: card?.team || "",
      connectionCompany: card?.company || "",
      connectionCountry: card?.country || "",
      connectionCity: card?.city || "",
      connectionState: card?.state || "",
      connectionZip: card?.zip || "",
      connectionAddress: card?.address || "",
      connectionProfilePictureUrl: card?.profilePictureUrl || "",

      dateAdded: new Date(),
      firstName: values?.firstName || "",
      lastName: values?.lastName || "",
      email: values?.email || "",
      phone: values?.phone || "",
      organization: card?.organization || "",
    });
  };

  const AddContactToUserAccountDoc = async (contactID, values) => {
    // console.log("##ADDContact ID TO ACCOUNT DOC:", contactID);
    setDoc(doc(db, "accounts", card?.uid, "connections", contactID), {
      cardID: contactID,
      contactID: contactID,
      connectedBy: id,
      connectedByAccountUid: card?.uid,
      connectionFirstName: card?.firstName || "",
      connectionLastName: card?.lastName || "",
      connectionEmail: card?.email || "",
      connectionPhone: card?.phone || "",
      connectionTeam: card?.team || "",
      dateAdded: new Date(),
      firstName: values?.firstName || "",
      lastName: values?.lastName || "",
      email: values?.email || "",
      phone: values?.phone || "",
      organization: card?.organization || "",
      connectionProfilePictureUrl: card?.profilePictureUrl || "",
    });
  };

  const AddContactToBasicUserAccountDoc = async (contactID, values) => {
    // console.log("##ADDContact ID TO ACCOUNT DOC:", contactID);
    setDoc(
      doc(db, "accounts", card?.limitedAccessUser, "connections", contactID),
      {
        cardID: contactID,
        contactID: contactID,
        connectedBy: id,
        connectedByAccountUid: card?.uid,
        connectionFirstName: card?.firstName || "",
        connectionLastName: card?.lastName || "",
        connectionEmail: card?.email || "",
        connectionPhone: card?.phone || "",
        connectionTeam: card?.team || "",
        dateAdded: new Date(),
        firstName: values?.firstName || "",
        lastName: values?.lastName || "",
        email: values?.email || "",
        phone: values?.phone || "",
        organization: card?.organization || "",
        connectionProfilePictureUrl: card?.profilePictureUrl || "",
      }
    );
  };

  const AddContactToOrganizationDoc = async (contactID, values) => {
    // console.log("##ADDContact ID TO ACCOUNT DOC:", contactID);
    setDoc(
      doc(db, "organizations", card?.organization, "connections", contactID),
      {
        cardID: contactID,
        contactID: contactID,
        connectedBy: id,
        connectedByAccountUid: card?.uid,
        connectionFirstName: card?.firstName || "",
        connectionLastName: card?.lastName || "",
        connectionEmail: card?.email || "",
        connectionPhone: card?.phone || "",
        connectionTeam: card?.team || "",
        dateAdded: new Date(),
        firstName: values?.firstName || "",
        lastName: values?.lastName || "",
        email: values?.email || "",
        phone: values?.phone || "",
        organization: card?.organization || "",
        connectionProfilePictureUrl: card?.profilePictureUrl || "",
      }
    );
  };

  const addContactToEventFunction = async (data) => {
    // Add Contact object to Event > EventID > Contacts collection firestore
    const { eventID, contactID, contact } = data;

    const eventRef = doc(db, "events", eventID, "contacts", contactID);
    await setDoc(eventRef, contact).then(() => {
      return eventID;
    });

    // const eventRef = db
    //   .collection("events")
    //   .doc(eventID)
    //   .collection("contacts")
    //   .doc(contactID)
    //   .set(contact);
    // return eventRef;
  };

  const AddContactToEventCard = async (contactID, values) => {
    // console.log("##ADDContact ID TO ACCOUNT DOC:", contactID);

    // If activeEvents exist inside card.activeEvents, add contact to event
    // Wait for each event to finish before moving on to next event
    if (card?.activeEvents) {
      console.log("Active Events Exist");
      card?.activeEvents?.forEach(async (event) => {
        console.log("Event ID Write");
        const data = {
          eventID: event,
          contactID: contactID,
          contact: {
            cardID: contactID,
            contactID: contactID,
            connectedBy: id,
            connectedByAccountUid: card?.uid,
            connectionFirstName: card?.firstName || "",
            connectionLastName: card?.lastName || "",
            connectionEmail: card?.email || "",
            connectionPhone: card?.phone || "",
            connectionTeam: card?.team || "",
            dateAdded: new Date(),
            firstName: values?.firstName || "",
            lastName: values?.lastName || "",
            email: values?.email || "",
            phone: values?.phone || "",
            organization: card?.organization || "",
            connectionProfilePictureUrl: card?.profilePictureUrl || "",
          },
        };

        await addContactToEventFunction(data).then((result) => {
          console.log("Add Contact to Event");
        });
      });
    }
  };

  // Analytics
  const incrementContactAdd = async () => {
    const cardRef = doc(db, "cards", id);
    const updateCard = await updateDoc(cardRef, {
      "counters.contactsAdded": increment(1),
    });
    const accountRef = doc(db, "accounts", card?.uid, "cards", id);
    const updateAccountCardCol = await updateDoc(accountRef, {
      "counters.contactsAdded": increment(1),
    });

    const basicAccountRef = doc(
      db,
      "accounts",
      card?.limitedAccessUser,
      "cards",
      id
    );
    const updateBasicAccountCardCol = await updateDoc(basicAccountRef, {
      "counters.contactsAdded": increment(1),
    });

    updateCard().then(() => {
      updateAccountCardCol().then(() => {
        if (card?.limitedAccessUser) {
          updateBasicAccountCardCol();
        }
      });
    });
  };

  const handleSubmit = (values) => {
    const { firstName, lastName, email, phone } = values;

    addContactToFirestore(values)
      .then(() => {
        AddContactToUserCard(contactID, values);

        // console.log("accountID", accountID);
      })
      .then(AddContactToUserAccountDoc(contactID, values))
      .then(() => {
        if (card?.limitedAccessUser !== null) {
          AddContactToBasicUserAccountDoc(contactID, values);
        } else {
          console.log("");
        }
      })
      .then(AddContactToOrganizationDoc(contactID, values))
      .then(registerContactExchange(contactID, values))
      .then(async () => {
        await AddContactToEventCard(contactID, values);
      })

      .then(incrementContactAdd())
      .then(saveVCard(card))
      .then(setOpen(!isOpen));
    // .then(navigate("/activate", { state: { activationCode: contactID } }));
  };

  const registerContactExchange = async (contactID, values) => {
    const date = new Date().toISOString();
    const contactData = {
      cardID: contactID,
      contactID: contactID,
      connectedBy: id,
      connectedByAccountUid: card?.uid,
      connectionFirstName: card?.firstName || "",
      connectionLastName: card?.lastName || "",
      connectionEmail: card?.email || "",
      connectionPhone: card?.phone || "",
      connectionClaimed: false,
      connectionTeam: card?.team || "",
      connectionCompany: card?.company || "",
      connectionCountry: card?.country || "",
      connectionCity: card?.city || "",
      connectionState: card?.state || "",
      connectionZip: card?.zip || "",
      connectionAddress: card?.address || "",
      connectionProfilePictureUrl: card?.profilePictureUrl || "",
      date: date,
      firstName: values?.firstName || "",
      lastName: values?.lastName || "",
      email: values?.email || "",
      phone: values?.phone || "",
      organization: card?.organization || "",
      cardVisits: increment(1),
    };
    // Add card visit for each team that card is in to the team's cardVisits collection for each day
    card.teams.forEach(async (team) => {
      const teamRef = doc(
        db,
        "teams",
        team.id,
        "contactsAdded",
        // insert date string
        date
      );
      const teamSnap = await getDoc(teamRef);
      if (teamSnap.exists()) {
        await updateDoc(teamRef, contactData);
        return contactID;
      } else {
        await setDoc(teamRef, contactData);
        return contactID;
      }
    });
  };

  return (
    <>
      <PopUpModal open={isOpen} setOpen={setOpen} id={id} accountID={accountID}>
        <div className="mx-auto w-full flex flex-col pb-40 overflow-y-auto ">
          <div className="">
            <div className="px-4 sm:px-0 text-center">
              <h3 className="text-2xl font-medium leading-6 text-gray-900 flex flex-col justify-start items-center space-y-2">
                <div className="mx-auto z-100">
                  <div className=" text-center h-24">
                    <div className="flex justify-center h-14">
                      <ProfilePictureImage
                        card={card}
                        height={24}
                        logoHeight={8}
                      />
                    </div>
                  </div>
                </div>
                <span className="ml-4">
                  Exchange Details <br />
                  {card?.firstName ? <>with {card?.firstName} </> : "with Me"}
                </span>
                <p className="mt-2 text-base font-normal text-gray-500">
                  Share your contact details here.
                </p>
              </h3>
            </div>
          </div>

          <div className="px-4">
            <div className="md:grid  md:gap-6">
              <div className="mt-1 md:col-span-2 md:mt-0">
                <Formik
                  initialValues={{
                    firstName: "",
                    lastName: "",
                    phone: "",
                    email: "",
                  }}
                  enableReinitialize
                  onSubmit={(values, { setSubmitting }) => {
                    const valuesObject = {
                      firstName: values.firstName,
                      lastName: values.lastName,
                      phone: phoneValue,
                      email: values.email,
                    };

                    handleSubmit(valuesObject);
                    console.log("clicked");
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <div className="sm:rounded-md text-left ">
                        <div className="bg-white px-4 py-2 sm:p-6">
                          <div className="grid grid-cols-6 gap-6">
                            <div className="col-span-6 sm:col-span-3">
                              <label
                                htmlFor="first-name"
                                className="block text-sm font-medium text-gray-700"
                              >
                                First name
                              </label>
                              <input
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type="text"
                                name="firstName"
                                id="first-name"
                                autoComplete="given-name"
                                className="mt-1 block rounded-md w-full border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                              />
                            </div>

                            <div className="col-span-6 sm:col-span-3">
                              <label
                                htmlFor="last-name"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Last name
                              </label>
                              <input
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type="text"
                                name="lastName"
                                id="last-name"
                                autoComplete="family-name"
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                              />
                            </div>
                            <div className="col-span-6 sm:col-span-6">
                              <label
                                htmlFor="tel"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Phone number
                              </label>
                              <PhoneInput
                                defaultCountry={countryCode || "AU"}
                                placeholder=""
                                value={phoneValue}
                                onChange={setPhoneValue}
                                inputProps={{
                                  name: "phone",
                                }}
                                className="mt-1 block w-full text-base"
                              />
                            </div>

                            <div className="col-span-6 sm:col-span-6">
                              <label
                                htmlFor="email"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Email address
                              </label>
                              <input
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type="text"
                                name="email"
                                id="email"
                                autoComplete="email"
                                className="mt-1 block w-full rounded-md  border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                              />
                            </div>
                          </div>
                          <div className=" mt-2 flex flex-col space-y-3 sm:flex-row sm:space-y-0 sm:space-x-4 z-100 bg-white pt-4">
                            <button
                              type="submit"
                              className="inline-flex w-full justify-center rounded-xl border border-gray-300 bg-black px-4 py-6 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                            >
                              <span className="ml-2">Exchange</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </PopUpModal>
    </>
  );
};
