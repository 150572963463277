import React, { useState } from "react";
import ProfileHeader from "../../../Components/Profile/ProfileHeader";
import DefaultUserAvatar from "../../../Assets/Images/DefaultUserAvatar.png";
import { UserAuth } from "../../../Context/AuthContext";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  updateDoc,
} from "firebase/firestore";
import { db, storage } from "../../../API/Firebase/firebase";
import { useEffect } from "react";
import { Field, Formik } from "formik";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { nanoid } from "nanoid";
import { ImageUploadFunction } from "../../../Functions/ImageUpload/Index";
import { useNavigate } from "react-router-dom";
import ProfilePictureImage from "../../../Components/Profile/ProfilePictureImage";
import { CirclePicker, EditableInput } from "react-color";
import { CardButton } from "../../../Components/Button/Index";
import { StyledImageUpload } from "../../../Functions/Crop/ImageUpload";

import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";
import { PulseLoader, SyncLoader } from "react-spinners";

export default function Index() {
  // API: User's Cards
  const { userProfile } = UserAuth();
  const { user, updateOrganization, account } = UserAuth();
  const [orgData, setOrgData] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [orgId, setOrgId] = useState(userProfile?.organizations[0]);
  const [isLoading, setIsLoading] = useState(false);
  const [dataToggle, setDataToggle] = useState(false);
  const [userData, setUserData] = useState([]);

  const [companyLogoUpload, setCompanyLogoUpload] = useState(
    orgData?.companyLogoUrl
  );
  const [companyCoverPhotoUpload, setCompanyCoverPhotoUpload] = useState(
    orgData?.companyCoverPhotoUrl
  );

  const [companyCoverPhotoPreview, setCompanyCoverPhotoPreview] = useState(
    orgData?.companyCoverPhotoUrl
  );

  const [companyLogoPreview, setCompanyLogoPreview] = useState(
    orgData?.companyLogoUrl
  );

  // useEffect(() => {
  //   const unsubscribe = onSnapshot(getDoc(db, "accounts", user?.uid), (doc) => {
  //     if (doc.exists()) {
  //       setUserData(doc.data());
  //       console.log("data doc", doc.data());
  //       setOrgId(doc.data().organization).then(() => {
  //         const unsubscribe = onSnapshot(
  //           getDoc(db, "organizations", doc.data().organization),
  //           (doc) => {
  //             if (doc.exists()) {
  //               setOrgData(doc.data());
  //               setIsLoading(false);
  //               setDataToggle(true);
  //               setCompanyLogoUpload(doc?.data()?.companyLogoUrl);
  //               setCompanyCoverPhotoUpload(doc?.data()?.companyCoverPhotoUrl);
  //             }
  //           }
  //         );
  //       });
  //     } else {
  //       console.log("No such document!");
  //     }
  //   });
  //   return () => {
  //     unsubscribe();
  //   };
  // }, []);

  // useEffect(() => {
  //   const unsubOrgs = onSnapshot(
  //     doc(db, "organizations", account?.organizations[0]),
  //     (doc) => {
  //       setOrgData(doc?.data());
  //       setCompanyLogoUpload(doc?.data()?.companyLogoUrl);
  //       setCompanyCoverPhotoUpload(doc?.data()?.companyCoverPhotoUrl);
  //     }
  //   );
  //   return () => {
  //     unsubOrgs();
  //   };
  // }
  // , []);

  const [orgCardData, setOrgCardData] = useState([]);
  const [orgCardChecked, setOrgCardChecked] = useState(false);

  useEffect(() => {
    console.log("orgId changed", orgId);
    getUserDoc();
    setCompanyCoverPhotoPreview(companyCoverPhotoUpload);
    setCompanyLogoPreview(companyLogoUpload);
    console.log("coverpreview", companyCoverPhotoPreview);
    console.log("coverupload", companyCoverPhotoUpload);
  }, []);
  const getUserDoc = () => {
    setDataToggle(true);

    getDoc(doc(db, "organizations", orgId))
      .then((doc) => {
        setOrgData(doc.data());
        setDataToggle(true);
        console.log("ORG DATA", doc.data());
        setCompanyLogoUpload(doc?.data()?.companyLogoUrl);
        setCompanyCoverPhotoUpload(doc?.data()?.companyCoverPhotoUrl);
        setCompanyCoverPhotoPreview(doc?.data()?.companyCoverPhotoUrl);
        setCompanyLogoPreview(doc?.data()?.companyLogoUrl);
      })
      .then(() => {
        const cardColRef = collection(db, "organizations", orgId, "cards");
        const q = query(cardColRef, orderBy("firstName"));
        getDocs(q).then((querySnapshot) => {
          const data = querySnapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));
          setOrgCardData(data);
          setIsLoading(false);
        });
      });
    return orgId;
  };

  const getOrgDoc = () => {
    getDoc(doc(db, "organizations", orgId)).then((doc) => {
      setOrgData(doc.data());
      setIsLoading(false);
    });
  };

  // if (isLoading === true) {
  //   getUserDoc();
  //   console.log("getUserDoc");
  // }

  //   console.log("userID", user);

  //   getUserDoc().then((organizationID) => {
  //     console.log("orgID", organizationID);
  //     const getOrgDoc = getDoc(doc(db, "organizations", orgId)).then((doc) => {
  //       setOrgData(doc.data());
  //       setIsLoading(false);
  //       setDataToggle(true);
  //       setCompanyLogoUpload(doc?.data()?.companyLogoUrl);
  //       setCompanyCoverPhotoUpload(doc?.data()?.companyCoverPhotoUrl);
  //     });
  //     getOrgDoc().then(() => {
  //       const getOrgCards = getDocs(
  //         collection(db, "organizations", orgId, "cards")
  //       ).then((querySnapshot) => {
  //         const cards = querySnapshot.docs.map((doc) => doc.data());
  //         setOrgCardData(cards);
  //         console.log("cards", cards);
  //       });
  //     });
  //   });
  // }, []);

  // setOrgId(doc.data().organization).then(() => {
  //   getDoc(db, "organizations", doc.data().organization)
  //     .then((doc) => {
  //       if (doc.exists()) {
  //         setOrgData(doc.data());
  //         setIsLoading(false);
  //         setDataToggle(true);
  //         setCompanyLogoUpload(doc?.data()?.companyLogoUrl);
  //         setCompanyCoverPhotoUpload(doc?.data()?.companyCoverPhotoUrl);
  //       }
  //     })
  //     .then(() => {
  //       const subCollectionRef = collection(
  //         db,
  //         "organizations",
  //         orgData?.id,
  //         "cards"
  //       );
  //       const unsubscribe = onSnapshot(
  //         subCollectionRef,
  //         (querySnapshot) => {
  //           const cards = [];
  //           querySnapshot.forEach((doc) => {
  //             cards.push({ ...doc.data(), id: doc.id });
  //           });
  //           setOrgCardData(cards);
  //         }
  //       );
  //       return () => {
  //         unsubscribe();
  //       };
  //     });
  // });
  //   });
  // }, []);
  //   const unsubOrgs = onSnapshot(
  //   getDoc(doc(db, "organizations", orgData?.id))
  //     .then((doc) => {
  //       console.log("loadedDoc");
  //       setOrgData(doc.data());

  //       setOrgId(doc.id);
  //     })
  //     .then(() => {
  //       const subCollectionRef = collection(
  //         db,
  //         "organizations",
  //         orgData?.id,
  //         "cards"
  //       );
  //       const qSnap = getDocs(subCollectionRef);
  //       qSnap.then((querySnapshot) => {
  //         querySnapshot.forEach((doc) => {
  //           setOrgCardData((orgCardData) => [...orgCardData, doc.data()]);
  //         });
  //       });

  //       setIsLoading(false);
  //       setOrgCardChecked(true);
  //     });
  // }, []);
  // , [orgData?.id]

  const navigate = useNavigate();

  const handleSubmit = (values, e) => {
    setIsSubmitting(true);
    // console.log("##VALUES: ", values);
    const orgRef = doc(db, "organizations", orgId);
    console.log("companyLogoUpload", companyLogoUpload);

    const formValues = {
      ...values,
      companyLogoUrl: orgData?.companyLogoUrl,
      companyCoverPhotoUrl: orgData?.companyCoverPhotoUrl,
      company: values.company || orgData?.company,
      companyDescription:
        values.companyDescription || orgData?.companyDescription,
      companyWebsite: values.companyWebsite || orgData?.companyWebsite,
      companyEmail: values.companyEmail || orgData?.companyEmail,
      companyPhone: values.companyPhone || orgData?.companyPhone,
      companyAddress: values.companyAddress || orgData?.companyAddress,
      companyCity: values.companyCity || orgData?.companyCity,
      companyState: values.companyState || orgData?.companyState,
      companyZip: values.companyZip || orgData?.companyZip,
      companyCountry: values.companyCountry || orgData?.companyCountry,
    };

    ImageUploadFunction(
      orgRef,
      companyLogoUpload,
      companyLogoPreview,
      "logos"
    ).then((companyLogoUrl) => {
      ImageUploadFunction(
        orgRef,
        companyCoverPhotoUpload,
        companyCoverPhotoPreview,
        "cover-photos"
      ).then((companyCoverPhotoUrl) => {
        updateOrganization(orgData?.id, {
          ...formValues,
          companyLogoUrl: companyLogoUrl,

          companyCoverPhotoUrl: companyCoverPhotoUrl,
        })
          .then(() => {
            values.cardID.map((cardID) => {
              const cardRef = doc(db, "cards", cardID);
              const cardObject = {
                companyLogoUrl: companyLogoUrl,
                companyCoverPhotoUrl: companyCoverPhotoUrl,
                company: values.company || orgData?.company,
                companyDescription:
                  values.companyDescription || orgData?.companyDescription,
                companyWebsite:
                  values.companyWebsite || orgData?.companyWebsite,
                companyEmail: values.companyEmail || orgData?.companyEmail,
                companyPhone: values.companyPhone || orgData?.companyPhone,
                companyAddress:
                  values.companyAddress || orgData?.companyAddress,
                companyCity: values.companyCity || orgData?.companyCity,
                companyState: values.companyState || orgData?.companyState,
                companyZip: values.companyZip || orgData?.companyZip,
                companyCountry:
                  values.companyCountry || orgData?.companyCountry,
                companyPrimaryTextColor:
                  values.companyPrimaryTextColor ||
                  orgData?.companyPrimaryTextColor,
                primaryBrandColor:
                  values.primaryBrandColor || orgData?.primaryBrandColor,
              };
              updateDoc(cardRef, cardObject).then(() => {
                console.log("updated card");
                const accountRef = doc(
                  db,
                  "accounts",
                  user?.uid,
                  "cards",
                  cardID
                );
                updateDoc(accountRef, cardObject).then(() => {
                  const index = orgCardData.findIndex(
                    (card) => card.cardID === cardID
                  );
                  // console.log("index", index);
                  // console.log("updating limitedAccessUser account");
                  // console.log(orgCardData.indexOf(cardID));

                  const limitedAccessUserAccountRef = doc(
                    db,
                    "accounts",
                    orgCardData[index]?.limitedAccessUser,
                    "cards",
                    cardID
                  );
                  updateDoc(limitedAccessUserAccountRef, cardObject).then(
                    () => {
                      console.log("updated limitedAccessUserAccount");
                    }
                  );
                });
              });
            });
          })
          .then(navigate("/cards"))

          .catch((error) => {
            console.error("##Error updating document: ", error);
          });
      });
    });
  };

  // updateCompanyLogo(orgRef, values.companyLogoUrl).then((cover_photo) => {
  //   updateOrganization("J67AZ6ADHM", {
  //     ...values,
  //     // companyLogoUrl: company_logo ? company_logo : "",
  //     companyCoverPhotoUrl: cover_photo ? cover_photo : "",
  //   });
  //   // console.log("card updated", values);
  // });

  const [primaryBrandColor, setPrimaryBrandColor] = useState(
    orgData?.primaryBrandColor || "#000000"
  );

  const [companyPrimaryTextColor, setCompanyPrimaryTextColor] = useState(
    orgData?.companyPrimaryTextColor || "#ffffff"
  );

  function setCoverPhotoFunction(image) {
    setCompanyCoverPhotoPreview(image);
    setCompanyCoverPhotoUpload(image);
  }

  function setLogoFunction(image) {
    setCompanyLogoPreview(image);
    setCompanyLogoUpload(image);
  }

  const [selectAllCardIDs, setSelectAllCardIDs] = useState(null);
  const [checkedSelectAll, setCheckedSelectAll] = useState(true);
  // function _handleSelectAllCards() {
  //   if (checkedSelectAll === true) {
  //     console.log(selectAllCardIDs);
  //     orgCardData?.map((card) => {
  //       setSelectAllCardIDs([...selectAllCardIDs, card?.cardID]);
  //     });
  //   }
  //   setCheckedSelectAll(!checkedSelectAll);
  //   return selectAllCardIDs;
  // }

  return (
    <div>
      <LoadingOverlay
        active={isSubmitting}
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(255, 255, 255, 0.5)",
          }),
          content: (base) => ({
            ...base,
            background: "white",
            color: "rgba(0, 0, 0, 0.5)",
          }),
          spinner: (base) => ({
            ...base,
            width: "100px",
            "& svg circle": {
              stroke: "rgba(0, 0, 0, 0.5)",
            },
          }),
        }}
        spinner
        text="Saving your changes"
      >
        <div>
          <div className="md:grid md:grid-cols-3 md:gap-6"></div>
        </div>

        <Formik
          initialValues={{
            company: orgData?.company || "",
            companyDescription: orgData?.companyDescription || "",
            companyWebsite: orgData?.companyWebsite || "",
            companyEmail: orgData?.companyEmail || "",
            companyPhone: orgData?.companyPhone || "",
            companyAddress: orgData?.companyAddress || "",
            companyCity: orgData?.companyCity || "",
            companyState: orgData?.companyState || "",
            companyZip: orgData?.companyZip || "",
            companyCountry: orgData?.companyCountry || "",
            companyLogoUrl: orgData?.companyLogoUrl || "",
            companyCoverPhotoUrl: orgData?.companyCoverPhotoUrl || "",
            primaryBrandColor: orgData?.primaryBrandColor || "#000000",
            companyPrimaryTextColor: orgData?.companyTextColor || "#ffffff",
          }}
          onSubmit={async (values, { setSubmitting }) => {
            handleSubmit(values);

            // console.log("clicked, values: ", values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            setFieldValue,
            handleSubmit,

            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit}>
              <div class="fixed bottom-0 w-full md:grid md:grid-cols-2 z-100 bg-white">
                <div className="" aria-hidden="true">
                  <div className="border-t border-gray-200" />

                  <div className="bg-white px-4 py-3 text-center sm:px-6 z-100 relative">
                    <div className="w-40 z-100 float-center px-5 py-3  justify-center rounded-md border border-transparent bg-blue-600  px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700">
                      {isSubmitting ? (
                        <PulseLoader color={"white"} loading={true} size={8} />
                      ) : (
                        <button
                          type="submit"
                          disabled={""}
                          className=" text-sm font-medium text-white shadow-sm hover:bg-blue-700"
                        >
                          Save and close
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-10 sm:mt-0">
                <div className="md:grid md:grid-cols-3 md:gap-6">
                  <div className="md:col-span-1">
                    <div className="px-4 sm:px-0 text-left">
                      <h3 className="text-lg font-medium leading-6 text-gray-900">
                        Group Card Branding
                      </h3>
                      <p className="mt-1 text-sm text-gray-600">
                        <p className="mt-1 text-sm text-gray-600">
                          Add a customised design across some or all cardholders
                          within your organisation.
                        </p>
                      </p>
                    </div>
                  </div>
                  <div className="mt-5 md:col-span-2 md:mt-0 z-0">
                    <div className="overflow-hidden shadow sm:rounded-md text-left z-0">
                      <div className="bg-white px-4 py-5 sm:p-6">
                        <div className="grid grid-cols-6 gap-4">
                          <div className="col-span-6 sm:col-span-6">
                            <ProfileHeader
                              card={{
                                profilePictureUrl: DefaultUserAvatar,
                                companyCoverPhotoUrl:
                                  companyCoverPhotoPreview ||
                                  companyCoverPhotoUpload ||
                                  "",
                                companyLogoUrl:
                                  companyLogoPreview || companyLogoUpload,
                                firstName: "",
                                lastName: "",
                              }}
                            />
                          </div>

                          <div className="col-span-6 sm:col-span-3">
                            <label className="block text-sm font-medium text-gray-700">
                              Cover Photo
                            </label>
                            <div className="mt-1 flex items-center">
                              <StyledImageUpload
                                saveImage={setCoverPhotoFunction}
                                cropShape="rect"
                                length={4}
                                height={1}
                              />
                            </div>
                          </div>

                          <div className="col-span-6 sm:col-span-3">
                            <label className="block text-sm font-medium text-gray-700">
                              Company Logo
                            </label>
                            <div className="mt-1 flex items-center">
                              <StyledImageUpload
                                saveImage={setLogoFunction}
                                cropShape="round"
                                length={1}
                                height={1}
                              />
                            </div>
                          </div>
                          <div className="col-span-6 border-2 border-dashed border-gray-100 rounded-xl p-4 shadow-md">
                            <div className="col-span-3 sm:col-span-3 flex justify-start items-start flex-col ">
                              <div className="flex justify-center items-center">
                                <p className="block text-sm font-medium text-gray-700 pb-2">
                                  Button Design
                                </p>
                              </div>
                              <div
                                style={{
                                  backgroundColor: primaryBrandColor,
                                  color: companyPrimaryTextColor,
                                }}
                                className={`mt-1 flex items-center w-32 h-10 rounded-full flex justify-center items-center`}
                              >
                                Text
                              </div>
                            </div>

                            <div className="col-span-6 sm:col-span-3 pt-4">
                              <label
                                htmlFor="companyCoverPhotoUrl"
                                className="block text-sm font-medium text-gray-700 pb-2"
                              >
                                Button Colour
                              </label>
                              <div className="mt-1 flex items-center bg-gray-200 rounded-xl justify-start px-4">
                                <ColorPicker
                                  handleColorChange={(color) => {
                                    setPrimaryBrandColor(color);
                                    setFieldValue("primaryBrandColor", color);
                                  }}
                                  textColor={companyPrimaryTextColor}
                                />
                              </div>
                            </div>
                            <div className="col-span-0 sm:col-span-3 flex justify-start items-start flex-col"></div>
                            <div className="col-span-6 sm:col-span-3 pt-4">
                              <label
                                htmlFor="companyCoverPhotoUrl"
                                className="block text-sm font-medium text-gray-700 pb-2"
                              >
                                Button Text Colour
                              </label>
                              <div className="mt-1 flex items-start bg-gray-200 rounded-xl justify-start px-4">
                                <TextColorPicker
                                  handleColorChange={(color) => {
                                    setCompanyPrimaryTextColor(color);
                                    setFieldValue(
                                      "companyPrimaryTextColor",
                                      color
                                    );
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="hidden sm:block" aria-hidden="true">
                <div className="py-5">
                  <div className="border-t border-gray-200" />
                </div>
              </div>

              <div className="mt-10 sm:mt-0 pb-28">
                <div className="md:grid md:grid-cols-3 md:gap-6">
                  <div className="md:col-span-1">
                    <div className="px-4 sm:px-0 text-left">
                      <h3 className="text-lg font-medium leading-6 text-gray-900">
                        Company Information
                      </h3>
                      <p className="mt-1 text-sm text-gray-600">
                        <p className="mt-1 text-sm text-gray-600">
                          Update company information across all orgDataholders
                          within your group.
                        </p>
                      </p>
                    </div>
                  </div>
                  <div className="mt-5 md:col-span-2 md:mt-0">
                    <div className="overflow-hidden shadow sm:rounded-md text-left">
                      <div className="bg-white px-4 py-5 sm:p-6">
                        <div className="grid grid-cols-6 gap-6">
                          <div className="col-span-6 sm:col-span-6">
                            <label
                              htmlFor="first-name"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Company name
                            </label>
                            <input
                              type="text"
                              name="company"
                              placeholder={orgData?.company}
                              onChange={(event) => {
                                setFieldValue(
                                  "company",
                                  event.currentTarget.value
                                );
                              }}
                              onBlur={handleBlur}
                              value={values.company}
                              id="company"
                              autoComplete="given-name"
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            />
                          </div>

                          <div className="col-span-6 sm:col-span-6">
                            <div className="col-span-3 sm:col-span-2">
                              <label
                                htmlFor="company-website"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Website
                              </label>
                              <div className="mt-1 flex rounded-md shadow-sm">
                                <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-sm text-gray-500">
                                  http://
                                </span>
                                <input
                                  type="text"
                                  name="companyWebsite"
                                  placeholder={orgData?.companyWebsite}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.companyWebsite}
                                  id="company-website"
                                  className="block w-full flex-1 rounded-none rounded-r-md border-gray-300 focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="last-name"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Company phone number
                            </label>
                            <input
                              type="text"
                              name="companyPhone"
                              placeholder="05 4932 ..."
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.companyPhone}
                              id="last-name"
                              autoComplete="family-name"
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            />
                          </div>

                          <div className="col-span-6 sm:col-span-6">
                            <label
                              htmlFor="first-name"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Country
                            </label>
                            <input
                              type="text"
                              name="companyCountry"
                              placeholder={orgData?.companyCountry}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.companyCountry}
                              id="first-name"
                              autoComplete="given-name"
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            />
                          </div>

                          <div className="col-span-6 sm:col-span-6">
                            <label
                              htmlFor="first-name"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Street address
                            </label>
                            <input
                              type="text"
                              name="companyAddress"
                              placeholder={orgData?.companyAddress}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.companyAddress}
                              id="first-name"
                              autoComplete="given-name"
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            />
                          </div>

                          <div className="col-span-6 sm:col-span-2">
                            <label
                              htmlFor="first-name"
                              className="block text-sm font-medium text-gray-700"
                            >
                              City
                            </label>
                            <input
                              type="text"
                              name="companyCity"
                              placeholder={orgData?.companyCity}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.companyCity}
                              id="first-name"
                              autoComplete="given-name"
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            />
                          </div>
                          <div className="col-span-6 sm:col-span-2">
                            <label
                              htmlFor="first-name"
                              className="block text-sm font-medium text-gray-700"
                            >
                              State / Province
                            </label>
                            <input
                              type="text"
                              name="companyState"
                              placeholder={orgData?.companyState}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.companyState}
                              id="first-name"
                              autoComplete="given-name"
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            />
                          </div>

                          <div className="col-span-6 sm:col-span-2">
                            <label
                              htmlFor="first-name"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Postcode
                            </label>
                            <input
                              type="text"
                              name="companyZip"
                              placeholder={orgData?.companyZip}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.companyZip}
                              id="first-name"
                              autoComplete="given-name"
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="hidden sm:block" aria-hidden="true">
                <div className="py-5">
                  <div className="border-t border-gray-200" />
                </div>
              </div>

              <div className="mt-10 sm:mt-0 pb-28">
                <div className="md:grid md:grid-cols-2 md:gap-6">
                  <div className="md:col-span-1">
                    <div className="px-4 sm:px-0 text-left">
                      <h3 className="text-lg font-medium leading-6 text-gray-900">
                        Apply changes to
                      </h3>
                      <p className="mt-1 text-sm text-gray-600">
                        <p className="mt-1 text-sm text-gray-600">
                          Select the cards you'd like to apply this update to
                        </p>
                      </p>
                    </div>
                  </div>
                  {/* 
            Multiple checkboxes with the same name attribute, but different
            value attributes will be considered a "checkbox group". Formik will automagically
            bind the checked values to a single array for your benefit. All the add and remove
            logic will be taken care of for you.
          */}
                  <div id="checkbox-group" className="relative col-span-6">
                    <div role="group" aria-labelledby="checkbox-group z-0">
                      {/* <div className="flex flex-row justify-start items-start">
                        <label>
                          <Field
                            type="checkbox"
                            name="cardIDS"
                            value={true}
                            onChange={() => {
                              _handleSelectAllCards();
                            }}
                            className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500 "
                          />
                        </label>
                        <p className="text-left ml-2"> Select All</p>
                      </div> */}
                      {orgCardData?.map((card) => (
                        <div className="mt-5 col-span-6 md:mt-0 z-0">
                          <div className="overflow-hidden shadow sm:rounded-md text-left">
                            <div className="bg-white px-4 py-5 sm:p-6 col-span-1">
                              <div className="grid grid-cols-1 gap-6">
                                <div className="col-span-3 justify-start items-center flex flex-row space-x-4">
                                  <label>
                                    <Field
                                      type="checkbox"
                                      name="cardID"
                                      value={card.cardID}
                                      className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                                    />
                                  </label>
                                  <div className="relative z-0">
                                    <ProfilePictureImage
                                      card={card}
                                      height={12}
                                      logoHeight={6}
                                    />
                                  </div>
                                  <p className="ml-2">
                                    {card.firstName} {card.lastName}
                                  </p>
                                </div>

                                <div className="col-span-2 sm:col-span-2"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="hidden sm:block" aria-hidden="true">
                <div className="py-5">
                  <div className="border-t border-gray-200" />
                </div>
              </div>
            </form>
          )}
        </Formik>
      </LoadingOverlay>
    </div>
  );
}

const ColorPicker = ({ textColor, handleColorChange }) => {
  const [color, setColor] = useState("#000000");
  const handleChange = (color) => {
    setColor(color.hex);
    handleColorChange(color.hex);
  };
  return (
    <div className="col-span-6 sm:col-span-3">
      <div className="py-4">
        <CirclePicker
          color={color}
          onChange={(color) => {
            handleChange(color);
          }}
          triangle="hide"
          onSwatchHover={(color) => {
            handleChange(color);
          }}
          colors={[
            "#000000",
            "#FF6900",
            "#FCB900",
            "#7BDCB5",
            "#00D084",
            "#8ED1FC",
            "#0693E3",
            "#EB144C",
            "#F78DA7",
            "#9900EF",
          ]}
        />
      </div>
    </div>
  );
};

const TextColorPicker = ({ textColor, handleColorChange }) => {
  const [color, setColor] = useState("#000000");
  const handleChange = (color) => {
    setColor(color.hex);
    handleColorChange(color.hex);
  };
  return (
    <div className="col-span-6 sm:col-span-3">
      <div className="py-4">
        <CirclePicker
          color={color}
          onChange={handleChange}
          colors={["#000000", "#FFFFFF"]}
          triangle="hide"
          onSwatchHover={(color) => {
            handleChange(color);
          }}
        />
      </div>
    </div>
  );
};
