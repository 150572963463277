/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  const colors = require('tailwindcss/colors')
  
  module.exports = {
    // ...
    theme: {
      extend: {
        colors: {
          cyan: colors.cyan,
        },
      },
    },
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { Fragment, useEffect, useState } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import {
  Bars3CenterLeftIcon,
  BellIcon,
  ClockIcon,
  CogIcon,
  CreditCardIcon,
  DocumentChartBarIcon,
  HandRaisedIcon,
  HomeIcon,
  QuestionMarkCircleIcon,
  ScaleIcon,
  ShieldCheckIcon,
  UserGroupIcon,
  UserPlusIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import {
  BanknotesIcon,
  BuildingOfficeIcon,
  CheckCircleIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  ExclamationCircleIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/20/solid";
import { LineChart } from "../../Components/Charts/LineChart";
import { AdminModal } from "../../Components/Modal/AdminModal";

import { CustomPopover } from "../../Components/Input/CustomPopover";
import { CustomCombobox } from "../../Components/Input/CustomCombobox";
import { UserAuth } from "../../Context/AuthContext";
import ProfilePictureImage from "../../Components/Profile/ProfilePictureImage";
import { useNavigate, useParams } from "react-router-dom";
import {
  arrayRemove,
  arrayUnion,
  doc,
  getDoc,
  onSnapshot,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../API/Firebase/firebase";

const navigation = [
  { name: "Home", href: "#", icon: HomeIcon, current: true },
  { name: "History", href: "#", icon: ClockIcon, current: false },
  { name: "Balances", href: "#", icon: ScaleIcon, current: false },
  { name: "Cards", href: "#", icon: CreditCardIcon, current: false },
  { name: "Recipients", href: "#", icon: UserGroupIcon, current: false },
  { name: "Reports", href: "#", icon: DocumentChartBarIcon, current: false },
];
const secondaryNavigation = [
  { name: "Settings", href: "#", icon: CogIcon },
  { name: "Help", href: "#", icon: QuestionMarkCircleIcon },
  { name: "Privacy", href: "#", icon: ShieldCheckIcon },
];

// const cards = [
//   {
//     id: 1,
//     firstName: "Molly",
//     lastName: "Sanders",
//     href: "#",
//     connections: "12",
//     imageUrl:
//       "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.6&w=256&h=256&q=80",
//   },
//   {
//     id: 2,
//     firstName: "Molly",
//     lastName: "Sanders",
//     href: "#",
//     connections: "12",
//     imageUrl:
//       "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.6&w=256&h=256&q=80",
//   },
//   {
//     id: 3,
//     firstName: "Molly",
//     lastName: "Sanders",
//     href: "#",
//     connections: "12",
//     imageUrl:
//       "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.6&w=256&h=256&q=80",
//   },
//   {
//     id: 4,
//     firstName: "Molly",
//     lastName: "Sanders",
//     href: "#",
//     connections: "12",
//     imageUrl:
//       "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.6&w=256&h=256&q=80",
//   },

//   // More cards...
// ];
const statusStyles = {
  success: "bg-green-100 text-green-800",
  processing: "bg-yellow-100 text-yellow-800",
  failed: "bg-gray-100 text-gray-800",
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function TeamDetailPage() {
  const { cards, userProfile } = UserAuth();
  const [connectionsCount, setConnectionsCount] = useState(0);
  const [connectionsMadeDocs, setConnectionsMadeDocs] = useState([]);
  const organizationID = userProfile.organizations[0];
  const [team, setTeam] = useState();
  // get params from url

  const { teamID } = useParams();
  function getTeam() {
    // Get from firestore document
    const teamRef = doc(db, "organizations", organizationID, "teams", teamID);
    const unsubscribe = onSnapshot(teamRef, (doc) => {
      console.log("Current data: ", doc.data());
      setTeam(doc.data());
    });

    return unsubscribe;
  }
  useEffect(() => {
    getTeam();
    console.log("team", team);
  }, []);

  const navigate = useNavigate();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const [nonTeamMembers, setNonTeamMembers] = useState([]);

  function getNonTeamMembers() {
    const nonTeamMembers = [];
    console.log("TEAM", team);
    cards.forEach((card) => {
      console.log("card", card);
      if (!team?.members.includes(card)) {
        nonTeamMembers.push(card);
      }
    });
    setNonTeamMembers(nonTeamMembers);
  }
  useEffect(() => {
    getNonTeamMembers();
  }, [team]);
  const totals = [
    {
      name: "Team Members",
      href: "#",
      icon: UserGroupIcon,
      amount: team?.members?.length || 0,
    },
    {
      name: "Total Connections Made",
      href: "#",
      icon: UserPlusIcon,
      amount: connectionsCount,
    },
    // More items...
  ];

  function removeTeamMemberFromTeamState(removeTeamMember) {
    // remove addTeamMember from nonTeamMembers array
    const filteredNonTeamMembers = nonTeamMembers.filter(
      (member) => member !== removeTeamMember
    );
    console.log("filteredNonTeamMembers", filteredNonTeamMembers);
    setNonTeamMembers(filteredNonTeamMembers);
  }

  function AddTeamMemberToTeamState(addTeamMember) {
    // add addTeamMember to nonTeamMembers array
    const newNonTeamMembers = nonTeamMembers;
    newNonTeamMembers.push(addTeamMember);
    console.log("newNonTeamMembers", newNonTeamMembers);
    setNonTeamMembers(newNonTeamMembers);
  }

  function handleAddTeamMemberSubmit(addTeamMember) {
    console.log("handleSubmit", addTeamMember);
    removeTeamMemberFromTeamState(addTeamMember);
    // if addTeamMember exists inside the selected array, return null
    if (team?.members?.includes(addTeamMember.cardID)) {
      return null;
    } else {
      // map through each card id in the selectedCards array and add to a new array
      const teamMembers = team?.members;

      teamMembers.push({
        [addTeamMember.cardID]: addTeamMember.cardID,
        cardID: addTeamMember.cardID,
        profilePictureUrl: addTeamMember?.profilePictureUrl || "",
        firstName: addTeamMember?.firstName || "",
        lastName: addTeamMember?.lastName || "",
      });

      console.log("memberIDS", teamMembers);

      // Create a new team document in the organizations collection and then adding the team document id to the user's teams array

      const teamDoc = {
        updatedAt: new Date(),
        members: teamMembers,
      };
      console.log("teamDOCC", teamDoc);
      const teamDocRef = doc(
        db,
        "organizations",
        organizationID,
        "teams",
        teamID
      );
      const setTeamDoc = async () => {
        await updateDoc(teamDocRef, teamDoc, { merge: true });
        console.log("Team doc written with ID: ", teamID);
      };
      setTeamDoc();

      const userTeamObject = {
        id: teamID,
        title: team.title,
        description: team.description,
        dateCreated: new Date(),
      };
      // map through each card id in the selectedCards array and add the team id to the card's teams array

      const cardDocRef = doc(db, "cards", addTeamMember.cardID);
      const setCardDoc = async () => {
        await updateDoc(
          cardDocRef, //arrayUnion
          { teams: arrayUnion(userTeamObject) },

          { merge: true }
        );
      };
      setCardDoc();
      console.log("Card doc written with ID: ", addTeamMember.cardID);
      return null;
    }
  }
  const [selected, setSelected] = useState("");
  useEffect(() => {
    console.log("selected", selected);
  }, [selected]);

  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-gray-100">
        <body class="h-full">
        ```
      */}

      <div className="min-h-full w-full">
        <div className="flex flex-1 flex-col">
          <main className="flex-1 pb-8">
            {/* Page header */}
            <div className="bg-white ">
              <div className="">
                <div className="py-6 md:flex md:items-center md:justify-between lg:border-b lg:border-gray-200">
                  <div className="min-w-0 flex-1">
                    {/* Profile */}
                    <div className="flex items-center">
                      <div>
                        <div className="flex items-center">
                          <h1 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:leading-9">
                            <span className="text-gray-400">Team </span>
                            {team?.title}
                          </h1>
                        </div>
                        <dl className="mt-6 flex flex-col sm:mt-1 sm:flex-row sm:flex-wrap">
                          <dt className="sr-only">Team status</dt>
                          <dd className="mt-3 flex items-center text-sm font-medium capitalize text-gray-500 sm:mr-6 sm:mt-0">
                            {team?.members?.length > 0 ? (
                              <>
                                <CheckCircleIcon
                                  className="mr-1.5 h-5 w-5 flex-shrink-0 text-green-400"
                                  aria-hidden="true"
                                />
                                Active
                              </>
                            ) : (
                              <>
                                <ExclamationCircleIcon
                                  className="mr-1.5 h-5 w-5 flex-shrink-0 text-yellow-400"
                                  aria-hidden="true"
                                />
                                Inactive
                              </>
                            )}
                          </dd>
                        </dl>
                      </div>
                    </div>
                  </div>
                  <div className="mt-6 flex space-x-3 md:mt-0 md:ml-4">
                    <CustomCombobox
                      selected={selected}
                      cards={nonTeamMembers}
                      setSelected={setSelected}
                      handleAddTeamMemberSubmit={handleAddTeamMemberSubmit}
                    />
                    {/* UPDATE - Add ability to archive and remove team members
                    <button
                      type="button"
                     
                      }
                      className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                    >
                      Archive Team
                    </button> */}
                    {/* <button
                      type="button"
                      onClick={() => {
                        setIsOpen(true);
                        console.log("clicked");
                      }}
                      className="inline-flex items-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                    >
                      Add Team Members
                    </button> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-2 text-left">
              <div className="py-4">
                <div className="my-8">
                  <div className="mx-auto ">
                    <h2 className="text-lg font-medium leading-6 text-gray-900 pb-2">
                      Team Members
                    </h2>
                    <div className="mt-4 flex flex-row">
                      {/* Card */}
                      {team?.members?.map((card) => (
                        <div className="relative">
                          <div className="flex items-start justify-between px-4 py-3 flex-col ">
                            <a
                              href={card.href}
                              className="group  text-sm text-center justify-center items-center h-full flex-col cursor-pointer flex"
                            >
                              <div className="w-10 h-10 rounded-full overflow-hidden hover:bg-gray-100 shadow-lg hover:ring hover:ring-blue-700">
                                <div
                                  onClick={() => {
                                    console.log("clicked");
                                    navigate("/cards/" + card.cardID + "/edit");
                                  }}
                                  className="w-10 h-10 rounded-full overflow-hidden hover:bg-gray-100 shadow-lg hover:ring hover:ring-blue-700"
                                >
                                  <ProfilePictureImage
                                    card={card}
                                    width="10"
                                    height="10"
                                    noBorder={true}
                                  />
                                </div>
                                <XMarkIcon
                                  className="absolute z-40 right-0 top-0 w-4 h-4 rounded-full overflow-hidden bg-gray-100 hover:bg-red-500 shadow-lg text-white cursor-pointer"
                                  onClick={async () => {
                                    AddTeamMemberToTeamState(card);
                                    console.log("RemoveTeamMember");
                                    // help me remove item from team.members firebase collection
                                    const teamDocRef = doc(
                                      db,
                                      "organizations",
                                      organizationID,
                                      "teams",
                                      teamID
                                    );
                                    await updateDoc(teamDocRef, {
                                      members: arrayRemove(card),
                                    });

                                    // remove team entry from teams array in card by finding card by cardID

                                    const cardDocRef = doc(
                                      db,
                                      "cards",
                                      card.cardID
                                    );
                                    console.log(
                                      "######cardDocRefIDDDD",
                                      card.cardID
                                    );
                                    const cardDoc = await getDoc(cardDocRef);
                                    const cardDocTeamsData =
                                      cardDoc.data()?.teams;
                                    console.log(
                                      "cardDocTeamsData",
                                      cardDocTeamsData
                                    );

                                    // update doc and remove where card teams array id matches teamID
                                    await updateDoc(cardDocRef, {
                                      teams: cardDocTeamsData.filter(
                                        (team) => team.id !== teamID
                                      ),
                                    });
                                  }}
                                />
                              </div>
                              <p className="truncate w-20 text-gray-500 group-hover:text-gray-900 pt-2">
                                {card.firstName}
                              </p>
                              <p className="truncate w-20 text-gray-500 group-hover:text-gray-900">
                                {card.lastName}
                              </p>
                            </a>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="my-2">
                <div className="mx-auto ">
                  <h2 className="text-lg font-medium leading-6 text-gray-900 pb-6">
                    Team Performance Summary
                  </h2>
                  <div className="pt-1 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
                    {/* Card */}
                    {totals.map((total) => (
                      <div
                        key={total.name}
                        className="overflow-hidden rounded-lg bg-white shadow"
                      >
                        <div className="p-5">
                          <div className="flex items-center">
                            <div className="flex-shrink-0">
                              <total.icon
                                className="h-6 w-6 text-gray-400"
                                aria-hidden="true"
                              />
                            </div>
                            <div className="ml-5 w-0 flex-1">
                              <dl>
                                <dt className="truncate text-sm font-medium text-gray-500">
                                  {total.name}
                                </dt>
                                <dd>
                                  <div className="text-lg font-medium text-gray-900">
                                    {total.amount}
                                  </div>
                                </dd>
                              </dl>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="mx-auto pt-10">
                <h2 className="text-lg font-medium leading-6 text-gray-900">
                  Team Performance
                </h2>
                <div className="mt-2 w-full">
                  {/* Card */}
                  {/* insert chart js line chart here */}

                  <LineChart
                    setConnectionsCount={setConnectionsCount}
                    setConnectionsMadeDocs={setConnectionsMadeDocs}
                    connectionsMadeDocs={connectionsMadeDocs}
                  />
                </div>
              </div>

              <h2 className="mx-auto mt-8 text-lg font-medium leading-6 text-gray-900 pt-8 pb-2">
                Team Connections
              </h2>

              {/* Activity list (smallest breakpoint only) */}
              <div className="shadow sm:hidden w-full">
                <ul
                  role="list"
                  className="mt-2 divide-y divide-gray-200 overflow-hidden shadow sm:hidden"
                >
                  {connectionsMadeDocs?.map((card) => (
                    <li key={card?.id}>
                      <a
                        href={card?.href}
                        className="block bg-white px-4 py-4 hover:bg-gray-50"
                      >
                        <span className="flex items-center space-x-4">
                          <span className="flex flex-1 space-x-2 truncate">
                            <img
                              className="h-16 w-16 rounded-full sm:hidden"
                              src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.6&w=256&h=256&q=80"
                              alt=""
                            />
                            <span className="flex flex-col truncate text-sm text-gray-500">
                              <span className="truncate">
                                {card?.firstName} {card?.lastName}
                              </span>
                              <span>
                                <span className="font-medium text-gray-900">
                                  21
                                </span>
                              </span>
                            </span>
                          </span>
                          <ChevronRightIcon
                            className="h-5 w-5 flex-shrink-0 text-gray-400"
                            aria-hidden="true"
                          />
                        </span>
                      </a>
                    </li>
                  ))}
                </ul>

                <nav
                  className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3"
                  aria-label="Pagination"
                >
                  <div className="flex flex-1 justify-between">
                    <a
                      href="#"
                      className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-500"
                    >
                      Previous
                    </a>
                    <a
                      href="#"
                      className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-500"
                    >
                      Next
                    </a>
                  </div>
                </nav>
              </div>

              {/* Activity table (small breakpoint and up) */}
              <div className="hidden sm:block">
                <div className="mx-auto ">
                  <div className="mt-6 flex flex-col">
                    <div className="min-w-full overflow-hidden overflow-x-auto align-middle shadow sm:rounded-lg">
                      <table className="flex-1 min-w-full divide-y divide-gray-200">
                        <thead>
                          <tr>
                            <th
                              className="bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900"
                              scope="col"
                            >
                              Connection
                            </th>
                            <th
                              className="bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900"
                              scope="col"
                            >
                              Connected with
                            </th>
                            <th
                              className="flex-1 bg-gray-50 px-6 py-3 text-right text-sm font-semibold text-gray-900"
                              scope="col"
                            >
                              Connection Date
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-50 bg-white">
                          {connectionsMadeDocs
                            .slice()
                            .reverse()
                            .map((card) => (
                              <tr key={card?.id} className="bg-white">
                                <td className=" w-4/5 whitespace-nowrap px-6 py-4 text-sm text-gray-900">
                                  <div className="flex">
                                    <a
                                      href={card?.href}
                                      className="group inline-flex space-x-2 truncate text-sm text-center items-center h-full"
                                    >
                                      <p className="truncate text-gray-500 group-hover:text-gray-900 ">
                                        <ProfilePictureImage
                                          card={card}
                                          width="12"
                                          height="12"
                                          noBorder={true}
                                          noLogo={true}
                                        />
                                      </p>
                                      <p>
                                        {card?.firstName} {card?.lastName}
                                      </p>
                                    </a>
                                  </div>
                                </td>
                                <td className=" w-4/5 whitespace-nowrap px-6 py-4 text-sm text-gray-900">
                                  <div className="flex">
                                    <a
                                      href={card?.href}
                                      className="group inline-flex space-x-2 truncate text-sm text-center items-center h-full"
                                    >
                                      {card?.connectionProfilePictureUrl !==
                                      "" ? (
                                        <img
                                          className="h-12 w-12 rounded-full"
                                          src={
                                            card?.connectionProfilePictureUrl
                                          }
                                          alt=""
                                        />
                                      ) : (
                                        <ProfilePictureImage
                                          card={{
                                            firstName:
                                              card?.connectionFirstName,
                                            lastName: card?.connectionLastName,
                                            profilePictureUrl:
                                              card?.connectionProfilePictureUrl,
                                          }}
                                          width="12"
                                          height="12"
                                          noBorder={true}
                                          noLogo={true}
                                        />
                                      )}
                                      <p className="truncate text-gray-500 group-hover:text-gray-900 ">
                                        {card?.connectionFirstName}{" "}
                                        {card?.connectionLastName}
                                      </p>
                                    </a>
                                  </div>
                                </td>
                                <td className="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500">
                                  <span className="font-medium text-gray-900">
                                    {
                                      //convert ISO date  to readable format
                                      new Date(card?.date).toLocaleDateString()
                                    }
                                  </span>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
