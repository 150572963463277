import { Field, FieldArray, Formik } from "formik";
// Form validation
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { UserAuth } from "../../../Context/AuthContext";

// API USER DATA
import { doc, getDoc, onSnapshot, updateDoc } from "firebase/firestore";
import { db, storage } from "../../../API/Firebase/firebase";
import { useState, useEffect } from "react";
import { getDownloadURL, ref, uploadString } from "firebase/storage";
import { nanoid } from "nanoid";
import ProfileHeader from "../../../Components/Profile/ProfileHeader";

import { StyledImageUpload } from "../../../Functions/Crop/ImageUpload";
import { PulseLoader } from "react-spinners";
import LoadingOverlay from "react-loading-overlay";

// Phone
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

const EditProfileSchema = Yup.object().shape({
  // title: Yup.string().min(2, "Too Short").max(50, "Too long").required(""),
});

export default function EditProfileIndex() {
  const [openCrop, setOpenCrop] = useState(false);
  const [photoURL, setPhotoURL] = useState("");

  const setCropModalState = (state) => {
    setOpenCrop(state);
  };
  const updatePhoto = (photo) => {
    setImageUpload(photo);
    setProfilePicturePreview(photo);
  };

  const { updateCard, updateBasicCard } = UserAuth();

  const [card, setCard] = useState({});
  let { id } = useParams();

  const [imageUpload, setImageUpload] = useState(card?.profilePictureUrl || "");
  const [companyLogoUpload, setCompanyLogoUpload] = useState(
    card?.companyLogoUrl || ""
  );
  const [coverPhotoUpload, setCoverPhotoUpload] = useState(
    card?.companyCoverPhotoUrl || card?.coverPhotoUrl || ""
  );

  const [coverPhotoPreview, setCoverPhotoPreview] = useState(
    card?.companyCoverPhotoUrl || card?.coverPhotoUrl || ""
  );
  const [profilePicturePreview, setProfilePicturePreview] = useState(
    card?.profilePictureUrl || ""
  );
  const [companyLogoPreview, setCompanyLogoPreview] = useState(
    card?.companyLogoUrl || ""
  );

  function updateProfilePicture(cardRef, profilePictureUrl) {
    if (profilePictureUrl === card?.profilePictureUrl) {
      const myPromise = new Promise((resolve, reject) => {
        resolve(profilePictureUrl);
      });
      return myPromise;
    } else {
      const myPromise = new Promise((resolve, reject) => {
        const imageRef = ref(storage, "images/profile_pictures/" + nanoid());
        // console.log("ADDED IMAGE URL", profilePictureUrl);
        uploadString(imageRef, profilePictureUrl, "data_url")
          .then((snapshot) => {
            //console.log("Uploaded image! - Download snapshot", snapshot.ref);
            getDownloadURL(snapshot.ref).then((profile_picture) => {
              //   console.log("DOWNLOAD URL", profile_picture);
              updateDoc(cardRef, { profilePictureUrl: profile_picture });
              resolve(profile_picture);
            });
          })
          .catch((error) => {
            // console.log("Error uploading image", error);
          });
      });
      console.log("imageUploaded");
      return myPromise;
    }
  }

  function updateCompanyLogo(cardRef, companyLogoUrl) {
    if (companyLogoUrl === card?.companyLogoUrl) {
      const myPromise = new Promise((resolve, reject) => {
        resolve(companyLogoUrl);
      });
      return myPromise;
    } else {
      const myPromise = new Promise((resolve, reject) => {
        const imageRef = ref(storage, "images/company-logos/" + nanoid());

        uploadString(imageRef, companyLogoUrl, "data_url")
          .then((snapshot) => {
            // console.log("Uploaded logo image!", snapshot);
            getDownloadURL(snapshot.ref).then((company_logo) => {
              updateDoc(cardRef, { companyLogoUrl: company_logo });
              resolve(company_logo);
            });
          })
          .catch((error) => {
            // console.log("Error uploading image", error);
          });
      });
      console.log("imageUploaded");
      return myPromise;
    }
  }

  function updateCoverPhoto(cardRef, coverPhotoUrl) {
    if (coverPhotoUrl === card?.coverPhotoUrl) {
      const myPromise = new Promise((resolve, reject) => {
        resolve(coverPhotoUrl);
      });
      return myPromise;
    } else {
      const myPromise = new Promise((resolve, reject) => {
        const imageRef = ref(storage, "images/cover-photos/" + nanoid());
        uploadString(imageRef, coverPhotoUrl, "data_url")
          .then((snapshot) => {
            console.log("Uploaded logo image!");
            getDownloadURL(snapshot.ref).then((cover_photo) => {
              updateDoc(cardRef, { coverPhotoUrl: cover_photo });
              resolve(cover_photo);
            });
          })
          .catch((error) => {
            // console.log("Error uploading image", error);
          });
      });
      console.log("imageUploaded");
      return myPromise;
    }
  }

  const [phoneValue, setPhoneValue] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const getCountryCode = async () => {
    const response = await fetch("https://ipapi.co/json/");
    const data = await response.json();
    setCountryCode(data.country);
    console.log(countryCode, "country code");
  };

  // API: User's Cards
  useEffect(() => {
    getCountryCode();
    const unsubCards = onSnapshot(doc(db, "cards", id), (doc) => {
      // console.log("Current data: ", doc.data());
      setCard(doc.data());
      setImageUpload(doc.data()?.profilePictureUrl);
      setCompanyLogoUpload(doc.data()?.companyLogoUrl);
      setCoverPhotoUpload(doc.data()?.coverPhotoUrl);
      setPhoneValue(doc.data()?.phone);
    });
    return () => {
      unsubCards();
    };
  }, []);

  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = (values) => {
    setIsSubmitting(true);
    // console.log(values);
    const cardRef = doc(db, "cards", id);
    // console.log("vales", values);
    const organization = card?.organization || "";
    //console.log("submit", profilePicturePreview);
    updateCoverPhoto(cardRef, coverPhotoUpload).then((cover_photo) => {
      updateProfilePicture(cardRef, imageUpload).then((profile_picture) => {
        updateCompanyLogo(cardRef, companyLogoUpload)
          .then((company_logo) => {
            const cardObject = {
              ...card,
              ...values,
              organization: card.organization,
              profilePictureUrl: profile_picture ? profile_picture : "",
              companyLogoUrl: company_logo ? company_logo : "",
              coverPhotoUrl: cover_photo ? cover_photo : "",
              phone: phoneValue ? phoneValue : "",
            };
            updateCard(id, cardObject, organization);
            //console.log("cardObject", cardObject);
            return cardObject;
            // console.log("card updated", values);
          })

          .then(() => {
            navigate("/cards");
          });
      });
    });
  };

  function TextFieldLinks({
    label,
    name,
    value,
    icon,
    onChangeText,
    onBlur,
    remove,
    index,
    ...props
  }) {
    return (
      <div className="flex w-full">
        <div className="col-span-6 sm:col-span-2">
          <label
            htmlFor="first-name"
            className="block text-sm font-medium text-gray-700"
          >
            {label}
          </label>
          <Field
            type="text"
            name={name}
            placeholder={label}
            onChangeText={onChangeText}
            onBlur={onBlur}
            value={value}
            autoCapitalize="none"
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
          />
        </div>
      </div>
    );
  }

  function setCoverPhotoFunction(image) {
    setCoverPhotoPreview(image);
    setCoverPhotoUpload(image);
  }
  function setProfilePictureFunction(image) {
    setImageUpload(image);
    setProfilePicturePreview(image);
  }
  function setLogoFunction(image) {
    setCompanyLogoPreview(image);
    setCompanyLogoUpload(image);
  }

  useEffect(() => {
    console.log("phoneValueSubmit", phoneValue);
  }, [phoneValue]);
  return (
    <>
      <div>
        <div className="md:grid md:grid-cols-3 md:gap-6"></div>
      </div>
      <div className="md:col-span-1">
        <div className="px-4 sm:px-0 text-start">
          <h3 className="text-4xl font-medium leading-6 text-gray-900">
            Edit Profile
          </h3>
        </div>
      </div>
      <div className="hidden sm:block" aria-hidden="true">
        <div className="py-5">
          <div className="border-t border-gray-200" />
        </div>
      </div>
      <LoadingOverlay
        active={isSubmitting}
        spinner={<PulseLoader color="#3B82F6" />}
        text="Updating Profile..."
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(255, 255, 255, 0.9)",
          }),
          content: (base) => ({
            ...base,

            color: "black",
          }),

          wrapper: {
            width: "100%",
            height: "100%",
          },
        }}
      >
        <Formik
          initialValues={{
            firstName: card?.firstName || "",
            lastName: card?.lastName || "",
            profilePictureUrl: imageUpload,
            companyLogoUrl: companyLogoUpload,
            coverPhotoUrl: coverPhotoUpload,
            role: card?.role || "",
            phone: phoneValue || "",
            email: card?.email || "",
            about: card?.about || "",
            note: card?.note || "",
            company: card?.company || "",
            companyWebsite: card?.companyWebsite || "",
            companyPhone: card?.companyPhone || "",
            address1: card?.companyAddress || card?.address1 || "",
            city: card?.companyCity || card?.city || "",
            state: card?.companyState || card?.state || "",
            zip: card?.companyZip || card?.zip || "",
            country: card?.companyCountry || card?.country || "",
            contactExchangeActive: card?.contactExchangeActive || false,
            links: card?.links || [],
          }}
          enableReinitialize={true}
          validateOnMount={true}
          validationSchema={EditProfileSchema}
          onSubmit={async (values, { setSubmitting }) => {
            handleSubmit(values);
            // console.log("clicked");
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,

            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit}>
              <div class="fixed bottom-0 w-full md:grid md:grid-cols-2 ">
                <div className="" aria-hidden="true">
                  <div className="border-t border-gray-200" />
                  <div className="mb-4 w-40 z-100 float-center px-5 py-3  justify-center rounded-md border border-transparent bg-blue-600  px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700">
                    {isSubmitting ? (
                      <PulseLoader color={"white"} loading={true} size={8} />
                    ) : (
                      <button
                        type="submit"
                        disabled={""}
                        className=" text-sm font-medium text-white shadow-sm hover:bg-blue-700"
                      >
                        Save and close
                      </button>
                    )}
                  </div>
                </div>
              </div>

              <div className="mt-10 sm:mt-0">
                <div className="md:grid md:grid-cols-3 md:gap-6">
                  <div className="md:col-span-1">
                    <div className="px-4 sm:px-0 text-left">
                      <h3 className="text-lg font-medium leading-6 text-gray-900">
                        Personal Information
                      </h3>
                      <p className="mt-1 text-sm text-gray-600">
                        <p className="mt-1 text-sm text-gray-600">
                          This information will be displayed publicly when you
                          share your Hypacard?. It will also appear within your
                          contact information when sharing information.
                        </p>
                      </p>
                    </div>
                  </div>

                  <div className="mt-5 md:col-span-2 md:mt-0">
                    <div className="overflow-hidden shadow sm:rounded-md text-left">
                      <div className="bg-white px-4 py-5 sm:p-6">
                        <div className="grid grid-cols-6 gap-6">
                          <div className="col-span-6 sm:col-span-6 space-y-4">
                            <ProfileHeader
                              card={{
                                profilePictureUrl:
                                  profilePicturePreview || imageUpload,
                                coverPhotoUrl:
                                  card?.companyCoverPhotoUrl ||
                                  coverPhotoPreview ||
                                  coverPhotoUpload,
                                companyLogoUrl:
                                  companyLogoPreview || companyLogoUpload,
                                firstName: card?.firstName,
                                lastName: card?.lastName,
                              }}
                            />
                          </div>

                          <div className="col-span-6 sm:col-span-2">
                            <label className="block text-sm font-medium text-gray-700">
                              Cover Photo
                            </label>
                            <StyledImageUpload
                              saveImage={setCoverPhotoFunction}
                              cropShape="rect"
                              length={5}
                              height={2}
                            />
                          </div>
                          <div className="col-span-6 sm:col-span-2">
                            <label className="block text-sm font-medium text-gray-700">
                              Profile Photo
                            </label>
                            <StyledImageUpload
                              saveImage={setProfilePictureFunction}
                              cropShape="round"
                              length={1}
                              height={1}
                            />
                          </div>
                          <div className="col-span-6 sm:col-span-2">
                            <label className="block text-sm font-medium text-gray-700">
                              Company Logo
                            </label>
                            <StyledImageUpload
                              saveImage={setLogoFunction}
                              cropShape="round"
                              length={1}
                              height={1}
                            />
                          </div>

                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="first-name"
                              className="block text-sm font-medium text-gray-700"
                            >
                              First name
                            </label>
                            <input
                              type="text"
                              name="firstName"
                              placeholder="First name"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.firstName}
                              id="first-name"
                              autoComplete="given-name"
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            />
                          </div>

                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="last-name"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Last name
                            </label>
                            <input
                              type="text"
                              name="lastName"
                              placeholder="Last Name"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.lastName}
                              id="last-name"
                              autoComplete="family-name"
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            />
                          </div>

                          <div className="col-span-6 sm:col-span-6">
                            <label
                              htmlFor="first-name"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Job title / Position
                            </label>
                            <input
                              type="text"
                              name="role"
                              placeholder="Role"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.role}
                              id="first-name"
                              autoComplete="given-name"
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            />
                          </div>

                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="first-name"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Phone number*
                            </label>
                            <PhoneInput
                              defaultCountry={countryCode || "AU"}
                              placeholder=""
                              value={phoneValue}
                              international={true}
                              onChange={setPhoneValue}
                              inputProps={{
                                name: "phone",
                              }}
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            />
                            <p className="text-xs text-gray-500">
                              *Must entered in international '+' format
                            </p>
                            {/* <input
                              type="text"
                              name="phone"
                              placeholder="Phone"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.phone}
                              id="phone"
                              autoComplete="phone"
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            /> */}
                          </div>

                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="last-name"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Email address
                            </label>
                            <input
                              type="text"
                              name="email"
                              placeholder="your@email.com"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.email}
                              id="email"
                              autoComplete="email"
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            />
                          </div>

                          <div className="col-span-6 sm:col-span-6">
                            <label
                              htmlFor="about"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Profile note
                            </label>
                            <div className="mt-1">
                              <textarea
                                id="about"
                                name="about"
                                placeholder="Add a brief note about yourself here"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.about}
                                rows={3}
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="hidden sm:block" aria-hidden="true">
                <div className="py-5">
                  <div className="border-t border-gray-200" />
                </div>
              </div>

              <div className="mt-10 sm:mt-0">
                <div className="md:grid md:grid-cols-3 md:gap-6">
                  <div className="md:col-span-1">
                    <div className="px-4 sm:px-0 text-left">
                      <h3 className="text-lg font-medium leading-6 text-gray-900">
                        Company Information
                      </h3>
                      <p className="mt-1 text-sm text-gray-600">
                        <p className="mt-1 text-sm text-gray-600"></p>
                      </p>
                    </div>
                  </div>
                  <div className="mt-5 md:col-span-2 md:mt-0">
                    <div className="overflow-hidden shadow sm:rounded-md text-left">
                      <div className="bg-white px-4 py-5 sm:p-6">
                        <div className="grid grid-cols-6 gap-6">
                          <div className="col-span-6 sm:col-span-6">
                            <label
                              htmlFor="first-name"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Company name
                            </label>
                            <input
                              type="text"
                              name="company"
                              placeholder="Acme Corp."
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.company}
                              id="company"
                              autoComplete="given-name"
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            />
                          </div>

                          <div className="col-span-6 sm:col-span-6">
                            <div className="col-span-3 sm:col-span-2">
                              <label
                                htmlFor="company-website"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Website
                              </label>
                              <div className="mt-1 flex rounded-md shadow-sm">
                                <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-sm text-gray-500">
                                  http://
                                </span>
                                <input
                                  type="text"
                                  name="companyWebsite"
                                  placeholder="www.yourcompany.com"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.companyWebsite}
                                  id="company-website"
                                  className="block w-full flex-1 rounded-none rounded-r-md border-gray-300 focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="last-name"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Company phone number
                            </label>
                            <input
                              type="text"
                              name="companyPhone"
                              placeholder="05 4932 ..."
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.companyPhone}
                              id="last-name"
                              autoComplete="family-name"
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            />
                          </div>

                          <div className="col-span-6 sm:col-span-6">
                            <label
                              htmlFor="first-name"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Country
                            </label>
                            <input
                              type="text"
                              name="country"
                              placeholder="Australia"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.country}
                              id="first-name"
                              autoComplete="given-name"
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            />
                          </div>

                          <div className="col-span-6 sm:col-span-6">
                            <label
                              htmlFor="first-name"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Street address
                            </label>
                            <input
                              type="text"
                              name="address1"
                              placeholder=""
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.address1}
                              id="first-name"
                              autoComplete="given-name"
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            />
                          </div>

                          <div className="col-span-6 sm:col-span-2">
                            <label
                              htmlFor="first-name"
                              className="block text-sm font-medium text-gray-700"
                            >
                              City
                            </label>
                            <input
                              type="text"
                              name="city"
                              placeholder=""
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.city}
                              id="first-name"
                              autoComplete="given-name"
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            />
                          </div>
                          <div className="col-span-6 sm:col-span-2">
                            <label
                              htmlFor="first-name"
                              className="block text-sm font-medium text-gray-700"
                            >
                              State / Province
                            </label>
                            <input
                              type="text"
                              name="state"
                              placeholder=""
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.state}
                              id="first-name"
                              autoComplete="given-name"
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            />
                          </div>

                          <div className="col-span-6 sm:col-span-2">
                            <label
                              htmlFor="first-name"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Postcode
                            </label>
                            <input
                              type="text"
                              name="zip"
                              placeholder=""
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.zip}
                              id="first-name"
                              autoComplete="given-name"
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="hidden sm:block" aria-hidden="true">
                <div className="py-5">
                  <div className="border-t border-gray-200" />
                </div>
              </div>

              <div className="mt-10 sm:mt-0">
                <div className="md:grid md:grid-cols-3 md:gap-6">
                  <div className="md:col-span-1">
                    <div className="px-4 sm:px-0 text-left">
                      <h3 className="text-lg font-medium leading-6 text-gray-900">
                        Hypa-Links
                      </h3>
                      <p className="mt-1 text-sm text-gray-600">
                        <p className="mt-1 text-sm text-gray-600"></p>
                      </p>
                    </div>
                  </div>
                  <div className="mt-5 md:col-span-2 md:mt-0">
                    <div className="overflow-hidden sm:rounded-md text-left">
                      <div className="bg-white py-5 sm:p-6">
                        <div className="w-full">
                          <FieldArray name="links">
                            {({
                              move,
                              swap,
                              push,
                              insert,
                              unshift,
                              pop,
                              remove,
                            }) => (
                              <div className="justify-center items-center w-full">
                                {values.links?.map((key, index) => (
                                  <div className="flex flex-col justify-center items-center w-full">
                                    <div
                                      key={key.id}
                                      className="py-2 rounded-xl w-full flex flex-row justify-center items-center space-x-2"
                                    >
                                      <TextFieldLinks
                                        name={`links.${index}.name`}
                                        label="Link Title"
                                        icon="pencil"
                                        onChangeText={handleChange(
                                          `links.${index}.name`
                                        )}
                                        value={key.name}
                                        remove={remove}
                                        index={index}
                                      />
                                      <TextFieldLinks
                                        name={`links.${index}.url`}
                                        label="Copy Link URL Here"
                                        icon="link"
                                        onChangeText={handleChange(
                                          `links.${index}.url`
                                        )}
                                        value={key.url}
                                        remove={remove}
                                        index={index}
                                      />
                                    </div>
                                    <div className="w-full justify-end items-end flex">
                                      <button
                                        className="flex justify-end items-end cursor"
                                        type="button"
                                        onClick={() => remove(index)} // remove an item from the list
                                      >
                                        <div className=" px-4">
                                          <p className="font-medium text-blue-700 p-1 text-sm">
                                            Remove Link
                                          </p>
                                        </div>
                                      </button>
                                    </div>
                                  </div>
                                ))}
                                <div className="w-full flex justify-start items-center">
                                  <button
                                    type="button"
                                    onClick={() =>
                                      push({
                                        id: nanoid(),
                                        icon: "link",
                                        label: "Copy URL Here",
                                      })
                                    }
                                  >
                                    <div className=" my-2 px-10 py-2 bg-blue-600 rounded-xl flex flex-row justify-center items-center">
                                      <p className="font-medium text-white mr-2">
                                        Add New Link
                                      </p>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={2}
                                        stroke="white"
                                        className="w-5 h-5 text-center"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          d="M12 4.5v15m7.5-7.5h-15"
                                        />
                                      </svg>
                                    </div>
                                  </button>
                                </div>
                              </div>
                            )}
                          </FieldArray>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="" aria-hidden="true">
                <div className="py-5">
                  <div className="border-t border-gray-200" />
                </div>
              </div>

              <div className="mt-10 sm:mt-0 pb-60">
                <div className="md:grid md:grid-cols-3 md:gap-6 text-left">
                  <div className="md:col-span-1">
                    <div className="px-4 sm:px-0">
                      <h3 className="text-lg font-medium leading-6 text-gray-900">
                        Settings
                      </h3>
                      <p className="mt-1 text-sm text-gray-600">
                        Select the settings you want to enable.
                      </p>
                    </div>
                  </div>
                  <div className="mt-5 md:col-span-2 md:mt-0">
                    <div className="overflow-hidden shadow sm:rounded-md">
                      <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                        <fieldset>
                          <legend className="sr-only">By Email</legend>
                          <div
                            className="text-base font-medium text-gray-900"
                            aria-hidden="true"
                          >
                            Contact Exchange
                          </div>
                          <div className="mt-4 space-y-4">
                            <div className="flex items-start">
                              <div className="flex h-5 items-center">
                                <input
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  checked={values.contactExchangeActive}
                                  id="contactExchangeActive"
                                  name="contactExchangeActive"
                                  type="checkbox"
                                  className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                                />
                              </div>
                              <div className="ml-3 text-sm">
                                <label
                                  htmlFor="comments"
                                  className="font-medium text-gray-700"
                                >
                                  Tick to activate contact exchange
                                </label>
                                <p className="text-gray-500">
                                  Include an automated popup to exchange contact
                                  information with the person you're sharing
                                  your contact details when you share your
                                  Hypacard?.
                                </p>
                              </div>
                            </div>
                          </div>
                        </fieldset>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </LoadingOverlay>
    </>
  );
}
