import React from "react";
import { AppStoreButtons } from "../../../../Components/Button/AppStoreButtons";

export function CreateCardPhoneIndex() {
  return (
    <div className="flex flex-col justify-center items-center h-full -my-10">
      <div className="py-4 flex-col">
        <p className="text-lg font-semibold flex-wrap mx-4 py-2 text-black">
          Your card is created!
        </p>
      </div>

      <div className="flex flex-col justify-center items-center text-center bg-gradient-to-tr from-blue-800 via-blue-600 to-fuchsia-400 h-44 w-80 rounded-xl shadow-xl">
        <AppStoreButtons />
      </div>

      <div className="py-2 flex-col">
        <p className="text-sm font-semibold flex-wrap mx-4 py-2 text-gray-500">
          Be prepared for every opportunity. <br /> Download the Hypacard app to
          complete your profile.
        </p>
      </div>
    </div>
  );
}
