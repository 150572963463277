import React from "react";
import { QRCode } from "react-qrcode-logo";
import hypacardIcon from "../../Assets/Images/hypacardIcon.png";

function QrCodeIndex({ code, size }) {
  return (
    <div className="w-full h-full flex justify-center items-center">
      <QRCode
        value={`https://hypa.to/${code}`}
        ecLevel="H"
        quietZone={13}
        quality={100}
        scale={10}
        logoWidth={60}
        logoHeight={60}
        logoRadius={200}
        qrStyle="dots"
        size={size || 300}
        eyeRadius={10}
        logoImage={hypacardIcon}
        removeQrCodeBehindLogo={true}
      />
    </div>
  );
}

export default QrCodeIndex;
