import React from "react";

export function ButtonPrimary({ title, handleClick, color, textColor }) {
  return (
    <button
      onClick={() => {
        handleClick();
      }}
      type="button"
      className={`inline-flex items-center rounded-full border border-gray-300 bg-white px-4 py-2 text-base font-medium text-${textColor} shadow-xl hover:bg-${color}-800 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 bg-${color}-700 hover:bg-blue-700 hover:text-white`}
    >
      {title}
    </button>
  );
}

export function CardButton({ title, handleClick, color, textColor }) {
  return (
    <button
      type="button"
      onClick={() => handleClick()}
      style={{ backgroundColor: color }}
      className={`flex w-full justify-center rounded-3xl border border-gray-300 py-6 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2`}
    >
      <span className="ml-2">Primary Brand Colour</span>
    </button>
  );
}
