import React from "react";
import ProfilePictureImage from "./ProfilePictureImage";

function ProfileHeader({ card }) {
  return (
    <div className="">
      <div className="flex flex-col items-center justify-center h-52">
        {/* Profile header */}

        {card?.companyCoverPhotoUrl && card?.companyCoverPhotoUrl !== "" ? (
          <div className="h-52 w-full object-cover lg:h-48 bg-white rounded-2xl">
            <img
              className="h-52 w-full object-cover lg:h-48 rounded-2xl"
              src={card?.companyCoverPhotoUrl}
              alt=""
            />
          </div>
        ) : card?.coverPhotoUrl && card?.coverPhotoUrl !== "" ? (
          <div className="h-52 w-full object-cover lg:h-48 bg-white rounded-2xl">
            <img
              className="h-52 w-full object-cover lg:h-48 rounded-2xl"
              src={card?.coverPhotoUrl}
              alt=""
            />
          </div>
        ) : (
          <div className="h-52 w-full object-cover lg:h-48 bg-gradient-to-tr from-blue-800 via-indigo-800 to-fuchsia-700 rounded-2xl"></div>
        )}
      </div>
      <div className="mx-auto z-100">
        <div className="-mt-32 text-center h-52">
          <div className="flex justify-center h-52">
            <ProfilePictureImage card={card} height={52} logoHeight={14} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfileHeader;
