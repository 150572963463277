import React from "react";
import { CSVLink } from "react-csv";

export function ExportCSV({ children, connections }) {
  const headers = [
    { label: "first_name", key: "firstName" },
    { label: "last_name", key: "lastName" },
    { label: "role", key: "role" },
    { label: "phone_number", key: "phone" },
    { label: "email", key: "email" },
    { label: "company_name", key: "company" },
    { label: "contacted_through_card_id", key: "connectedBy" },
    {
      label: "contacted_through_first_name",
      key: "connectionFirstName",
    },
    {
      label: "contacted_through_last_name",
      key: "connectionLastName",
    },
    { label: "organisation_id", key: "organization" },
    { label: "organisation_name", key: "organizationName" },
    { label: "date_added", key: "dateAdded" },
  ];

  const data = connections.map((contact) => {
    return {
      firstName: contact.firstName || "",
      lastName: contact.lastName || "",
      role: contact.role || "",
      email: contact.email || "",
      phone: contact.phone || "",
      company: contact.company || "",
      connectedBy: contact.connectedBy || "",
      connectionFirstName: contact.connectionFirstName || "",
      connectionLastName: contact.connectionLastName || "",
      organization: contact.organization || "",
      organizationName: contact.organizationName || "",
      dateAdded: contact.dateAdded || "",
    };
  });

  return (
    <CSVLink data={data} headers={headers} filename={"Hypacard Contact List"}>
      {children}
    </CSVLink>
  );
}
