import './App.css';
import {
  BrowserRouter,
  Routes,
  Route,

  Navigate,
  useLocation,
  Outlet,
} from "react-router-dom";
import AppRoute from './Layouts/Menu/Index';
import DashboardIndex from './Pages/Dashboard/Index';
import EditProfileIndex from './Pages/Profile/EditProfile/Index';
import CardsIndex from './Pages/Profile/ProfileLandingPage/Index';
import AnalyticsIndex from './Pages/Analytics/Index';
import SettingsIndex from './Pages/Settings/Index';
import ContactsIndex from './Pages/Contacts/Index';
import PublicProfileIndex from './Pages/Public/Profile/Index';
import SignInIndex from './Pages/Registration/Signin';
import RegisterIndex from './Pages/Registration/Register';
import { AuthContextProvider, useAuthState, UserAuth } from './Context/AuthContext';
import PublicMenuIndex from './Layouts/Public/Index';
import FrontendIndex from './Pages/Frontend/Index';
import LandingIndex from './Pages/Landing/Landing';
import CreateNewCardIndex from './Pages/Profile/CreateNewCard/Index';
import EventsIndex from './Pages/Events/Index';
import EventsList from './Pages/Events/List/EventList';
import EventNavLayout from './Pages/Events/Layout/ NavLayout';
import CalendarIndex from './Pages/Events/EventCalendar/Calendar';
import EventDetailPage from './Pages/Events/EventDetailPage/Index';
import EventDetailLayout from './Pages/Events/Layout/EventDetailLayout';
import CreateEvent from './Pages/Events/CreateEvent/CreateEvent';
import PasswordReset from './Pages/Registration/PasswordReset';
import ProfileNavLayout from './Pages/Profile/Layout/ NavLayout';
import GroupSettingsIndex from './Pages/Profile/GroupSettings/Index';
import { BasicAppIndex } from './Pages/App/Basic/Index';
import BasicAppLayout from './Pages/App/Basic/Layout/BasicAppLayout';
import EditProfileBasicIndex from './Pages/App/Basic/EditProfileBasic';
import { ContactsBasicIndex } from './Pages/App/Basic/Contacts';
import { SignInLinkIndex } from './Pages/App/SignIn';
import BasicCardRegistration from './Pages/Registration/BasicCardRegistration';
import { auth } from './API/Firebase/firebase';
import { useEffect, useState } from 'react';
import { AppStoreRedirect } from './Functions/Routing/Index';
import AppLinks from './Pages/Download/Index';
import SignInPhoneIndex from './Pages/Registration/SigninPhone';
import { CreateCardPhoneIndex } from './Pages/Profile/CreateNewCard/PhoneCreate/Index';
import { OnboardingScreen } from './Pages/Registration/OnboardingScreen';
import ActivateIndex from './Pages/Registration/Activate';
import ActivateIndex1 from './Pages/Public/Profile/Claim/Activate';
import { Helmet } from 'react-helmet';
import TeamsIndex from './Pages/Teams/TeamsIndex';
import TeamDetailPage from './Pages/Teams/TeamDetailPage';
import CreateTeamPage from './Pages/Teams/CreateTeamPage';



// const CurrentUser = () => {
//   const [user, loading, error] = useAuthState(auth);
//   if (loading) {
//     return (
//       <div>
//         <p>Initialising User...</p>
//       </div>
//     );
//   }
//   if (error) {
//     return (
//       <div>
//         <p>Error: {error}</p>
//       </div>
//     );
//   }
//   if (user) {
//     return (
//       <div>
//         <p>Current User: {user.email}</p>
//         <button onClick={SignOut}>Log out</button>

//       </div>
//     );
//   }
//   return <button onClick={SignInWithGoogle}>Log in</button>;

// };



// // Only show route if user is authenticated
const AuthenticatedRoute = ({ children, ...props }) => {
  const { user, authLoading, userProfile, signedIn, setUser } = UserAuth();
  
  const [counter, setCounter] = useState(0)
  const [token, setToken] = useState(null)
  // console.log('AuthenticatedRoute | USER | AUTHLOADING |', user, authLoading, userProfile);


  if (user) {
    
     if (user.role === undefined && counter < 20) {
       auth.currentUser.getIdToken(true)
        
        .then(() => {
           auth.currentUser.getIdTokenResult().then((idTokenResult) => {
            // console.log('IDTOKENCLAIM1', idTokenResult.claims.role, 'user', user);
            // console.log('USERTOKEN', user)
            return idTokenResult.claims.role;
          }).then((tokenID) => { setToken(tokenID);  return tokenID }).then((tokenID) => {
            const newUser = { ...user, role: tokenID }
            setUser(newUser)
            
            // console.log('NEWUSER', newUser)
          
          }).then(() => {
            setCounter(counter + 1)
            // console.log('count', counter)
          })
        })
         .catch((error) => {

          //  console.log('error', error)
         })
     }
  }
    
 
  return  (
    user !== null ?
      
      // token !== null ?
        
  
        <AppRoute />
        // :
        // <div>
        //   <p>Initialising User...</p>
        // </div>
     

  
    : <Navigate to="/" />
  )
  // if (authLoading === true) {
  //   console.log('authLoading', authLoading);
  //   return (
  //     <div>
  //       <p>Initialising User...</p>
  //     </div>
  //   );
  // }
  
  // if (user && userProfile.role?.admin === true) {
  //   return (
  //       <Navigate to="/app" />
      
  //   );
  // }
  // if (user && userProfile.role?.basic === true) {
  //   return (
  //       <>
  //       <p>Current User: {user.email} {userProfile.role?.admin === true ? <p>basic YES</p> : <><p>NOT BASIC</p></>}</p>
  //       <AppRoute />
  //       </>
      
  //   );
  // }
  // return <button >Log in</button>;

};


// const RequireAuth = () => {
//   const { user, authLoading, userProfile, signedIn } = UserAuth();
//   const allowedRoles = ['admin', 'basic'];
//   return (
//     // userProfile?.role?.find((role) => role === 'admin')
//       allowedRoles.find((role) => role === 'admin')
//       ? (
//       <Outlet />
//     ) :
//       user ? 
//         <Navigate to="/cards" /> :
//         <Navigate to="/signin" />

//   );
// };


// Return only if user is unauthenticated
const UnauthenticatedRoute = ({ children, ...props }) => {
  const { user, authLoading, userProfile, signedIn, setUser } = UserAuth();
  
  // console.log('UNAUTHuser', user);

  // if (authLoading === false) {
  //   console.log('authLoading', authLoading, user);
  //   return (
  //     <div>
  //       <p>Initialising User...</p>
  //     </div>
  //   );
  // }
  // if (user === null) {
  //   return (
  //    <PublicLayout />
  //   );
  // }
  // if (user && userProfile.role?.admin === true) {
  //   console.log('userProfile', userProfile);
  //   return (
  //       <Navigate to="/settings" />
  //   );
  // }
  
  // if (user !== null && userProfile?.role?.admin === true) {
  //   console.log('user !== null & userProfile ADMIN === TRUE', user);
  //   return (
  //     <Navigate to="/app" />
  //   );
  // }
  // if (user === null) {
  //   console.log('user === null', user);
  //   return (
  //     <PublicLayout />
  //   );
  // }
  // if (user !== null && userProfile?.role?.basic === true) {
  //   console.log('user !== null', user);
  //   return (
  //     <Navigate to="/app" />
  //   );
  // }
  // if (user !== null) {
  //   console.log('user !== null', user);
  //   return (
  //     <div>user not null but no profile
  //       <button>Logout</button>


  //     </div>

  //   );
  // }
  const [userToken, setUserToken] = useState(null)

   if (user && userToken === null) {
    
     if (user.role === undefined) {
       user.getIdToken(true)
        
        .then(() => {
          user.getIdTokenResult().then((idTokenResult) => {
            // console.log('IDTOKENCLAIM2', idTokenResult.claims.role, 'user', user);
            const newUser = { ...user, role: idTokenResult.claims.role }
            // console.log('NEWUSER', newUser)
            setUser(newUser)
             setUserToken(idTokenResult.claims.role)
            // console.log('USERTOKEN', user)
              // if (idTokenResult.claims.role !== undefined) {
              //   setUserToken(idTokenResult.claims.role)
                
              //   console.log('userToken', userToken)
              //          console.log('user', user)
              // }
            
          
         
          }
          )
        }
        )
     } else {
       setUserToken(user.role)
      //  console.log('userToken ELSE', userToken)
      //  console.log('user ELSE', user)
      }
   }
   
  if (user !== null && userToken === null) {
    setUserToken(user.role)
    // console.log('User Token set', userToken)
  }

  return (
   user === null ?
      <PublicLayout />
      :
      (user && userToken !== null) ?

        <Navigate to="/cards" /> :
        <div>Loading...</div>
   
      
  );
};


  
// const RequireAuth = ({ children, ...props }) => {
//   const { user, authLoading, userProfile } = UserAuth();

//   return (
//     user?.uid ? r
//         <AppRoute > 
//         <AuthenticatedRoute {...props} />
//          </AppRoute>
//         : <Navigate to="/register" />
//   )
// }


 
const PublicLayout = () => {

  return ( <Outlet /> )
  
}
function App() {

// const {setUser} = UserAuth()
  
  
// subscribe to user auth state
  useEffect(() => {
    
    const unsubscribe = auth.onAuthStateChanged((user) => {
      // const { setUser } = UserAuth()
      if (user) {
        user.getIdTokenResult().then((idTokenResult) => {
          // setUser({...user, role: idTokenResult.claims.role})
          // console.log('IDTOKENCLAIM USERAUTH', idTokenResult.claims.role);
        }
        );
        // console.log('user', user);
        // setUserProfile(userProfile);
        // console.log('userProfile', userProfile);
        // setAuthLoading(false);
        // console.log('authLoading', authLoading);
        // setSignedIn(true);
        // console.log('signedIn', signedIn);
      } else {
        // console.log('user', user);
      }
    });

    return unsubscribe;
  }, []);



 
  return (
    <AuthContextProvider>
   
    <div className="App">
      <BrowserRouter>
          <Routes>
           
            <Route path="/" element={<UnauthenticatedRoute />} >
                <Route path="/" element={<SignInIndex />} />
          
            </Route>
                <Route path="/public" element={<PublicProfileIndex />} />
                <Route path="/download" element={<AppStoreRedirect />} />
                <Route path="/applinks" element={<AppLinks />} />
            <Route path="/h/:id" element={<PublicProfileIndex />} />
            <Route path="/:id" element={<PublicProfileIndex />} />
                <Route path="/signin" element={<SignInIndex />} />
                <Route path="/activate/:id" element={<ActivateIndex />} />
                <Route path="/create" element={<SignInPhoneIndex />} />
                <Route path="/reset" element={<PasswordReset />} />
                <Route path="/register" element={<RegisterIndex />} />
                <Route path="/cardlink/:id" element={<BasicCardRegistration />} />
                {/* <Route path="/activateHender" element={<ActivateIndex1 />} /> */}
             
       
 
            <Route element={<AuthenticatedRoute />}>
                  <Route path="/create/card" element={<CreateCardPhoneIndex />} />
                <Route path="/create/details" element={<OnboardingScreen />} />
               <Route element={<BasicAppLayout />} >
              <Route path="app" element={<BasicAppIndex />} />
              <Route path="app/edit" element={<EditProfileBasicIndex />} />
              <Route path="app/contacts" element={<ContactsBasicIndex />} />
              </Route>
              

          
                <Route path="/dashboard" element={ <DashboardIndex/> } />
              <Route element={<ProfileNavLayout />}>
                  
                 <Route path="/cards" element={ <CardsIndex/> } />
               
                 <Route path="/group" element={<GroupSettingsIndex/>} />
                </Route>
   
                <Route path="/teams" element={<TeamsIndex />} />
                <Route path="/teams/:teamID" element={<TeamDetailPage />} />
                <Route path="/teams/create" element={<CreateTeamPage />} />
               <Route element={<EventNavLayout/>}>
                  <Route path="/events" element={<EventsList />} />
                  <Route path="/events/:eventID" element={<EventDetailPage />} />
                  <Route path="/events/calendar" element={<CalendarIndex />} />
                </Route>
                  <Route path="/cards/:id/edit" element={<EditProfileIndex />} />
                  <Route path="/cards/new" element={<CreateNewCardIndex />} />
               
                <Route element={<EventDetailLayout />}>
                    <Route path="/events/:id" element={<EventDetailPage />} />
                    <Route path="/events/new" element={<CreateEvent />} />
                </Route>
                <Route path="/analytics" element={<AnalyticsIndex />} />
                  <Route path="/settings" element={<SettingsIndex />} />
              <Route path="/contacts" element={<ContactsIndex />} />
              </Route>
            
      
               
{/* 
            Missing Route Path
            <Route path="*" element={<Navigate to="/dashboard" />} /> */}
       
      </Routes>
      </BrowserRouter>
      </div>
      </AuthContextProvider>
  
  );
}

export default App;
