import { doc, updateDoc } from "firebase/firestore";
import { Field, Formik } from "formik";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { db } from "../../API/Firebase/firebase";
import { UserAuth } from "../../Context/AuthContext";

export function OnboardingScreen() {
  const { auth, user } = UserAuth();
  const location = useLocation();
  const { state } = location;
  const { cardId, phoneNumber } = state || {};

  console.log("cardID", cardId, "phoneNumber", phoneNumber);
  const navigate = useNavigate();

  const ONBOARDINGINPUTS = [
    {
      type: "text",
      name: "firstName",
      keyboardType: "default",
      label: "First Name",
      placeholder: "First Name",
    },
    {
      type: "text",
      name: "lastName",
      keyboardType: "default",
      label: "Last Name",
      placeholder: "Last Name",
    },
    {
      type: "text",
      name: "phone",
      keyboardType: "phone-pad",
      label: "Phone Number",
      placeholder: phoneNumber,
    },
    {
      type: "email",
      name: "email",
      keyboardType: "email-address",
      label: "Email",
      placeholder: "Email",
    },
    {
      type: "text",
      name: "role",
      keyboardType: "default",
      label: "Role",
      placeholder: "Role",
    },
    {
      type: "text",
      name: "company",
      keyboardType: "default",
      label: "Company",
      placeholder: "Company",
    },
  ];

  const handleSubmit = async (values) => {
    const submitObject = {
      phone: phoneNumber,
      ...values,
    };

    await updateDoc(doc(db, "cards", cardId), {
      ...submitObject,
    }).then(() => {
      updateDoc(doc(db, "accounts", user.uid, "cards", cardId), {
        ...submitObject,
      })
        .then(() => {
          // UPDATE THIS TO REDIRECT TO THE APP REDIRECT HOME PAGE
          navigate("/create/card");
        })
        .catch((error) => {
          console.log("Error updating document.");
        });
    });

    console.log("Submit", submitObject);
  };

  return (
    <Formik
      initialValues={{
        firstName: "",
        lastName: "",
        role: "",
        phone: phoneNumber || "",
        email: "",
        company: "",
      }}
      enableReinitialize={true}
      validateOnMount={true}
      onSubmit={async (values, { setSubmitting }) => {
        handleSubmit(values);
        console.log("clicked", values);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        /* and other goodies */
      }) => (
        <form onSubmit={handleSubmit}>
          <div className="w-full h-full flex flex-col px-4 pt-10">
            <div className="flex flex-col justify-start items-start">
              <p style={{ fontSize: 24, fontWeight: "" }}>
                Let's get you started
              </p>
              <div className="mt-2">
                <p style={{ fontSize: 16, fontWeight: "" }}>
                  Enter optional details to populate your card.
                </p>
              </div>
              <div className="pt-10 grid grid-cols-2 gap-4">
                {ONBOARDINGINPUTS.map((item, index) => (
                  <Field
                    className="block w-full h-16 rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 text-xl"
                    id={item.name}
                    name={item.name}
                    placeholder={item.placeholder}
                    type={item.type}
                    keyboardType={item.keyboardType}
                  />
                ))}
              </div>
            </div>
            <div className="pt-10 px-4">
              <div
                className="bg-black rounded-full py-3 items-center justify-center px-5 flex flex-row"
                onClick={handleSubmit}
              >
                <p className="text-xl text-white rounded-full items-center justify-center">
                  Add Details To My Hypacard {"  "}⚡
                </p>
              </div>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
}
