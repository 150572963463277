import { Formik } from "formik";
// Form validation
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { UserAuth } from "../../../Context/AuthContext";

// API USER DATA
import { doc, getDoc, onSnapshot, updateDoc } from "firebase/firestore";
import { auth, db, storage } from "../../../API/Firebase/firebase";
import { useState, useEffect } from "react";
import { nanoid } from "nanoid";

import {
  getDownloadURL,
  ref,
  uploadBytes,
  uploadString,
} from "firebase/storage";
// Random id generator
import { customAlphabet } from "nanoid";
import { StyledImageUpload } from "../../../Functions/Crop/ImageUpload";
import ProfileHeader from "../../../Components/Profile/ProfileHeader";
import { Switch } from "@headlessui/react";
import LoadingOverlay from "react-loading-overlay";
import { PulseLoader } from "react-spinners";

// Phone
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

const alphabet = "123456789ABCDEFGHJKLMNPQRSTUVWXYZ";
const customNanoId = customAlphabet(alphabet, 8);

const EditProfileSchema = Yup.object().shape({
  // title: Yup.string().min(2, "Too Short").max(50, "Too long").required(""),
});

export default function CreateNewCardIndex() {
  const [card, setCard] = useState({});
  let { id } = useParams();
  const { user } = UserAuth();
  const [orgId, setOrgId] = useState("");
  const [employeeCard, setEmployeeCard] = useState(true);
  const getUserDoc = () => {
    getDoc(doc(db, "accounts", user?.uid)).then((doc) => {
      setOrgId(doc.data().organizations[0]);
      const orgID = doc.data().organizations[0];
      // console.log("orgID", doc.data().organizations[0]);
    });
  };

  const [phoneValue, setPhoneValue] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const getCountryCode = async () => {
    const response = await fetch("https://ipapi.co/json/");
    const data = await response.json();
    setCountryCode(data.country);
    console.log(countryCode, "country code");
  };

  useEffect(() => {
    getCountryCode();
    getUserDoc();
  }, []);
  // API: User's Cards
  //   useEffect(() => {
  //     const unsubCards = onSnapshot(doc(db, "cards", id), (doc) => {
  //       console.log("Current data: ", doc.data());
  //       setCard(doc.data());
  //     });
  //     return () => {
  //       unsubCards();
  //     };
  //   }, []);

  const [imageUpload, setImageUpload] = useState(card?.profilePictureUrl || "");
  const [companyLogoUpload, setCompanyLogoUpload] = useState(
    card?.companyLogoUrl || ""
  );
  const [coverPhotoUpload, setCoverPhotoUpload] = useState(
    card?.coverPhotoUrl || ""
  );

  function updateProfilePicture(profilePictureUrl) {
    if (profilePictureUrl === "") {
      const myPromise = new Promise((resolve, reject) => {
        resolve(profilePictureUrl);
      });
      return myPromise;
    } else {
      const myPromise = new Promise((resolve, reject) => {
        const imageRef = ref(storage, "images/profile_pictures/" + nanoid());
        uploadString(imageRef, profilePictureUrl, "data_url")
          .then((snapshot) => {
            console.log("Uploaded image!");
            getDownloadURL(snapshot.ref).then((profile_picture) => {
              resolve(profile_picture);
            });
          })
          .catch((error) => {
            // console.log("Error uploading image", error);
          });
      });
      console.log("imageUploaded");
      return myPromise;
    }
  }

  function updateCompanyLogo(companyLogoUrl) {
    if (companyLogoUrl === "") {
      const myPromise = new Promise((resolve, reject) => {
        resolve(companyLogoUrl);
      });
      return myPromise;
    } else {
      const myPromise = new Promise((resolve, reject) => {
        const imageRef = ref(storage, "images/company-logos/" + nanoid());
        uploadString(imageRef, companyLogoUrl, "data_url")
          .then((snapshot) => {
            console.log("Uploaded logo image!");
            getDownloadURL(snapshot.ref).then((company_logo) => {
              resolve(company_logo);
            });
          })
          .catch((error) => {
            // console.log("Error uploading image", error);
          });
      });
      console.log("imageUploaded");
      return myPromise;
    }
  }

  function updateCoverPhoto(coverPhotoUrl) {
    if (coverPhotoUrl === "") {
      const myPromise = new Promise((resolve, reject) => {
        resolve(coverPhotoUrl);
      });
      return myPromise;
    } else {
      const myPromise = new Promise((resolve, reject) => {
        const imageRef = ref(storage, "images/cover-photos/" + nanoid());
        uploadString(imageRef, coverPhotoUrl, "data_url")
          .then((snapshot) => {
            console.log("Uploaded logo image!");
            getDownloadURL(snapshot.ref).then((cover_photo) => {
              resolve(cover_photo);
            });
          })
          .catch((error) => {
            // console.log("Error uploading image", error);
          });
      });
      console.log("imageUploaded");
      return myPromise;
    }
  }

  const navigate = useNavigate();
  const { addCardToDatabaseProfile, account } = UserAuth();
  //console.log("ACCOUNT PROFILE", account);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleSubmit = (values) => {
    // console.log(values);
    setIsSubmitting(true);

    const userId = auth.currentUser.uid;
    const card = {
      ...values,
      employeeCard: employeeCard,
      accountID: userId,
      phone: phoneValue || "",
      counters: {
        cardOpens: 0,
        contactsAdded: 0,
      },
    };

    updateCoverPhoto(coverPhotoUpload).then((cover_photo) => {
      updateProfilePicture(imageUpload).then((profile_picture) => {
        updateCompanyLogo(companyLogoUpload)
          .then((company_logo) => {
            addCardToDatabaseProfile(
              id,
              {
                ...values,
                employeeCard: employeeCard,
                activationCode: customNanoId(),
                profilePictureUrl: profile_picture ? profile_picture : "",
                companyLogoUrl: company_logo ? company_logo : "",
                coverPhotoUrl: cover_photo ? cover_photo : "",
                phone: phoneValue || "",
              },
              orgId
            );
            // console.log("card updated", values);
          })
          .then(navigate("/cards"));
      });
    });
  };

  const [coverPhotoPreview, setCoverPhotoPreview] = useState(
    card?.companyCoverPhotoUrl || card?.coverPhotoUrl || ""
  );
  const [profilePicturePreview, setProfilePicturePreview] = useState(
    card?.profilePictureUrl || ""
  );
  const [companyLogoPreview, setCompanyLogoPreview] = useState(
    card?.companyLogoUrl || ""
  );

  function setCoverPhotoFunction(image) {
    setCoverPhotoPreview(image);
    setCoverPhotoUpload(image);
  }
  function setProfilePictureFunction(image) {
    setImageUpload(image);
    setProfilePicturePreview(image);
  }
  function setLogoFunction(image) {
    setCompanyLogoPreview(image);
    setCompanyLogoUpload(image);
  }

  useEffect(() => {
    console.log("phoneValueSubmit", phoneValue);
  }, [phoneValue]);
  return (
    <>
      <LoadingOverlay
        active={isSubmitting}
        spinner={<PulseLoader color="#3B82F6" />}
        text="Creating Your Profile..."
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(255, 255, 255, 0.9)",
          }),
          content: (base) => ({
            ...base,

            color: "black",
          }),

          wrapper: {
            width: "100%",
            height: "100%",
          },
        }}
      >
        <div>
          <div className="md:grid md:grid-cols-3 md:gap-6"></div>
        </div>

        <div className="md:col-span-1">
          <div className="px-4 sm:px-0 text-start">
            <h3 className="text-4xl font-medium leading-6 text-gray-900">
              Add New Card
            </h3>
          </div>
        </div>

        <div className="hidden sm:block" aria-hidden="true">
          <div className="py-5">
            <div className="border-t border-gray-200" />
          </div>
        </div>
        <Formik
          initialValues={{
            firstName: "",
            lastName: "",
            profilePictureUrl: imageUpload,
            companyLogoUrl: companyLogoUpload,
            coverPhotoUrl: coverPhotoUpload,
            role: "",
            phone: "",
            email: "",
            note: "",
            company: "",
            companyWebsite: "",
            companyPhone: "",
            address1: "",
            city: "",
            state: "",
            zip: "",
            country: "",
          }}
          enableReinitialize
          validationSchema={EditProfileSchema}
          onSubmit={(values, { setSubmitting }) => {
            handleSubmit(values);
            // console.log("clicked");
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,

            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit}>
              <div class="fixed bottom-0 w-full md:grid md:grid-cols-1 ">
                <div className="" aria-hidden="true">
                  <div className="border-t border-gray-200" />

                  <div className="w-40 z-100 float-center px-5 py-3 mb-4 justify-center rounded-md border border-transparent bg-blue-600  px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
                    {isSubmitting ? (
                      <PulseLoader color={"white"} loading={true} size={8} />
                    ) : (
                      <button
                        type="submit"
                        disabled={""}
                        className=" text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                      >
                        Create and close
                      </button>
                    )}
                  </div>
                </div>
              </div>
              <div className="mt-10 sm:mt-0">
                <div className="md:grid md:grid-cols-3 md:gap-6">
                  <div className="md:col-span-1">
                    <div className="px-4 sm:px-0 text-left">
                      <h3 className="text-lg font-medium leading-6 text-gray-900">
                        Card Type
                      </h3>
                      <p className="mt-1 text-sm text-gray-600">
                        <p className="mt-1 text-sm text-gray-600">
                          Select <i>Employee</i> if you want to add a card for
                          an employee, and <i>Personal</i> if you want to add a
                          card for yourself.
                        </p>
                      </p>
                    </div>
                  </div>
                  <div className="mt-5 md:col-span-2 md:mt-0">
                    <div className="overflow-hidden shadow sm:rounded-md text-left">
                      <div className="bg-white px-4 py-5 sm:p-6 h-28">
                        <div className="grid grid-cols-6 gap-6">
                          <div className="flex flex-row col-span-6 sm:col-span-2 items-center">
                            <label
                              htmlFor="first-name"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Employee
                            </label>
                            <div className="ml-2" />
                            <Switch
                              checked={employeeCard}
                              onChange={setEmployeeCard}
                              className={`${
                                employeeCard ? "bg-blue-700" : "bg-gray-400"
                              }
          relative inline-flex h-[24px] w-[60px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                            >
                              <span
                                aria-hidden="true"
                                className={`${
                                  employeeCard
                                    ? "translate-x-9"
                                    : "translate-x-0"
                                }
            pointer-events-none inline-block h-[20px] w-[20px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                              />
                            </Switch>
                          </div>
                          <div className="flex flex-row col-span-6 sm:col-span-2 items-center">
                            <label
                              htmlFor="first-name"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Personal
                            </label>
                            <div className="ml-2" />
                            <Switch
                              checked={employeeCard}
                              onChange={setEmployeeCard}
                              className={`${
                                !employeeCard ? "bg-blue-700" : "bg-gray-400"
                              }
          relative inline-flex h-[24px] w-[60px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                            >
                              <span
                                aria-hidden="true"
                                className={`${
                                  !employeeCard
                                    ? "translate-x-9"
                                    : "translate-x-0"
                                }
            pointer-events-none inline-block h-[20px] w-[20px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                              />
                            </Switch>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="hidden sm:block" aria-hidden="true">
                <div className="py-5">
                  <div className="border-t border-gray-200" />
                </div>
              </div>

              <div className="mt-10 sm:mt-0">
                <div className="md:grid md:grid-cols-3 md:gap-6">
                  <div className="md:col-span-1">
                    <div className="px-4 sm:px-0 text-left">
                      <h3 className="text-lg font-medium leading-6 text-gray-900">
                        Personal Information
                      </h3>
                      <p className="mt-1 text-sm text-gray-600">
                        <p className="mt-1 text-sm text-gray-600">
                          This information will be displayed publicly when you
                          share your Hypacard. It will also appear within your
                          contact information when sharing information.
                        </p>
                      </p>
                    </div>
                  </div>
                  <div className="mt-5 md:col-span-2 md:mt-0">
                    <div className="overflow-hidden shadow sm:rounded-md text-left">
                      <div className="bg-white px-4 py-5 sm:p-6">
                        <div className="grid grid-cols-6 gap-6">
                          <div className="col-span-6 sm:col-span-6 space-y-4">
                            {imageUpload || coverPhotoPreview ? (
                              <ProfileHeader
                                card={{
                                  profilePictureUrl:
                                    profilePicturePreview || imageUpload,
                                  coverPhotoUrl:
                                    card?.companyCoverPhotoUrl ||
                                    coverPhotoPreview ||
                                    coverPhotoUpload,
                                  companyLogoUrl:
                                    companyLogoPreview || companyLogoUpload,
                                  firstName: card?.firstName,
                                  lastName: card?.lastName,
                                }}
                              />
                            ) : null}
                          </div>
                          <div className="col-span-6 sm:col-span-2">
                            <label className="block text-sm font-medium text-gray-700">
                              Cover Photo
                            </label>
                            <StyledImageUpload
                              saveImage={setCoverPhotoFunction}
                              cropShape="rect"
                              length={5}
                              height={2}
                            />
                          </div>
                          <div className="col-span-6 sm:col-span-2">
                            <label className="block text-sm font-medium text-gray-700">
                              Profile Photo
                            </label>
                            <StyledImageUpload
                              saveImage={setProfilePictureFunction}
                              cropShape="round"
                              length={1}
                              height={1}
                            />
                          </div>
                          <div className="col-span-6 sm:col-span-2">
                            <label className="block text-sm font-medium text-gray-700">
                              Company Logo
                            </label>
                            <StyledImageUpload
                              saveImage={setLogoFunction}
                              cropShape="round"
                              length={1}
                              height={1}
                            />
                          </div>

                          {/* REMOVE BELOW SECTION */}

                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="first-name"
                              className="block text-sm font-medium text-gray-700"
                            >
                              First name
                            </label>
                            <input
                              type="text"
                              name="firstName"
                              placeholder="First name"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.firstName}
                              id="first-name"
                              autoComplete="given-name"
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            />
                          </div>

                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="last-name"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Last name
                            </label>
                            <input
                              type="text"
                              name="lastName"
                              placeholder="Last Name"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.lastName}
                              id="last-name"
                              autoComplete="family-name"
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            />
                          </div>

                          <div className="col-span-6 sm:col-span-6">
                            <label
                              htmlFor="first-name"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Job title / Position
                            </label>
                            <input
                              type="text"
                              name="role"
                              placeholder="Role"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.role}
                              id="first-name"
                              autoComplete="given-name"
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            />
                          </div>

                          <div className="col-span-6 sm:col-span-3">
                            {/* <input
                              type="text"
                              name="phone"
                              placeholder="Phone"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.phone}
                              id="phone"
                              autoComplete="phone"
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            /> */}
                            <label
                              htmlFor="first-name"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Phone number*
                            </label>
                            <PhoneInput
                              defaultCountry={countryCode || "AU"}
                              placeholder=""
                              value={phoneValue}
                              international={true}
                              onChange={setPhoneValue}
                              inputProps={{
                                name: "phone",
                              }}
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            />
                            <p className="text-xs text-gray-500">
                              *Must be entered in international '+' format
                            </p>
                          </div>

                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="last-name"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Email address
                            </label>
                            <input
                              type="text"
                              name="email"
                              placeholder="your@email.com"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.email}
                              id="email"
                              autoComplete="email"
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            />
                          </div>

                          <div className="col-span-6 sm:col-span-6">
                            <label
                              htmlFor="about"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Profile note
                            </label>
                            <div className="mt-1">
                              <textarea
                                id="about"
                                name="about"
                                placeholder="Add a brief note about yourself here"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.about}
                                rows={3}
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="hidden sm:block" aria-hidden="true">
                <div className="py-5">
                  <div className="border-t border-gray-200" />
                </div>
              </div>

              <div className="mt-10 sm:mt-0">
                <div className="md:grid md:grid-cols-3 md:gap-6">
                  <div className="md:col-span-1">
                    <div className="px-4 sm:px-0 text-left">
                      <h3 className="text-lg font-medium leading-6 text-gray-900">
                        Company Information
                      </h3>
                      <p className="mt-1 text-sm text-gray-600">
                        <p className="mt-1 text-sm text-gray-600"></p>
                      </p>
                    </div>
                  </div>
                  <div className="mt-5 md:col-span-2 md:mt-0">
                    <div className="overflow-hidden shadow sm:rounded-md text-left">
                      <div className="bg-white px-4 py-5 sm:p-6">
                        <div className="grid grid-cols-6 gap-6">
                          <div className="col-span-6 sm:col-span-6">
                            <label
                              htmlFor="first-name"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Company name
                            </label>
                            <input
                              type="text"
                              name="company"
                              placeholder="Acme Corp."
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.company}
                              id="company"
                              autoComplete="given-name"
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            />
                          </div>

                          <div className="col-span-6 sm:col-span-6">
                            <div className="col-span-3 sm:col-span-2">
                              <label
                                htmlFor="company-website"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Website
                              </label>
                              <div className="mt-1 flex rounded-md shadow-sm">
                                <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-sm text-gray-500">
                                  http://
                                </span>
                                <input
                                  type="text"
                                  name="companyWebsite"
                                  placeholder="www.yourcompany.com"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.companyWebsite}
                                  id="company-website"
                                  className="block w-full flex-1 rounded-none rounded-r-md border-gray-300 focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="last-name"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Company phone number
                            </label>
                            <input
                              type="text"
                              name="companyPhone"
                              placeholder="05 4932 ..."
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.companyPhone}
                              id="last-name"
                              autoComplete="family-name"
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            />
                          </div>

                          <div className="col-span-6 sm:col-span-6">
                            <label
                              htmlFor="first-name"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Country
                            </label>
                            <input
                              type="text"
                              name="country"
                              placeholder="Australia"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.country}
                              id="first-name"
                              autoComplete="given-name"
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            />
                          </div>

                          <div className="col-span-6 sm:col-span-6">
                            <label
                              htmlFor="first-name"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Street address
                            </label>
                            <input
                              type="text"
                              name="address1"
                              placeholder=""
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.address1}
                              id="first-name"
                              autoComplete="given-name"
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            />
                          </div>

                          <div className="col-span-6 sm:col-span-2">
                            <label
                              htmlFor="first-name"
                              className="block text-sm font-medium text-gray-700"
                            >
                              City
                            </label>
                            <input
                              type="text"
                              name="city"
                              placeholder=""
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.city}
                              id="first-name"
                              autoComplete="given-name"
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            />
                          </div>
                          <div className="col-span-6 sm:col-span-2">
                            <label
                              htmlFor="first-name"
                              className="block text-sm font-medium text-gray-700"
                            >
                              State / Province
                            </label>
                            <input
                              type="text"
                              name="state"
                              placeholder=""
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.state}
                              id="first-name"
                              autoComplete="given-name"
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            />
                          </div>

                          <div className="col-span-6 sm:col-span-2">
                            <label
                              htmlFor="first-name"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Postcode
                            </label>
                            <input
                              type="text"
                              name="zip"
                              placeholder=""
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.zip}
                              id="first-name"
                              autoComplete="given-name"
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className=" pb-40" aria-hidden="true">
                <div className="py-5">
                  <div className="border-t border-gray-200" />
                </div>
              </div>

              {/* UPDATE: BUILD OUT FUNCTIONALITY FOR THIS SECTION
            <div className="mt-10 sm:mt-0 pb-60">
              <div className="md:grid md:grid-cols-3 md:gap-6 text-left">
                <div className="md:col-span-1">
                  <div className="px-4 sm:px-0">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                      Settings
                    </h3>
                    <p className="mt-1 text-sm text-gray-600">
                      Select the settings you want to enable.
                    </p>
                  </div>
                </div>
                <div className="mt-5 md:col-span-2 md:mt-0">
                  <div className="overflow-hidden shadow sm:rounded-md">
                    <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                      <fieldset>
                        <legend className="sr-only">By Email</legend>
                        <div
                          className="text-base font-medium text-gray-900"
                          aria-hidden="true"
                        >
                          Contact Exchange
                        </div>
                        <div className="mt-4 space-y-4">
                          <div className="flex items-start">
                            <div className="flex h-5 items-center">
                              <input
                                id="comments"
                                name="comments"
                                type="checkbox"
                                className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                              />
                            </div>
                            <div className="ml-3 text-sm">
                              <label
                                htmlFor="comments"
                                className="font-medium text-gray-700"
                              >
                                Tick to activate contact exchange
                              </label>
                              <p className="text-gray-500">
                                Receive contact information from the person
                                you're sharing your contact details with every
                                time you share your Hypacard.
                              </p>
                            </div>
                          </div>
                        </div>
                      </fieldset>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            </form>
          )}
        </Formik>
      </LoadingOverlay>
    </>
  );
}
