import React, { useRef } from "react";
import toast, { ToastBar, Toaster } from "react-hot-toast";
import { ButtonPrimary } from "../../../../Components/Button/Index";
import PopUpModal from "../../../../Components/Modal/PopupModal";
import ToastNotification from "../../../../Components/Notifications/Index";
import QrCodeIndex from "../../../../Components/QrCode/Index";

// QR Code download import
import { exportComponentAsJPEG } from "react-component-export-image";
import { QRCode } from "react-qrcode-logo";
import { openEmail } from "../../../../Functions/Sharing/openEmail";

function ShareProfilePopUp({ open, setOpen, card }) {
  const code = card?.cardID;
  // console.log("SHAREPROFILE", card);

  // Download QR Code button
  // Copy Link button
  const DownloadQR = () => {
    exportComponentAsJPEG(componentRef, { fileName: "myHypacardQRCode" });
  };

  const componentRef = useRef();
  // //  Render QR Code
  const ComponentToPrint = React.forwardRef((props, ref) => (
    <div ref={ref} className="w-full">
      <div className="w-full">
        <QrCodeIndex code={code} />
      </div>
    </div>
  ));

  const Print = () => {
    return (
      <div className="w-full">
        <ComponentToPrint ref={componentRef} />
      </div>
    );
  };

  // send SMS
  const sendSMS = () => {
    const message = `Hi%0D%0A%0D%0AI'm using Hypacard to share my contact details.%0D%0A%0D%0AYou can save my contact details directly into your contact list, here: https://hypa.to/${code}%0D%0A%0D%0ALook forward to connecting!`;
    window.location = `sms:?body=${message}`;
  };

  // send Email
  const sendEmail = ({ card }) => {
    window.open(
      `mailto:?subject=Let's Connect&body=Hi%0D%0A%0D%0AI'm using Hypacard to share my contact details.%0D%0A%0D%0AYou can save my contact details directly into your contact list, here: https://hypa.to/${code}%0D%0A%0D%0ALook forward to connecting!%0D%0A%0D%0ARegards%0D%0A%0D%0A${
        card.firstName ? card?.firstName : ""
      } ${card.lastName ? card?.lastName : ""}`
    );
  };

  function copyToClipboard(text) {
    var dummy = document.createElement("textarea");
    // to avoid breaking orgain page when copying more words
    // cant copy when adding below this code
    // dummy.style.display = 'none'
    document.body.appendChild(dummy);
    //Be careful if you use texarea. setAttribute('value', value), which works with "input" does not work with "textarea". – Eduard
    dummy.value = text;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
  }

  return (
    <>
      <PopUpModal open={open} setOpen={setOpen} code={code}>
        <Toaster>
          {(t) => (
            <ToastBar
              toast={t}
              icon={t.icon}
              containerStyle={{
                top: 20,
                left: 20,
                bottom: 20,
                right: 20,
              }}
              style={{
                background: "orange",
                color: "white",

                zIndex: 1,
                borderRadius: 10,
              }}
            />
          )}
        </Toaster>
        {/* QR CODE SECTION */}
        <div className="flex w-full flex-col text-left space-y-2 justify-center items-center">
          <p className="font-semibold text-xl">Share Your Card</p>
          <Print />
          <button
            onClick={() => {
              toast("Link Copied", { icon: "👍" });
              copyToClipboard("https://hypa.to/O8VVXQO25F");
            }}
            className="flex justify-center items-center mt-4 border-2 border-blue-100 rounded-xl px-2 py-1"
          >
            <p>hypa.to/</p>
            <span className="">{code} </span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="white"
              className="ml-2 w-5 h-5 text-blue-600"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16.5 8.25V6a2.25 2.25 0 00-2.25-2.25H6A2.25 2.25 0 003.75 6v8.25A2.25 2.25 0 006 16.5h2.25m8.25-8.25H18a2.25 2.25 0 012.25 2.25V18A2.25 2.25 0 0118 20.25h-7.5A2.25 2.25 0 018.25 18v-1.5m8.25-8.25h-6a2.25 2.25 0 00-2.25 2.25v6"
              />
            </svg>
          </button>

          <div className="flex justify-center items-center mt-4 space-x-2">
            <div className="grid grid-cols-2 gap-2 justify-center items-center py-4 text-center">
              <button
                onClick={() => {
                  copyToClipboard(`https://hypa.to/${code}`);
                  toast("Link Copied", { icon: "👍" });
                }}
                className="p-2 col-span-1 h-24 w-40 bg-blue-50 justify-center items-center flex flex-col space-y-2 rounded-xl border-2 border-gray-100"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-8 h-8 text-blue-500 -rotate-45"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5"
                  />
                </svg>

                <p className="font-normal text-blue-500 text-sm">Share Link</p>
              </button>
              <button
                onClick={() => {
                  toast("Downloading QR Code", { icon: "👍" });
                  DownloadQR();
                }}
                className="p-2 col-span-1 h-24 w-40 bg-blue-50 justify-center items-center flex flex-col space-y-2 rounded-xl border-2 border-gray-100"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-8 h-8 text-blue-500"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 8.25H7.5a2.25 2.25 0 00-2.25 2.25v9a2.25 2.25 0 002.25 2.25h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25H15M9 12l3 3m0 0l3-3m-3 3V2.25"
                  />
                </svg>

                <p className="font-normal text-blue-500 text-sm">
                  Download QR Code
                </p>
              </button>
              <button
                onClick={() => {
                  toast("Opening SMS", { icon: "👍" });
                  sendSMS();
                }}
                className="p-2 col-span-1 h-24 w-40 bg-blue-50 justify-center items-center flex flex-col space-y-2 rounded-xl border-2 border-gray-100"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-8 h-8 text-blue-500"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 01.865-.501 48.172 48.172 0 003.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z"
                  />
                </svg>
                <p className="font-normal text-blue-500 text-sm">
                  Share as SMS
                </p>
              </button>
              <button
                onClick={() => {
                  toast("Opening Email", { icon: "👍" });
                  sendEmail({ card, code });
                }}
                className="p-2 col-span-1 h-24 w-40 bg-blue-50 justify-center items-center flex flex-col space-y-2 rounded-xl border-2 border-gray-100"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-8 h-8 text-blue-500"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                  />
                </svg>

                <p className="font-normal text-blue-500 text-sm">
                  Share as Email
                </p>
              </button>
            </div>
          </div>
          <div className="flex justify-end mt-4 space-x-2 w-full">
            <button
              onClick={() => {
                toast("Opening Email", { icon: "👍" });
                sendEmail({ card, code });
              }}
              className="w-full p-2 py-4 bg-blue-500 justify-center items-center flex flex-col space-y-2 rounded-xl border-2 border-gray-100"
            >
              <p className="font-normal text-white text-sm">
                Quick Share (Send Email)
              </p>
            </button>
          </div>
        </div>
      </PopUpModal>
    </>
  );
}

export default ShareProfilePopUp;
