import React, { useEffect } from "react";
import { isAndroid, isIOS } from "react-device-detect";
import logo from "../../Assets/Images/hypacardLogoFull.png";
import AppLinks from "../../Pages/Download/Index";

export function AppStoreRedirect() {
  const iOSUrl = "https://apps.apple.com/app/hypacard/id1618151363";
  const androidUrl =
    "https://play.google.com/store/apps/details?id=com.hypacard.hypacard";

  useEffect(() => {
    if (isAndroid) {
      window.location.href = androidUrl;
    } else if (isIOS) {
      window.location.href = iOSUrl;
    } else {
      window.location.href = "localhost:3000/applinks";
    }
  }, []);

  return <AppLinks />;
}
