import React from "react";

function ProfileDetails({ card }) {
  return (
    <div className="mx-auto md:w-1/3 z-100 flex flex-col pt-16 justify-center items-center text-center">
      <div className="mt-2 min-w-0 flex-1  2xl:block space-y-1">
        <h1 className="text-xl font-semibold text-black">
          {card?.firstName} {card?.lastName}
        </h1>
        <h3 className="text-lg text-gray-500">
          {card?.role}
          {card?.role && card?.company ? (
            <span> at {card?.company}</span>
          ) : (
            <></>
          )}
        </h3>
        <h3 className="text-base text-gray-500">
          {card?.city?.toUpperCase()}
          {card?.city && card?.country ? (
            <span>, {card?.state?.toUpperCase()}</span>
          ) : (
            <></>
          )}
        </h3>
        {/* Description list */}
        {card?.about ? (
          <div className="w-full flex text-center justify-center items-center flex pb-1">
            <div className="flex flex-col w-full justify-center items-center text-center">
              <dd
                className="mt-4 w-full text-lg text-center text-gray-500"
                dangerouslySetInnerHTML={{ __html: card.about }}
              />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default ProfileDetails;
