/*
  This requires following config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/

import { collection, doc, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import { db } from "../../API/Firebase/firebase";
import { UserAuth } from "../../Context/AuthContext";
import ProfilePictureImage from "../Profile/ProfilePictureImage";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function CardComponent() {
  const navigate = useNavigate();
  const { userProfile } = UserAuth();
  console.log("Teams userProfile", userProfile);
  // Team Data
  const [teams, setTeams] = useState([]);
  useEffect(() => {
    // Get teams collection from firebase

    const organizationRef = collection(
      db,
      "organizations",
      userProfile.organizations[0],
      "teams"
    );

    // Get docs from teams collection
    const unsubscribe = onSnapshot(organizationRef, (querySnapshot) => {
      const teams = [];
      querySnapshot.forEach((doc) => {
        teams.push({ ...doc.data(), id: doc.id });
      });
      setTeams(teams);
      console.log("teams", teams);
    });

    return unsubscribe;
  }, []);
  // Count Active Teams
  const [activeEventCount, setActiveEventCount] = useState(0);

  // useEffect(() => {
  //   let count = 0;
  //   teams.forEach((team) => {
  //     if (team.cards.length > 0) {
  //       count++;
  //     }
  //   });
  //   setActiveEventCount(count);
  // }, []);

  return (
    <>
      <Team teams={teams} />
      <CreateEvent navigate={navigate} />
    </>
  );
}

function Team({ teams }) {
  const navigate = useNavigate();
  return (
    <ul
      role="list"
      className="grid grid-cols-1 gap-6 sm:grid-cols-1 lg:grid-cols-3"
    >
      {teams.map((team) => (
        <li
          key={team.id}
          onClick={() => {
            navigate(`/teams/${team?.id}`);
          }}
          className="
              cursor-pointer col-span-1 rounded-lg bg-transparent shadow-xl ring-4 ring-gray-100 text-left p-2 hover:ring hover:ring-b hover:ring-blue-800  hover:shadow-3xl"
        >
          <div className="flex w-full items-center justify-between space-x-6 p-2">
            <div className="grid grid-cols-6 w-full">
              <div className="col-span-5">
                <h4 className="truncate text-xl font-semibold text-gray-700">
                  {team?.title}
                </h4>
                <p className="truncate text-sm text-gray-700 pt-1">
                  {team?.description}
                </p>
              </div>

              <div className="col-span-6 py-6">
                <div className="col-span-6 flex flex-col items-start ml-2">
                  <Cards cards={team?.cards} />
                </div>
                <div className="col-span-6 flex flex-col items-start ml-2">
                  <h4 className="truncate text-md text-gray-400 pt-2">
                    {team?.cards?.length} Team Members
                  </h4>
                </div>
                <div className="pt-4 space-x-2 mb-4 flex flex-row">
                  {team.members.map((member) => (
                    <div className=" h-10 flex items-center justify-center rounded-full shadow-xl bg-white ">
                      <ProfilePictureImage
                        key={member.id}
                        card={member}
                        height="12"
                        noLogo={true}
                        noBorder={true}
                      />
                    </div>
                  ))}
                </div>
              </div>

              <div className="col-span-6 pt-2">
                <Link
                  to={`/teams/${team?.id}`}
                  className="rounded-3xl w-full justify-end flex text-start border border-transparent text-md py-1 font-medium text-blue-800 hover:text-blue-400"
                >
                  <span className="">View Team</span>
                </Link>
              </div>
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
}

function Cards({ cards }) {
  return (
    <div className="flex overflow-hidden">
      {cards?.map((card) => (
        <img
          className="inline-block h-8 w-8 rounded-full ring-2 ring-white"
          src="https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
          alt=""
        />
      ))}
    </div>
  );
}

function CreateEvent({ card, navigate }) {
  return (
    <div className="py-10">
      <button
        onClick={() => {
          navigate(`/teams/create`);
        }}
        type="button"
        className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
      >
        <svg
          className="mx-auto h-12 w-12 text-gray-400"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M13.5 16.875h3.375m0 0h3.375m-3.375 0V13.5m0 3.375v3.375M6 10.5h2.25a2.25 2.25 0 002.25-2.25V6a2.25 2.25 0 00-2.25-2.25H6A2.25 2.25 0 003.75 6v2.25A2.25 2.25 0 006 10.5zm0 9.75h2.25A2.25 2.25 0 0010.5 18v-2.25a2.25 2.25 0 00-2.25-2.25H6a2.25 2.25 0 00-2.25 2.25V18A2.25 2.25 0 006 20.25zm9.75-9.75H18a2.25 2.25 0 002.25-2.25V6A2.25 2.25 0 0018 3.75h-2.25A2.25 2.25 0 0013.5 6v2.25a2.25 2.25 0 002.25 2.25z"
          />
        </svg>

        <span className="mt-2 block text-sm font-medium text-gray-900">
          Create a new team
        </span>
      </button>
    </div>
  );
}
