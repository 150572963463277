import React from "react";
import { useNavigate } from "react-router-dom";

function TeamRating() {
  return (
    <div className="flex flex-col w-full h-20 justify-start items-start text-3xl font-semibold">
      <List />
    </div>
  );
}

export default TeamRating;

const people = [
  {
    name: "Lindsay Westoff",
    title: "Front-end Developer",
    department: "Optimization",
    connections: "12",
    role: "Sales Lead",
    image: "https://randomuser.me/api/portraits/thumb/women/30.jpg",
  },
  {
    name: "Sandra Sully",
    title: "Front-end Developer",
    department: "Optimization",
    connections: "231",
    role: "Marketing Manager",
    image: "https://randomuser.me/api/portraits/thumb/women/1.jpg",
  },
  {
    name: "Garry Walton",
    title: "Front-end Developer",
    department: "Optimization",
    connections: "122",
    role: "Business Development Executive",
    image: "https://randomuser.me/api/portraits/thumb/men/3.jpg",
  },
  // More people...
];

function List() {
  const navigate = useNavigate();

  return (
    <div className="px-4 w-full">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto text-left">
          <h1 className="text-xl font-semibold text-gray-900">
            All-Time Team Ratings
          </h1>
          <p className="mt-2 text-sm text-gray-600 font-normal">
            See your team's networking results, live.
          </p>
        </div>
        <div>
          <button
            type="button"
            onClick={() => {
              console.log("See Analytics");
              navigate("/analytics");
            }}
            className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            See Analytics
          </button>
        </div>
      </div>
      <div className="mt-4 flex flex-col text-left">
        <div className="-my-2 -mx-2 overflow-x-auto ">
          <div className=" align-middle  w-full">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-transparent">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pr-3 text-left text-sm font-semibold text-gray-900"
                    >
                      Position
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Total Connections
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {
                    // Order by connections
                    people
                      .sort((a, b) => b.connections - a.connections)
                      .map((person) => (
                        <tr key={person.connections}>
                          <td className="whitespace-nowrap py-4 pr-3 text-sm h-20">
                            <span className="inline-flex rounded-full bg-blue-100 w-10 h-10 flex justify-center items-center text-xs font-semibold leading-5 text-blue-700">
                              {people.indexOf(person) + 1}
                            </span>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <div className="flex items-center">
                              <div className="h-10 w-10 flex-shrink-0">
                                <img
                                  className="h-10 w-10 rounded-full"
                                  src={person.image}
                                  alt=""
                                />
                              </div>
                              <div className="ml-4">
                                <div className="font-medium text-gray-900">
                                  {person.name}
                                </div>
                                <div className="text-gray-500">
                                  {person.role}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="h-20 px-3 py-4 text-sm text-gray-500 text-center justify-center items-center flex0">
                            <span className="rounded-full 0 px-2 text-normal font-semibold leading-5 text-blue-700 ">
                              {person.connections}
                            </span>
                          </td>
                        </tr>
                      ))
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
