import React from "react";
import LoadingOverlay from "react-loading-overlay";
import { BarChart } from "../../Components/Charts/BarChart";
import { DoughnutChart } from "../../Components/Charts/DoughnutChart";
import TeamRating from "./TeamRating";

export default function DashboardIndex() {
  return (
    <LoadingOverlay
      active={true}
      text="We're building you a live dashboard. Coming next week..."
      // add background color to the overlay
      styles={{
        overlay: (base) => ({
          ...base,
          background: "rgba(255, 255, 255, 0.9)",
        }),
        content: (base) => ({
          ...base,

          color: "black",
        }),

        wrapper: {
          width: "100%",
          height: "100%",
        },
      }}
    >
      <div className="flex flex-col w-full h-full justify-start items-start text-3xl font-semibold mx-4">
        <h1 className="text-4xl font-medium leading-6 text-gray-900">
          Team Overview
        </h1>

        <div className="flex flex-row w-full h-full">
          <div className="flex flex-col w-1/2 h-full justify-start items-start text-3xl font-semibold ">
            <div className="flex flex-row w-full h-1/2 p-8 shadow justify-between items-start text-3xl font-semibold">
              <div className="sm:flex sm:items-center w-1/2">
                <div className="sm:flex-auto text-left">
                  <h1 className="text-xl font-semibold text-gray-900">
                    Team Networking Share
                  </h1>
                  <p className="mt-2 text-sm text-gray-600 font-normal">
                    See who's gathering the most leads for your business.
                  </p>
                </div>
              </div>
              <div className="pt-8 flex flex-col flex-1 h-full justify-center items-center text-3xl font-semibold">
                <DoughnutChart />
              </div>
            </div>
            <div className="flex flex-col w-full  justify-start items-start text-3xl font-semibold">
              <div className="border-2 rounded-md shadow border-gray-100 w-full py-4 px-4 justify-center flex-col items-start mt-4">
                <div className="sm:flex sm:items-center w-1/2">
                  <div className="sm:flex-auto text-left">
                    <h1 className="text-xl font-semibold text-gray-900">
                      Total Connection Volume
                    </h1>
                    <p className="mt-2 text-sm text-gray-600 font-normal">
                      See all your team's networking every month of the year
                    </p>
                  </div>
                </div>

                <BarChart />
              </div>
            </div>
          </div>
          <div className="flex flex-col w-1/2 h-full justify-start items-start text-3xl font-semibold px-4">
            <div className="pt-4 flex flex-col rounded-md shadow w-full h-full justify-start items-start text-3xl font-semibold border-2 border-gray-100">
              <div className="flex h-full flex-row w-full justify-start items-start text-3xl font-semibold">
                <TeamRating />
              </div>
            </div>
          </div>
        </div>
      </div>
    </LoadingOverlay>
  );
}
