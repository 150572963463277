import { ArrowRight } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import hypacardIcon from "../../Assets/Images/hypacardIcon.png";
import { saveVCard } from "../../Functions/Card/VCard";
import BelowFold from "./BelowFold";
import ProfileDetails from "./ProfileDetails";
import ProfileHeader from "./ProfileHeader";

export const ProfileCardIndex = ({ card, id, setOpen }) => {
  const navigate = useNavigate();

  return (
    <div className="relative overflow-y-auto text-left h-screen">
      <div className="relative overflow-y-auto text-left h-full p-4">
        <div className="h-52">
          <ProfileHeader card={card} />
        </div>
        <div className="w-full mt-2 flex flex-col py-4 ">
          <ProfileDetails card={card} />
        </div>
        <BelowFold card={card} setOpen={setOpen} />
      </div>
      {/* <div className="sticky bottom-0 right-0 p-4 bg-black w-full h-40 flex justify-between items-center">
        <div />
        <p className="text-white text-center">
          Exchange details with {card?.firstName}
        </p>
      </div> */}
    </div>
  );
};
