// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB3V0KGYwhGW64PcHrMcnPJVkTUOjlcY1I",
  authDomain: "hypa-27b28.firebaseapp.com",
  projectId: "hypa-27b28",
  storageBucket: "hypa-27b28.appspot.com",
  messagingSenderId: "297451645647",
  appId: "1:297451645647:web:d237c5a959006fc0b225ba",
  measurementId: "G-JCRW2F4T6T"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const functions = getFunctions();


export const db = getFirestore(app);
export const auth = getAuth(app);
export const provider = new GoogleAuthProvider();
provider.setCustomParameters({ prompt: 'select_account' });
const analytics = getAnalytics(app);



export const storage = getStorage(app);
