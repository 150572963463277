import {
  getDownloadURL,
  ref,
  uploadBytes,
  uploadString,
} from "firebase/storage";
import { nanoid } from "nanoid";
import { storage } from "../../API/Firebase/firebase";

// This function takes an image file and uploads it to firebase storage
export function ImageUploadFunction(
  dbRef,
  imageUrl,
  userImageUrl,
  storageFolderName
) {
  console.log("##ImageUploadFunction1");
  if (
    String(imageUrl) === String(userImageUrl) ||
    imageUrl === null ||
    imageUrl === undefined ||
    imageUrl === "" ||
    imageUrl === userImageUrl ||
    // String is not data64 format
    String(imageUrl)?.length < 100
  ) {
    const myPromise = new Promise((resolve, reject) => {
      console.log("Image is the same, no need to upload");
      resolve(userImageUrl);
    });
    return myPromise;
  } else {
    const myPromise = new Promise((resolve, reject) => {
      const storageRef = ref(
        storage,
        "images/" + storageFolderName + "/" + nanoid()
      );
      uploadBytes(storageRef, imageUrl)
        .then((snapshot) => {
          console.log("##Uploaded image file!");
          getDownloadURL(snapshot.ref).then((downloadURL) => {
            console.log("##File available at");
            resolve(downloadURL);
          });
        })
        .catch((error) => {
          console.log("##Error uploading image file");
          reject(error);
        });
    });
    return myPromise;
  }
}
