import React, { useState, useCallback } from "react";

import Cropper from "react-easy-crop";
import Slider from "@material-ui/core/Slider";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { getOrientation } from "get-orientation/browser";
import ImgDialog from "./ImgDialog";
import { getCroppedImg, getRotatedImage } from "./canvasUtils";
import { styles } from "./styles";
import styled from "styled-components";
import PopUpModal from "../../Components/Modal/PopupModal";

import Resizer from "react-image-file-resizer";

const ORIENTATION_TO_ANGLE = {
  3: 180,
  6: 90,
  8: -90,
};

const Button = styled.button`
  /* Insert your favorite CSS code to style a button */
  background-color: black;
  border: none;
  color: white;
  padding: 8px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  border-radius: 12px;

  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 1rem;
  color: #fff;
`;

const ImageUpload = ({ classes, saveImage, cropShape, length, height }) => {
  const [imageSrc, setImageSrc] = React.useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const blobToBase64 = (url) => {
    return new Promise(async (resolve, _) => {
      // do a request to the blob uri
      const response = await fetch(url);

      // response has a method called .blob() to get the blob file
      const blob = await response.blob();

      // instantiate a file reader
      const fileReader = new FileReader();

      // read the file
      fileReader.readAsDataURL(blob);

      fileReader.onloadend = function () {
        Resizer.imageFileResizer(
          blob,
          500,
          500,
          "JPEG",
          70,
          0,
          (url) => {
            resolve(url);
          },
          "base64",
          500,
          500
        );
      };
      // Here is the base64 string
    });
  };

  const showCroppedImage = useCallback(
    async (e) => {
      e.preventDefault();
      setOpen(false);
      try {
        const croppedImage = await getCroppedImg(
          imageSrc,
          croppedAreaPixels,
          rotation
        );
        //console.log("donee", croppedImage);
        blobToBase64(croppedImage).then((res) => {
          //  console.log("result", res);
          saveImage(res);
          setImageSrc(null);
        });
      } catch (e) {
        // console.error(e);
      }
    },
    [saveImage, imageSrc, croppedAreaPixels, rotation]
  );

  const onClose = useCallback(() => {
    setCroppedImage(null);
  }, []);

  const onFileChange = async (e) => {
    setOpen(true);
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      let imageDataUrl = await readFile(file);

      // apply rotation if needed
      const orientation = await getOrientation(file);
      const rotation = ORIENTATION_TO_ANGLE[orientation];
      if (rotation) {
        imageDataUrl = await getRotatedImage(imageDataUrl, rotation);
      }

      setImageSrc(imageDataUrl);
    }
  };

  // Create a reference to the hidden file input element
  const hiddenFileInput = React.useRef(null);

  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = (event) => {
    event.preventDefault();

    hiddenFileInput.current.click();
  };

  const [open, setOpen] = React.useState(false);

  return (
    <div>
      <>
        <Button onClick={handleClick}>Upload image</Button>
        <input
          type="file"
          ref={hiddenFileInput}
          onChange={onFileChange}
          accept="image/*"
          style={{ display: "none" }}
        />
      </>
      {open ? (
        <>
          <PopUpModal open={open} setOpen={setOpen}>
            <React.Fragment>
              <div className={classes.cropContainer}>
                <Cropper
                  image={imageSrc}
                  crop={crop}
                  cropShape={cropShape}
                  rotation={rotation}
                  zoom={zoom}
                  aspect={length / height}
                  onCropChange={setCrop}
                  onRotationChange={setRotation}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                />
              </div>
              <div className={classes.controls}>
                <div className={classes.sliderContainer}>
                  <Typography
                    variant="overline"
                    classes={{ root: classes.sliderLabel }}
                  >
                    Zoom
                  </Typography>
                  <Slider
                    value={zoom}
                    min={1}
                    max={3}
                    step={0.1}
                    aria-labelledby="Zoom"
                    classes={{ root: classes.slider }}
                    onChange={(e, zoom) => setZoom(zoom)}
                  />
                </div>
                <div className={classes.sliderContainer}>
                  <Typography
                    variant="overline"
                    classes={{ root: classes.sliderLabel }}
                  >
                    Rotation
                  </Typography>
                  <Slider
                    value={rotation}
                    min={0}
                    max={360}
                    step={1}
                    aria-labelledby="Rotation"
                    classes={{ root: classes.slider }}
                    onChange={(e, rotation) => setRotation(rotation)}
                  />
                </div>
              </div>
              <Button
                onClick={showCroppedImage}
                variant="contained"
                color="primary"
                classes={{ root: classes.cropButton }}
              >
                Save Image
              </Button>
              <ImgDialog img={croppedImage} onClose={onClose} />
            </React.Fragment>
          </PopUpModal>
        </>
      ) : null}
    </div>
  );
};

function readFile(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
}

export const StyledImageUpload = withStyles(styles)(ImageUpload);
