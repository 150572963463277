/*
  This requires following config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import {
  CheckCircleIcon,
  EnvelopeIcon,
  PhoneIcon,
  PlusIcon,
  XCircleIcon,
} from "@heroicons/react/20/solid";
import { useEffect, useState } from "react";
import MediaQuery from "react-responsive";
// Animation on card load
import { motion } from "framer-motion";
// API USER DATA

import { UserAuth } from "../../../Context/AuthContext";
import { useNavigate } from "react-router-dom";
import { ButtonPrimary } from "../../../Components/Button/Index";

import QrCodeIndex from "../../../Components/QrCode/Index";
import PopUpCardActivation from "./PopUpModals/CardActivationPopUp";
import ShareProfilePopUp from "./PopUpModals/ShareProfilePopUp";

import ProfileHeader from "../../../Components/Profile/ProfileHeader";
import HypaSimpleLoadingAnimation from "../../../Functions/Loading/Index";

import ProfilePictureImage from "../../../Components/Profile/ProfilePictureImage";
import { ShareWithCardHolder } from "./PopUpModals/ShareWithCardHolder";
import LiveDotAnimation from "../../../Functions/Animations/LiveDot";

export default function CardsIndex() {
  // Modal: Activate Card
  const [openActivateModal, setOpenActivateModal] = useState(false);

  const { user, getCards, cards } = UserAuth();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getCards();
  }, []);

  return (
    <div className="">
      <>
        <div>
          <div className="md:grid md:grid-cols-3 md:gap-6"></div>
        </div>

        <div className="block" aria-hidden="true"></div>

        {isLoading ? (
          <div className="bg-white">
            <HypaSimpleLoadingAnimation />
          </div>
        ) : (
          <motion.div
            initial={{ opacity: 0, scale: 1 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.4 }}
          >
            <MediaQuery maxWidth={800}>
              <QRCard cards={cards} />
            </MediaQuery>
            <MediaQuery minWidth={800}>
              <People cards={cards} />
            </MediaQuery>
            {user?.role === 100 ? (
              <AddCard />
            ) : (
              <div className="py-20 block"></div>
            )}
          </motion.div>
        )}

        <PopUpCardActivation
          open={openActivateModal}
          setOpen={setOpenActivateModal}
        />
      </>
    </div>
  );
}

/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/

function QRCard({ cards }) {
  const navigate = useNavigate();
  const [qrModalOpen, setQrModalOpen] = useState(false);
  const [code, setCode] = useState("");

  const [cardData, setCardData] = useState(null);

  const [cardHolderModalIsOpen, setCardHolderModalIsOpen] = useState(false);

  const handleShareModal = (card) => {
    setCardData(card);
    setQrModalOpen(true);
  };

  return (
    <MediaQuery maxWidth={800}>
      <ul>
        {cards?.map((card) => (
          <div className="pb-20">
            <li
              key={card?.uid}
              className="col-span-1 rounded-lg bg-transparent shadow-xl border-b border-gray-100 text-left p-4 "
            >
              <div className="flex justify-start flex-row">
                <ProfilePictureImage card={card} height={14} logoHeight={0} />
                <div className="flex justify-start flex-col">
                  <p className="font-bold ml-2">
                    {card?.firstName} {card?.lastName}
                  </p>
                  {card?.role && card?.company ? (
                    <p className="font-bold ml-2">
                      {card?.role} {card?.company}
                    </p>
                  ) : null}
                </div>
              </div>
              <QrCodeIndex code={card.cardID} />
              <ShareButton
                card={card}
                setQrModalOpen={setQrModalOpen}
                setCode={setCode}
                handleMobileModal={() => handleShareModal(card)}
              />
            </li>
          </div>
        ))}
        <ShareProfilePopUp
          open={qrModalOpen}
          setOpen={setQrModalOpen}
          card={cardData}
        />
      </ul>
    </MediaQuery>
  );
}

function People({ cards }) {
  const navigate = useNavigate();
  const [qrModalOpen, setQrModalOpen] = useState(false);
  const [code, setCode] = useState("");
  const [cardData, setCardData] = useState(null);
  const { user } = UserAuth();

  const [cardHolderModalIsOpen, setCardHolderModalIsOpen] = useState(false);

  const handleShareModal = (card) => {
    setCardData(card);
    setQrModalOpen(true);
  };

  const handleCardHolderShareModal = (card) => {
    setCardData(card);
    setCardHolderModalIsOpen(true);
  };

  return (
    <ul className="grid grid-cols-1 gap-6 sm:grid-cols-1 lg:grid-cols-3 pt-4">
      {cards?.map((card) => {
        // console.log("card", card);
        return (
          <li
            key={card?.uid}
            className="col-span-1 rounded-2xl bg-transparent shadow-xl border-b border-gray-100 text-left p-4  hover:ring hover:ring-b hover:ring-blue-800 hover:ring-opacity-50 hover:shadow-3xl"
          >
            <div className="">
              <ProfileHeader
                card={card}
                qrModalOpen={qrModalOpen}
                setQrModalOpen={setQrModalOpen}
                setCode={setCode}
              />
            </div>

            <CardInfo card={card} />

            <ShareButton
              card={card}
              handleMobileModal={() => handleShareModal(card)}
            />

            {user?.role === 100 && card?.limitedAccessUser === undefined ? (
              <>
                <div className="flex justify-center items-center w-full pt-4">
                  <ButtonPrimary
                    title={"Activate personal card access for team member"}
                    color={"white"}
                    textColor={"black"}
                    handleClick={() => handleCardHolderShareModal(card)}
                  />
                </div>
              </>
            ) : user?.role === 100 && card?.limitedAccessUser ? (
              <div className="flex justify-center items-center w-full pt-4 flex-row">
                <LiveDotAnimation />

                <p>
                  <span className="text-blue-800 font-bold">Active</span>
                </p>
              </div>
            ) : null}
            <div className="flex justify-center items-center w-full pt-4 flex-wrap flex-col pt-2">
              <div className="flex justify-center items-center w-full flex-wrap flex-row">
                {card?.teams?.map((team) => (
                  <div
                    onClick={() => navigate(`/teams/${team?.id}`)}
                    className="bg-white border-2 border-gray-100 mx-1 shadow h-8 rounded-full my-2 px-4 w-32 flex flex-row items-center justify-between w-full 
 cursor-pointer hover:bg-blue-700 hover:text-white hover:shadow-2xl text-gray-800 hover:shadow-xl"
                  >
                    <p className="text-center text-sm truncate text-center w-full ">
                      {team?.title}
                    </p>
                  </div>
                ))}

                <div
                  onClick={() => {
                    navigate(`/teams/`);
                  }}
                  className="opacity-50 hover:opacity-100 bg-white border-2 border-gray-200 mx-2 shadow h-8 rounded-full my-2 text-sm px-4 flex flex-row items-center justify-between 
 cursor-pointer hover:bg-blue-700 hover:text-white hover:shadow-2xl text-gray-600
                      
                      "
                >
                  {card?.teams?.length > 0 ? (
                    <p>Add to another team</p>
                  ) : (
                    <p>Add to a team</p>
                  )}
                  <PlusIcon className="h-5 w-5 " aria-hidden="true" />
                </div>
              </div>
            </div>
          </li>
        );
      })}
      <ShareWithCardHolder
        open={cardHolderModalIsOpen}
        setOpen={setCardHolderModalIsOpen}
        card={cardData}
      />
      <ShareProfilePopUp
        open={qrModalOpen}
        setOpen={setQrModalOpen}
        card={cardData}
      />
    </ul>
  );
}

function CardInfo({ card }) {
  return (
    <div className="w-full flex flex-col justify-start items-center text-center min-h-16 pt-2">
      <h1 className="text-xl font-semibold text-black">
        {card?.firstName} {card?.lastName}
      </h1>
      <h3 className="text-lg text-gray-500 pt-2">
        {card?.role}
        {card?.role && card?.company ? <span> at {card?.company}</span> : <></>}
      </h3>
    </div>
  );
}

export function ShareButton({ card, handleMobileModal }) {
  const navigate = useNavigate();

  return (
    <div className="grid grid-cols-3 py-4">
      <button
        onClick={() => {
          navigate(`/cards/${card?.cardID}/edit`, {
            state: { id: card?.cardID, accountID: card?.accountID },
          });
        }}
        className="col-span-1 justify-center items-center flex"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-5 h-5 mr-1"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
          />
        </svg>

        <p>Edit</p>
      </button>
      <a
        target="_blank"
        rel="noreferrer"
        href={`https://hypa.to/` + card?.cardID}
        className="col-span-1 justify-center items-center flex"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-5 h-5 mr-1"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
          />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
          />
        </svg>

        <p>View</p>
      </a>
      <button
        onClick={(e) => {
          e.preventDefault();
          handleMobileModal(card);
        }}
        className="col-span-1 justify-center items-center flex"
      >
        <div>
          {/* QR Code */}
          <div className="relative z-140 ">
            <div className="relative flex-row truncate items-between w-full justify-between">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-5 h-5 mr-1"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3.75 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 013.75 9.375v-4.5zM3.75 14.625c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5a1.125 1.125 0 01-1.125-1.125v-4.5zM13.5 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 0113.5 9.375v-4.5z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6.75 6.75h.75v.75h-.75v-.75zM6.75 16.5h.75v.75h-.75v-.75zM16.5 6.75h.75v.75h-.75v-.75zM13.5 13.5h.75v.75h-.75v-.75zM13.5 19.5h.75v.75h-.75v-.75zM19.5 13.5h.75v.75h-.75v-.75zM19.5 19.5h.75v.75h-.75v-.75zM16.5 16.5h.75v.75h-.75v-.75z"
                />
              </svg>
            </div>
          </div>
        </div>
        <p>Share</p>
      </button>
    </div>
  );
}
function AddCard({ card }) {
  const navigate = useNavigate();

  return (
    <div className="py-10 block">
      <button
        onClick={() => {
          navigate(`/cards/new`);
        }}
        type="button"
        className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
      >
        <svg
          className="mx-auto h-12 w-12 text-gray-400"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M13.5 16.875h3.375m0 0h3.375m-3.375 0V13.5m0 3.375v3.375M6 10.5h2.25a2.25 2.25 0 002.25-2.25V6a2.25 2.25 0 00-2.25-2.25H6A2.25 2.25 0 003.75 6v2.25A2.25 2.25 0 006 10.5zm0 9.75h2.25A2.25 2.25 0 0010.5 18v-2.25a2.25 2.25 0 00-2.25-2.25H6a2.25 2.25 0 00-2.25 2.25V18A2.25 2.25 0 006 20.25zm9.75-9.75H18a2.25 2.25 0 002.25-2.25V6A2.25 2.25 0 0018 3.75h-2.25A2.25 2.25 0 0013.5 6v2.25a2.25 2.25 0 002.25 2.25z"
          />
        </svg>

        <span className="mt-2 block text-sm font-medium text-gray-900">
          Create a new card
        </span>
      </button>
    </div>
  );
}
