import { Field, FieldArray, Formik } from "formik";
// Form validation
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { UserAuth } from "../../../Context/AuthContext";

// API USER DATA
import {
  collection,
  doc,
  getDocs,
  onSnapshot,
  query,
  updateDoc,
} from "firebase/firestore";
import { auth, db, storage } from "../../../API/Firebase/firebase";
import { useState, useEffect } from "react";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { nanoid } from "nanoid";
import ProfileHeader from "../../../Components/Profile/ProfileHeader";
import ShareProfilePopUp from "../../Profile/ProfileLandingPage/PopUpModals/ShareProfilePopUp";
import { PulseLoader } from "react-spinners";
import LoadingOverlay from "react-loading-overlay";

const EditProfileSchema = Yup.object().shape({
  // title: Yup.string().min(2, "Too Short").max(50, "Too long").required(""),
});

export default function EditProfileBasicIndex({
  coverPhotoPreview,
  coverPhotoUpload,

  companyLogoUpload,
  companyLogoPreview,
}) {
  function TextFieldLinks({
    label,
    name,
    value,
    icon,
    onChangeText,
    onBlur,
    remove,
    index,
    ...props
  }) {
    return (
      <div className="flex w-full">
        <div className="col-span-6 sm:col-span-2">
          <label
            htmlFor="first-name"
            className="block text-sm font-medium text-gray-700"
          >
            {label}
          </label>
          <Field
            type="text"
            name={name}
            placeholder={label}
            onChangeText={onChangeText}
            onBlur={onBlur}
            value={value}
            autoCapitalize="none"
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
          />
        </div>
      </div>
    );
  }

  const [card, setCard] = useState(null);
  const [profilePicturePreview, setProfilePicturePreview] = useState(
    card?.profilePictureUrl || ""
  );

  const [dataPulled, setDataPulled] = useState(false);
  if (dataPulled === false) {
    const getAccountCard = async () => {
      const user = await auth.currentUser;
      // console.log("userID", user.uid);
      const accountCardCol = collection(db, "accounts", user.uid, "cards");
      const accountCardQuery = query(accountCardCol);
      const accountCardSnapshot = await getDocs(accountCardQuery);
      const cardData = [];
      const accountCardData = accountCardSnapshot.docs.map((doc) => {
        cardData.push(doc.data());
      });
      setCard(cardData[0]);
    };
    getAccountCard();
    setDataPulled(true);
  }

  const [imageUpload, setImageUpload] = useState(card?.profilePictureUrl || "");

  useEffect(() => {
    // console.log("card", card);
    setImageUpload(card?.profilePictureUrl || "");
  }, [card]);
  function updateProfilePicture(cardRef, profilePictureUrl) {
    if (profilePictureUrl === card?.profilePictureUrl) {
      const myPromise = new Promise((resolve, reject) => {
        resolve(profilePictureUrl);
      });
      return myPromise;
    } else {
      const myPromise = new Promise((resolve, reject) => {
        const imageRef = ref(storage, "images/profile_pictures/" + nanoid());
        uploadBytes(imageRef, profilePictureUrl)
          .then((snapshot) => {
            console.log("Uploaded image!");
            getDownloadURL(snapshot.ref).then((profile_picture) => {
              updateDoc(cardRef, { profilePictureUrl: profile_picture });
              resolve(profile_picture);
            });
          })
          .catch((error) => {
            // console.log("Error uploading image", error);
          });
      });
      console.log("imageUploaded");
      return myPromise;
    }
  }
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const { updateCard } = UserAuth();
  const handleSubmit = (values) => {
    setIsSubmitting(true);
    // console.log(values);
    const cardRef = doc(db, "cards", card.cardID);

    updateProfilePicture(cardRef, values.profilePictureUrl).then(
      (profile_picture) => {
        updateCard(
          card.cardID,
          {
            ...card,
            ...values,
            profilePictureUrl: profile_picture ? profile_picture : "",
          },
          card.organization
        );

        // console.log("card updated", values);
        navigate("/cards");
      }
    );
  };

  return (
    <div className="scroll-y-auto">
      <div>
        <div className="md:grid md:grid-cols-3 md:gap-6"></div>
      </div>

      <div className="md:col-span-1">
        <div className="px-4 sm:px-0 text-start">
          <h3 className="text-4xl font-medium leading-6 text-gray-900">
            Edit Profile
          </h3>
        </div>
      </div>

      <div className="hidden sm:block" aria-hidden="true">
        <div className="py-5">
          <div className="border-t border-gray-200" />
        </div>
      </div>
      <LoadingOverlay
        active={isSubmitting}
        spinner={<PulseLoader color="#3B82F6" />}
        text="Updating Profile..."
      >
        <Formik
          initialValues={{
            firstName: card?.firstName || "",
            lastName: card?.lastName || "",
            profilePictureUrl: imageUpload,
            role: card?.role || "",
            phone: card?.phone || "",
            email: card?.email || "",
            about: card?.about || "",
            note: card?.note || "",
            company: card?.company || "",
            companyWebsite: card?.companyWebsite || "",
            companyPhone: card?.companyPhone || "",
            address1: card?.companyAddress || card?.address1 || "",
            city: card?.companyCity || card?.city || "",
            state: card?.companyState || card?.state || "",
            zip: card?.companyZip || card?.zip || "",
            country: card?.companyCountry || card?.country || "",
            contactExchangeActive: card?.contactExchangeActive || false,
            links: card?.links || [],
          }}
          enableReinitialize={true}
          validateOnMount={true}
          validationSchema={EditProfileSchema}
          onSubmit={async (values, { setSubmitting }) => {
            console.log("Clicked. Values:", values);
            // navigate("/app");
            handleSubmit(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,

            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit}>
              <div class="fixed bottom-14 w-full bg-white">
                <div className="" aria-hidden="true">
                  <div className="border-t border-gray-200" />

                  <div className="bg-white px-4 py-3 text-left sm:px-6">
                    {isSubmitting ? (
                      <PulseLoader color={"white"} loading={true} size={8} />
                    ) : (
                      <button
                        type="submit"
                        disabled={""}
                        className=" text-sm font-medium text-white shadow-sm hover:bg-blue-700  "
                      >
                        Save and close
                      </button>
                    )}
                  </div>
                </div>
              </div>

              <div className="mt-10 sm:mt-0">
                <div className="md:grid md:grid-cols-3 md:gap-6">
                  <div className="md:col-span-1">
                    <div className="px-4 sm:px-0 text-left">
                      <h3 className="text-lg font-medium leading-6 text-gray-900">
                        Personal Information
                      </h3>
                      <p className="mt-1 text-sm text-gray-600">
                        <p className="mt-1 text-sm text-gray-600">
                          This information will be displayed publicly when you
                          share your Hypacard?. It will also appear within your
                          contact information when sharing information.
                        </p>
                      </p>
                    </div>
                  </div>
                  <div className="mt-5 md:col-span-2 md:mt-0">
                    <div className="overflow-hidden shadow sm:rounded-md text-left">
                      <div className="bg-white px-4 py-5 sm:p-6">
                        <div className="grid grid-cols-6 gap-6">
                          <div className="col-span-6 sm:col-span-6">
                            <ProfileHeader
                              card={{
                                profilePictureUrl:
                                  profilePicturePreview || imageUpload,
                                coverPhotoUrl:
                                  card?.companyCoverPhotoUrl ||
                                  coverPhotoPreview ||
                                  coverPhotoUpload,
                                companyLogoUrl:
                                  companyLogoPreview || companyLogoUpload,
                                firstName: card?.firstName,
                                lastName: card?.lastName,
                              }}
                            />
                          </div>

                          <div className="col-span-6 sm:col-span-2">
                            <label className="block text-sm font-medium text-gray-700">
                              Profile Photo
                            </label>
                            <div className="mt-1 flex items-center">
                              <input
                                onChange={(e) => {
                                  setImageUpload(e.target.files[0]);
                                  setProfilePicturePreview(
                                    URL.createObjectURL(e.target.files[0])
                                  );
                                }}
                                name="profilePictureUrl"
                                type="file"
                                title="Upload a photo"
                                className=" w-full rounded-md border border-gray-300 bg-white py-2 px-3 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                              />
                            </div>
                          </div>

                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="first-name"
                              className="block text-sm font-medium text-gray-700"
                            >
                              First name
                            </label>
                            <input
                              type="text"
                              name="firstName"
                              placeholder="First name"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.firstName}
                              id="first-name"
                              autoComplete="given-name"
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            />
                          </div>

                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="last-name"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Last name
                            </label>
                            <input
                              type="text"
                              name="lastName"
                              placeholder="Last Name"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.lastName}
                              id="last-name"
                              autoComplete="family-name"
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            />
                          </div>

                          <div className="col-span-6 sm:col-span-6">
                            <label
                              htmlFor="first-name"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Job title / Position
                            </label>
                            <input
                              type="text"
                              name="role"
                              placeholder="Role"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.role}
                              id="first-name"
                              autoComplete="given-name"
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            />
                          </div>

                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="first-name"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Phone number
                            </label>
                            <input
                              type="text"
                              name="phone"
                              placeholder="Phone"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.phone}
                              id="phone"
                              autoComplete="phone"
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            />
                          </div>

                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="last-name"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Email address
                            </label>
                            <input
                              type="text"
                              name="email"
                              placeholder="your@email.com"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.email}
                              id="email"
                              autoComplete="email"
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            />
                          </div>

                          <div className="col-span-6 sm:col-span-6">
                            <label
                              htmlFor="about"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Profile note
                            </label>
                            <div className="mt-1">
                              <textarea
                                id="about"
                                name="about"
                                placeholder="Add a brief note about yourself here"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.about}
                                rows={3}
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="hidden sm:block" aria-hidden="true">
                <div className="py-5">
                  <div className="border-t border-gray-200" />
                </div>
              </div>

              <div className="hidden sm:block" aria-hidden="true">
                <div className="py-5">
                  <div className="border-t border-gray-200" />
                </div>
              </div>

              <div className="mt-10 sm:mt-0 pb-60">
                <div className="md:grid md:grid-cols-3 md:gap-6 text-left">
                  <div className="md:col-span-1">
                    <div className="px-4 sm:px-0">
                      <h3 className="text-lg font-medium leading-6 text-gray-900">
                        Settings
                      </h3>
                      <p className="mt-1 text-sm text-gray-600">
                        Select the settings you want to enable.
                      </p>
                    </div>
                  </div>
                  <div className="mt-5 md:col-span-2 md:mt-0">
                    <div className="overflow-hidden shadow sm:rounded-md">
                      <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                        <fieldset>
                          <legend className="sr-only">By Email</legend>
                          <div
                            className="text-base font-medium text-gray-900"
                            aria-hidden="true"
                          >
                            Contact Exchange
                          </div>
                          <div className="mt-4 space-y-4">
                            <div className="flex items-start">
                              <div className="flex h-5 items-center">
                                <input
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  checked={values.contactExchangeActive}
                                  id="contactExchangeActive"
                                  name="contactExchangeActive"
                                  type="checkbox"
                                  className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                                />
                              </div>
                              <div className="ml-3 text-sm">
                                <label
                                  htmlFor="comments"
                                  className="font-medium text-gray-700"
                                >
                                  Tick to activate contact exchange
                                </label>
                                <p className="text-gray-500">
                                  Include an automated popup to exchange contact
                                  information with the person you're sharing
                                  your contact details when you share your
                                  Hypacard?.
                                </p>
                              </div>
                            </div>
                          </div>
                        </fieldset>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </LoadingOverlay>
    </div>
  );
}
