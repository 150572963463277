import React from "react";
import { useNavigate } from "react-router-dom";
import { CreateTeamInputForm } from "./CreateTeamInputForm";

function CreateTeamPage() {
  const navigate = useNavigate();
  return (
    <div>
      <div className="sm:flex sm:items-center text-left pb-10">
        <div className="sm:flex-auto">
          <h1 className="text-4xl font-medium leading-6 text-gray-900">
            Create a team
          </h1>
          <p className="mt-2 text-normal text-gray-700">
            Create a team to track and manage your team's networking activity.
          </p>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none"></div>
      </div>
      <CreateTeamInputForm />
    </div>
  );
}

export default CreateTeamPage;
