import React from "react";
import { ButtonPrimary } from "../../../../Components/Button/Index";
import { CustomInputTextField } from "../../../../Components/Input/Index";
import PopUpModal from "../../../../Components/Modal/PopupModal";

function PopUpCardActivation({ open, setOpen }) {
  return (
    <PopUpModal open={open} setOpen={setOpen}>
      <div className="flex w-full flex-col text-left space-y-2">
        <p className="font-semibold text-xl">Add cards</p>
        <p>Enter the activation code found on your card here</p>
        <CustomInputTextField
          label={"Card Activation Code"}
          name={"activate"}
        />
        <div className="flex justify-end mt-4 space-x-2">
          <ButtonPrimary title={"Cancel"} handleClick={() => setOpen(false)} />
          <ButtonPrimary
            title={"Add Card"}
            color={"blue"}
            textColor={"white"}
          />
        </div>
      </div>
    </PopUpModal>
  );
}

export default PopUpCardActivation;
