import React from 'react'
import VCard from 'vcard-creator';
import FileSaver, { saveAs } from 'file-saver';
// import ResizeImage from 'react-resize-image'
import Resizer from "react-image-file-resizer";
//import ImgToBase64 from 'react-native-image-base64';
// import { Base64 } from 'js-base64';
// import imageToBase64 from 'image-to-base64/browser'



// const resizeFile = (file) =>
//   new Promise((resolve) => {
//     Resizer.imageFileResizer(
//       file,
//       300,
//       300,
//       "JPEG",
//       100,
//       0,
//       (uri) => {
//         resolve(uri);
//       },
//       "base64"
//     );
//   });


// const base64Custom = async (imageFile) => {
//   // resize image first
//   const response = await resizeFile(imageFile);
//   // get base64 string
//   const base64Image =  ImgToBase64.getBase64String(response)
//   return base64Image;

// }

export async function base64File(
      src
    ) {
      const data = await fetch(src);
      const blob = await data.blob();
    
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      Resizer.imageFileResizer(
        blob,
        240,
        240,
      
        "JPEG",
        50,
        0,
        (uri) => {
          toDataURL(uri).then(base64Image => {
            resolve(base64Image)
            return resolve(base64Image);
          }
          )
          
        },
        "base64",
        100,
        100
      );
    };
  });
}



export function toDataURL(src, callback, outputFormat) {
  
  const myPromise = new Promise((resolve, reject) => {
    var img = new Image();
    img.crossOrigin = 'Anonymous';
    img.onload = function() {
      var canvas = document.createElement('CANVAS');
      var ctx = canvas.getContext('2d');
      var dataURL;
      canvas.height = this.naturalHeight;
      canvas.width = this.naturalWidth;;
      ctx.drawImage(this, 0, 0);
      dataURL = canvas.toDataURL(outputFormat).split(`data:image/png;base64,`)[1];
      resolve(dataURL);
    };
    // console.log('img', img)
    img.src = src;
    if (img.complete || img.complete === undefined) {
      img.src = "data:image/jpeg;base64";
      img.src = src;
    }
  });
  return myPromise

  // const myPromise = new Promise((resolve, reject) => {
  //   base64Custom(src).then(base64Image => {
  //     resolve(base64Image)
  //   }
  //   )
  // })
  // return myPromise
}

function createVCard(user, imageBase64Url) {
  const yourDate = new Date()
  const additional = ''
  const prefix = ''
  const suffix = ''
  const myVCard = new VCard();
  myVCard
    // Add personal data
    .addName(user?.lastName, user?.firstName, additional, prefix, suffix)
    // Add work data
    .addCompany(user?.company || '')
    .addRole(user?.role || '')
    .addJobtitle(user?.role || '')
    .addAddress(user?.companyAddress || user?.address1, user?.companyCity || user?.city, user?.companyState || user?.state, user?.companyZip || user?.zip, user?.companyCountry || user?.country)
    .addURL(user?.companyWebsite || user?.website || '')
    .addPhoto(imageBase64Url || '')


   
  // console.log('VCARD', myVCard)
   if (user?.about === undefined) {
    myVCard.addNote(` Met on ${yourDate.toISOString().split('T')[0]} with Hypacard. You can create your own digital business card at https://hypacard.com/`)
  }
  if (user?.about !== undefined ) {
    myVCard.addNote(user?.about + ` Met on ${yourDate.toISOString().split('T')[0]} with Hypacard. You can create your own digital business card at https://hypacard.com/`)
  }

  if (user?.companyPhone) {
    myVCard.addPhoneNumber(user?.companyPhone || '', 'WORK')
  }
  if (user?.phone) {
    myVCard.addPhoneNumber(user?.phone || '', 'PERSONAL')
  }
  if (user?.companyEmail) {
    myVCard.addEmail(user?.companyEmail || '', 'WORK')
  }
    if (user?.email) {
    myVCard.addEmail(user?.email || '', 'PERSONAL')
  }
  return myVCard
  //     console.log('myVCARD', myVCard.toString())
  // console.log('RESULT:', dataUrl)
}

function getVCard(user) {

  //  img.src = "data:image/jpeg;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==";

  // console.log('IMAGE', img)
  const myPromise = new Promise((resolve, reject) => { 
    // const promise = base64Custom(user?.profilePictureUrl ||'https://firebasestorage.googleapis.com/v0/b/hypa-27b28.appspot.com/o/assets%2FhypacardIcon.png?alt=media&token=76590290-74ed-49c4-86aa-dae9f5ff20f5')
    
   const promise = base64File(user?.profilePictureUrl ||'https://firebasestorage.googleapis.com/v0/b/hypa-27b28.appspot.com/o/assets%2FhypacardIcon.png?alt=media&token=77f8e306-4814-4651-9e51-510bdd43b4ab')
    
    promise.then(
      
      imageBase64Url => {
      const myVCard = createVCard(user, imageBase64Url)
      resolve(myVCard)
    })
  })
  return myPromise
}

function saveVCard(user) {
  // console.log('USER', user)
  const myVCardPromise = getVCard(user)
  myVCardPromise.then(myVCard => {
    const obj = myVCard.toString()
    const blob = new Blob([obj], {type: "text/vcard; charset=utf-8"})
    FileSaver.saveAs(blob, `HypacardContact.vcf`);
  })
}

export {getVCard, saveVCard}