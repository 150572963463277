import { collection, getDocs, query } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth, db } from "../../../API/Firebase/firebase";
import ProfilePictureImage from "../../../Components/Profile/ProfilePictureImage";
import QrCodeIndex from "../../../Components/QrCode/Index";
import { UserAuth } from "../../../Context/AuthContext";
import { ShareButton } from "../../Profile/ProfileLandingPage/Index";
import ShareProfilePopUp from "../../Profile/ProfileLandingPage/PopUpModals/ShareProfilePopUp";
import BasicMenu from "./Layout/Menu";

export function BasicAppIndex() {
  const { user, authLoading } = UserAuth();
  const [cards, setCards] = useState([]);
  // Modal: Activate Card
  const [openActivateModal, setOpenActivateModal] = useState(false);
  const [dataPulled, setDataPulled] = useState(false);
  // useEffect(() => {
  //   getUser().then((funcCardIDs) => getCards(funcCardIDs));
  // }, [dataToggle]);
  const [isLoading, setIsLoading] = useState(false);

  if (dataPulled === false) {
    const getAccountCards = async () => {
      // console.log("userID", user.uid);
      const accountCardCol = collection(db, "accounts", user?.uid, "cards");
      const accountCardQuery = query(accountCardCol);
      const accountCardSnapshot = await getDocs(accountCardQuery);
      const cardData = [];
      const accountCardData = accountCardSnapshot.docs.map((doc) => {
        cardData.push(doc.data());
      });
      setCards(cardData);
    };
    getAccountCards().then(() => setDataPulled(true));
  }
  const [shareModalIsOpen, setShareModalIsOpen] = useState(false);
  return (
    <div className="grid grid-col-1 flex h-screen -mt-8">
      <ShareProfilePopUp
        open={shareModalIsOpen}
        setOpen={setShareModalIsOpen}
        code={cards[0]?.cardID}
        cardDetails={cards[0]}
      />
      <div className="flex justify-end items-center w-full px-4 col-span-1 h-14 pt-8">
        <BasicMenu card={cards[0]} />
      </div>
      <div className="flex justify-center items-center w-full col-span-1 space-y-10 h-full overflow-x-hidden ">
        <ul class="flex overflow-auto gap-6 w-full py-2">
          {cards.map((card) => (
            <li className="pb-4 w-full min-w-screen mx-auto px-10 flex justify-center w-full items-center py-2 flex-col">
              <div className="flex justify-center w-full items-center py-4">
                <div className="flex flex-col justify-center w-full items-center">
                  {card.companyLogoUrl && (
                    <img
                      className="rounded-full"
                      src={card?.companyLogoUrl}
                      alt="profile"
                      width="100"
                      height="100"
                    />
                  )}
                  <p>
                    {card.firstName} {card.lastName}
                  </p>
                </div>
              </div>
              <div className="bg-white p-4 rounded-2xl shadow-xl justify-center flex items-center">
                <QrCodeIndex code={card.cardID} size={250} />
              </div>
              <ShareButtonBasic
                card={cards[0]}
                shareModalIsOpen={shareModalIsOpen}
                setShareModalIsOpen={setShareModalIsOpen}
              />
            </li>
          ))}
        </ul>
      </div>
      <div className="col-span-1 h-20"></div>
    </div>
  );
}

export function ShareButtonBasic({ card, setShareModalIsOpen }) {
  return (
    <div className="grid grid-cols-2 py-4 w-full">
      <a
        target="_blank"
        rel="noreferrer"
        href={`https://hypa.to/` + card?.cardID}
        className="col-span-1 justify-center items-center flex"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-5 h-5 mr-1"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
          />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
          />
        </svg>

        <p>View</p>
      </a>
      <button
        onClick={() => {
          setShareModalIsOpen(true);
        }}
        className="col-span-1 justify-center items-center flex"
      >
        <div>
          {/* QR Code */}
          <div className="relative z-140 ">
            <div className="relative flex-row truncate items-between w-full justify-between">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-5 h-5 mr-1"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3.75 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 013.75 9.375v-4.5zM3.75 14.625c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5a1.125 1.125 0 01-1.125-1.125v-4.5zM13.5 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 0113.5 9.375v-4.5z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6.75 6.75h.75v.75h-.75v-.75zM6.75 16.5h.75v.75h-.75v-.75zM16.5 6.75h.75v.75h-.75v-.75zM13.5 13.5h.75v.75h-.75v-.75zM13.5 19.5h.75v.75h-.75v-.75zM19.5 13.5h.75v.75h-.75v-.75zM19.5 19.5h.75v.75h-.75v-.75zM16.5 16.5h.75v.75h-.75v-.75z"
                />
              </svg>
            </div>
          </div>
        </div>
        <p>Share</p>
      </button>
    </div>
  );
}
