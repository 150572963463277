/*
  This requires following config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { EnvelopeIcon, PhoneIcon } from "@heroicons/react/20/solid";
import { useEffect, useState } from "react";

// API USER DATA
import {
  doc,
  onSnapshot,
  where,
  collection,
  query,
  getDocs,
  getDoc,
} from "firebase/firestore";
import { auth, db } from "../../../API/Firebase/firebase";
import { UserAuth } from "../../../Context/AuthContext";
import { Outlet, useNavigate } from "react-router-dom";
import { ButtonPrimary } from "../../../Components/Button/Index";
import PopUpModal from "../../../Components/Modal/PopupModal";

import Tabs from "../../../Components/Navigation/Tabs";
import CalendarIndex from "../EventCalendar/Calendar";
import LoadingOverlay from "react-loading-overlay";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function EventNavLayout() {
  const navigate = useNavigate();
  // Event Data

  // Count Active Events
  const [activeEventCount, setActiveEventCount] = useState(0);

  const tabs = [
    // {
    //   name: "Upcoming Events",
    //   href: "/events",
    //   count: activeEventCount,
    //   current: false,
    // },
    // { name: "Past Events", href: "#", count: 0, current: false },
    // { name: "Calendar", href: "/events/calendar", count: 0, current: false },
  ];

  return (
    <>
      <LoadingOverlay
        active={false}
        text="Start warming up your calendar, cos' we have event features coming in hot 🔥"
        // add background color to the overlay
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(255, 255, 255, 0.9)",
          }),
          content: (base) => ({
            ...base,

            color: "black",
          }),

          wrapper: {
            width: "100%",
            height: "100%",
          },
        }}
      >
        <div>
          <div className="md:grid md:grid-cols-3 md:gap-6"></div>
        </div>

        <div className="md:col-span-1">
          <div className="px-4 sm:px-0 text-start justify-between flex">
            <h3 className="text-4xl font-medium leading-6 text-gray-900">
              Events
            </h3>

            <ButtonPrimary
              title={"Add Event"}
              color={"blue"}
              textColor={"white"}
              handleClick={() => {
                navigate("/events/new");
              }}
            />
          </div>
        </div>

        <div className="block" aria-hidden="true">
          <div className="py-5"></div>
        </div>

        <div className="pb-8">
          <div className="">
            <div className="border-b border-gray-200">
              <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                <Tabs tabs={tabs} />
              </nav>
            </div>
          </div>
        </div>
        <Outlet />
      </LoadingOverlay>
    </>
  );
}
