/*
  This requires following config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/

import { Outlet, useNavigate } from "react-router-dom";

export default function EventDetailLayout() {
  return (
    <>
      <Outlet />
    </>
  );
}
