import React from "react";
// Loading animation
import Lottie from "react-lottie";
import animationData from "../../Assets/Animations/Lotties/hypaRotateLottie.json";

export default function HypaSimpleLoadingAnimation() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div className="w-full h-screen flex justify-center items-center">
      <Lottie options={defaultOptions} height={50} width={50} />
    </div>
  );
}
