import { ErrorMessage, Form, Formik, useField } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../../Context/AuthContext";
import * as Yup from "yup";
import hypacardIcon from "../../Assets/Images/hypacardIcon.png";
/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/

const CustomInputField = ({ placeholder, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <div className="col-span-6 sm:col-span-3 space-y-1 pb-1">
      <label
        htmlFor="email"
        className="block text-sm font-medium leading-6 text-gray-900 text-left"
      >
        {props.label}
      </label>
      <div className="mt-2">
        <input
          id={field.name}
          name={field.name}
          type={field.type}
          placeholder={placeholder}
          {...field}
          {...props}
          required
          className="block w-full rounded-lg border-0 py-6 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6 bg-gray-50"
        />
        <p className="text-red-500 text-xs text-left h-8 pt-2">
          {meta.touched && meta.error && <ErrorMessage name={field.name} />}
        </p>
      </div>
    </div>
  );
};

const LoadingSpinner = () => {
  return (
    <div role="status">
      <svg
        aria-hidden="true"
        class="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-indigo-600"
        viewBox="0 0 100 101"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
          fill="currentColor"
        />
        <path
          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
          fill="currentFill"
        />
      </svg>
      <span class="sr-only">Loading...</span>
    </div>
  );
};

export default function SignInIndex() {
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { signIn } = UserAuth();
  const [loading, setLoading] = useState(false);
  const LOGIN_DATA = [
    {
      email: "",
      password: "",
    },
  ];

  const [loginForm, setLoginForm] = useState(LOGIN_DATA);
  const { email, password } = loginForm;

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setLoginForm({ ...loginForm, [name]: value });
  };

  const loginValidation = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Password is required"),
  });

  const handleSubmit = async (e) => {
    // Prevent page from submitting and refreshing
    console.log("submitting");
    setLoading(true);
    await signIn(email, password)
      .then(() => {
        setLoading(false);
        // Redirect to login page
        navigate("/cards");
      })
      .catch((error) => {
        setLoading(false);
        setError(error.message);
        console.log("error signing in", error);
      });
  };

  // .then(() => {
  //   navigate("/cards");
  // })
  // .catch((error) => {
  //   setError(error.message);
  // });

  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-gray-50">
        <body class="h-full">
        ```
      */}
      {/* <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-12 w-auto"
            src="https://firebasestorage.googleapis.com/v0/b/hypa-27b28.appspot.com/o/assets%2FhypacardIcon.png?alt=media&token=76590290-74ed-49c4-86aa-dae9f5ff20f5"
            alt="Your Company"
          />
          <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
            Sign in with email
          </h2>
        </div>

        <div className="mt-2 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 sm:rounded-lg sm:px-10">
            <Formik
              initialValues={{ email: "", password: "" }}
              onSubmit={handleSubmit}
            >
              {({ values, handleChange, handleSubmit }) => (
                <form className="space-y-2" onSubmit={handleSubmit}>
                  <div>
                    <div className="mt-1">
                      <input
                        onChange={(e) => setEmail(e.target.value)}
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        placeholder="Email address"
                        required
                        className="h-16 block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                      />
                    </div>
                  </div>

                  <div>
                    <div className="mt-1">
                      <input
                        onChange={(e) => setPassword(e.target.value)}
                        id="password"
                        name="password"
                        placeholder="Password"
                        type="password"
                        autoComplete="current-password"
                        required
                        className="h-16 block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                      />
                    </div>
                  </div>
                  <div className="h-14">
                    {error && (
                      <p className="text-red-500 text-xs text-center">
                        Your email or password is incorrect. Please try again.
                        <br /> <br />
                        Reset your password if the problem continues.
                      </p>
                    )}
                  </div>

                  <div className="flex items-center justify-between"></div>

                  <div>
                    <button
                      type="submit"
                      className="flex w-full justify-center rounded-full border border-transparent bg-blue-600 py-4 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    >
                      Sign in
                    </button>
                  </div>
                </form>
              )}
            </Formik>
            <button
              type="button"
              onClick={() => navigate("/create")}
              className="inline-flex justify-between rounded-xl py-6 text-base font-medium text-gray-400  hover:text-blue-800"
            >
              <span className="">Need a Hypacard Account? Create One Here</span>
            </button>
            <div className="flex items-center justify-between py-4"></div>

            <div className="text-sm text-right">
              <button
                onClick={() => (
                  navigate("/reset"),
                  {
                    state: { email: email },
                  }
                )}
                className="font-medium text-blue-200 hover:text-blue-500"
              >
                Forgot your password?
              </button>
            </div>
          </div>
        </div>
      </div> */}

      <div className="flex min-h-full">
        <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div className="flex flex-col items-center justify-center w-full">
              <img
                className="h-10 w-auto"
                src={hypacardIcon}
                alt="Your Company"
              />
              <h2 className="mt-6 text-4xl font-bold tracking-tight text-gray-900 text-left">
                Log in
              </h2>
            </div>

            <Formik
              enableReinitialize
              initialValues={{ email, password }}
              onSubmit={handleSubmit}
              validationSchema={loginValidation}
            >
              {(formik) => (
                <Form className="space-y-2" onSubmit={formik.handleSubmit}>
                  <div className="mt-8">
                    <div className="mt-6">
                      <CustomInputField
                        key="email"
                        label="WORK EMAIL"
                        name="email"
                        type="email"
                        placeholder="Email address"
                        onChange={handleOnChange}
                      />
                      <CustomInputField
                        key="password"
                        label="PASSWORD"
                        name="password"
                        type="password"
                        placeholder="Password"
                        onChange={handleOnChange}
                      />

                      <div className="flex items-center justify-between mt-4">
                        <button
                          type="submit"
                          className="flex w-full justify-center items-center rounded-3xl bg-indigo-600 px-3 h-16 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                          {loading ? <LoadingSpinner /> : "Log in to Hypacard"}
                        </button>
                      </div>

                      <div className="flex items-center justify-center py-6">
                        <div className="text-sm">
                          <button
                            onClick={() => (
                              navigate("/reset"),
                              {
                                state: { email: email },
                              }
                            )}
                            className="font-medium text-black hover:text-indigo-500"
                          >
                            Forgot your password?
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        <div className="relative hidden w-0 flex-1 lg:block">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src="https://images.unsplash.com/photo-1505904267569-f02eaeb45a4c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80"
            alt=""
          />
        </div>
      </div>
    </>
  );
}
