import React, { useEffect, useState } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, TimeScale } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { db } from '../../API/Firebase/firebase';
import { collection, getCountFromServer, getDocs } from 'firebase/firestore';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import 'chartjs-adapter-moment'

ChartJS.register(ArcElement, Tooltip, Legend, TimeScale);



export function LineChart({setConnectionsCount, setConnectionsMadeDocs, connectionsMadeDocs}) {


    
  const {teamID} = useParams();
  
    const [cardVisits, setCardVisits] = useState(0);
    const [cardVisitsDocs, setCardVisitsDocs] = useState(0);
    const [connectionsMade, setConnectionsMade] = useState(0);


   // FILTER firestore subcollection by date v9
    const getCardVisits = async () => {
        const cardVisits = collection(db, "teams",teamID , "cardVisits")
       
        const snapshot = await getCountFromServer(cardVisits)
        
        setCardVisits(snapshot.data().count);
        


        // get docs from subcollection and set data to cardVisitsDocs
        const cardVisitsDocs = await getDocs(cardVisits);
        setCardVisitsDocs(cardVisitsDocs.docs.map(doc => doc.data()));

    
    const cardConnections = collection(db, "teams", teamID, "contactsAdded")
       const snapshotConnections = await getCountFromServer(cardConnections)
        setConnectionsMade(snapshotConnections.data().count);
        
        setConnectionsCount(snapshotConnections.data().count)

        // get docs from subcollection and set data to cardVisitsDocs
        const connectionsMadeDocs = await getDocs(cardConnections);
        setConnectionsMadeDocs(connectionsMadeDocs.docs.map(doc => doc.data()));

  
    };
    useEffect(() => {
        getCardVisits();
    }, []);


//   const sortedArray  = cardVisitsDocs.sort((a,b) => new moment(a.date).format('YYYYMMDD') - new moment(b.date).format('YYYYMMDD'))
//  console.log('SORT',sortedArray)
    
    function groupByDate(data, duration) {
        if (data.length > 0 ) {
           function groupBy(data, duration) {
            const formatted = data.map(elem => {
 
        return {
            date: moment(elem.date).startOf(duration).format('YYYY-MM-DD'), count: 1
                }
            })

  const dates = formatted.map(elem => elem.date)
  const uniqueDates = dates.filter((date, index) => dates.indexOf(date) === index)


  return uniqueDates.map(date => {
    const count = formatted.filter(elem => elem.date === date).reduce((count, elem) => count + elem.count, 0)
    return { date, count }
    })
           }
            

         return groupBy(data, 'day')
        }
    }




    // useEffect(() => {
//         if (cardVisitsDocs.length > 0 ) {
//            function groupBy(cardVisitsDocs, duration) {
//             const formatted = cardVisitsDocs.map(elem => {
 
//         return {
//             date: moment(elem.date).startOf(duration).format('YYYY-MM-DD'), count: 1
//                 }
//             })

//   const dates = formatted.map(elem => elem.date)
//   const uniqueDates = dates.filter((date, index) => dates.indexOf(date) === index)
//     console.log('UNIQUE', uniqueDates)

//   return uniqueDates.map(date => {
//     const count = formatted.filter(elem => elem.date === date).reduce((count, elem) => count + elem.count, 0)
//     return { date, count }
//     })
//     }
    
    //         console.log('DATES', groupBy(cardVisitsDocs, 'week'))
    //         setGraphData(groupBy(cardVisitsDocs, 'week'))
    //     }
    // }, [cardVisitsDocs])

    const [cardVisitData, setCardVisitData] = useState()
    const [connectionData, setConnectionData] = useState()
        const data = {
    labels: cardVisitData?.map((item) => item.date),
    datasets: [
        {
            label: 'Card Visits',
            data: cardVisitData?.map((item) => item.count),
            fill: false,
            backgroundColor: 'orange',
            borderColor: 'orange',
        },
        {
            label: 'Connections Made',
            data: connectionData?.map((item) => item.count),
            fill: false,
            backgroundColor: '#4E6AFF',
            borderColor: '#4E6AFF',
        }
    ],
};

    // function orderByDate(data) {
        
    //     return data.sort((a, b) => new moment(a.date).format('YYYYMMDD') - new moment(b.date).format('YYYYMMDD'))
    // }

    useEffect(() => {
        setCardVisitData(groupByDate(cardVisitsDocs, 'day'))
        console.log('connectionsMadeDocs', connectionsMadeDocs)
        // Order connectionsMadeDocs by date
        setConnectionData(groupByDate(connectionsMadeDocs, 'day'))
        console.log('connectionsMadeDocs', connectionsMadeDocs)
    }, [cardVisitsDocs, connectionsMadeDocs])
    


    return <div style={{ width: '100%', height: '600px' }}>
        
        <Line
        data={data}
        options={{scales: {
        y: {
            min: 0,
            ticks: {
                stepSize: 1
            }
            },
        x: {
            type: "time",
            time: {
          // Luxon format string
          tooltipFormat: 'DD T'
        },
      },
           
            
            
        },
     
        maintainAspectRatio: false,
            plugins: {
                legend: {
                    display: true,
                        },
                    },  
                }}
    />
        </div>
}
