import { Formik } from "formik";
import { useState } from "react";
import toast, { ToastBar, Toaster } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { UserAuth } from "../../Context/AuthContext";

/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
export default function PasswordReset() {
  const { state } = useLocation();
  // console.log("state", state);
  const [email, setEmail] = useState(state?.email || "");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { signIn, resetPasswordFunction } = UserAuth();

  const handleSubmit = async () => {
    try {
      await resetPasswordFunction(email);
      toast("Password reset email sent!");
      setTimeout(() => {
        navigate("/signin");
      }, 1200);
    } catch (error) {
      setError(error.message);
      console.log("error resetting password in");
    }
  };

  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-gray-50">
        <body class="h-full">
        ```
      */}

      <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-12 w-auto"
            src="https://firebasestorage.googleapis.com/v0/b/hypa-27b28.appspot.com/o/assets%2FhypacardIcon.png?alt=media&token=76590290-74ed-49c4-86aa-dae9f5ff20f5"
            alt="Your Company"
          />
          <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
            Reset your hypacard password
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            <p className="font-medium text-blue-600 hover:text-blue-500">
              A reset password link will appear within your inbox.
              <br />
              Please check the Junk folder.
            </p>
          </p>
        </div>

        <Formik
          initialValues={{
            email: "",
          }}
          onSubmit={handleSubmit}
        >
          {({ values, handleChange, handleBlur, handleSubmit }) => (
            <form onSubmit={handleSubmit} className="space-y-6">
              <Toaster>
                {(t) => (
                  <ToastBar
                    toast={t}
                    icon={t.icon}
                    containerStyle={{
                      top: 20,
                      left: 20,
                      bottom: 20,
                      right: 20,
                    }}
                    style={{
                      background: "orange",
                      color: "white",

                      zIndex: 1,
                      borderRadius: 10,
                    }}
                  />
                )}
              </Toaster>
              <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md ">
                <div className="bg-white py-8 px-4 sm:rounded-lg sm:px-10 space-y-6">
                  <div className="">
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Email address
                    </label>
                    <div className="mt-1 ">
                      <input
                        onChange={(e) => setEmail(e.target.value)}
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        required
                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                      />
                    </div>
                  </div>

                  <div className="flex items-center justify-between "></div>

                  <div className="">
                    <button
                      type="submit"
                      className="flex w-full justify-center rounded-full border border-transparent bg-blue-600 py-4 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    >
                      Reset Password
                    </button>
                  </div>
                  <div className="flex items-center justify-between w-full">
                    <div className="text-sm text-right w-full">
                      <div
                        onClick={() => navigate("/signin")}
                        className="font-medium text-blue-200 hover:text-blue-500"
                      >
                        Sign In
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </>
  );
}
