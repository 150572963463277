import React from "react";

export function CustomInputTextField({
  label,
  placeholder,
  type,
  name,
  value,
  handleChange,
  onBlur,
  color,
}) {
  return (
    <div className="">
      <label
        htmlFor="email"
        className="block text-base font-medium text-gray-700 text-left"
      >
        {label}
      </label>
      <div className="mt-1">
        <input
          type={type}
          name={name}
          handleChange={handleChange}
          onBlur={onBlur}
          id={type}
          value={value}
          className={`block w-full h-10 pl-2 rounded-md border-${color} border-2 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-base bg-${color}`}
          placeholder={placeholder}
        />
      </div>
    </div>
  );
}
