import React from "react";
import Benefits from "./Sections/Benefits";
import Footer from "./Sections/Footer";
import Header from "./Sections/Header";
import Navbar from "./Sections/Navbar";

function LandingIndex() {
  return (
    <div className="h-full overflow-y-auto">
      <Navbar />
      <div className="">
        <Header />
        <Benefits />
      </div>
      <Footer />
    </div>
  );
}

export default LandingIndex;
