import React from "react";
import { ButtonPrimary, CardButton } from "../../../../Components/Button/Index";
import { CustomInputTextField } from "../../../../Components/Input/Index";
import PopUpModal from "../../../../Components/Modal/PopupModal";
import toast, { ToastBar, Toaster } from "react-hot-toast";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { PaperAirplaneIcon } from "@heroicons/react/24/outline";
import ProfilePictureImage from "../../../../Components/Profile/ProfilePictureImage";
import ProfileHeader from "../../../../Components/Profile/ProfileHeader";
import { httpsCallable } from "firebase/functions";
import { UserAuth } from "../../../../Context/AuthContext";
import { functions } from "../../../../API/Firebase/firebase";
import { useNavigate } from "react-router-dom";

export function ShareWithCardHolder({
  open,
  setOpen,
  card,
  handleCardHolderShareModal,
}) {
  const { user } = UserAuth();
  // console.log(card);
  // function copyToClipboard(text) {
  //   var dummy = document.createElement("textarea");
  //   // to avoid breaking orgain page when copying more words
  //   // cant copy when adding below this code
  //   // dummy.style.display = 'none'
  //   document.body.appendChild(dummy);
  //   //Be careful if you use texarea. setAttribute('value', value), which works with "input" does not work with "textarea". – Eduard
  //   dummy.value = text;
  //   dummy.select();
  //   document.execCommand("copy");
  //   document.body.removeChild(dummy);
  // }

  const sendActivationSMSFunction = ({
    to,
    firstName,
    activationCode,
    cardID,
    organizationID,
  }) => {
    const createActivationCode = httpsCallable(
      functions,
      "createActivationCode"
    );

    const sendSMSActivation = httpsCallable(functions, "sendSMSActivation");

    createActivationCode({
      cardID: cardID,
      phone: to,
      activationCode: activationCode,
      firstName: firstName || "",
      organizationID: organizationID,
    }).then(() => {
      sendSMSActivation({
        to: to,
        firstName: firstName,
        activationCode: activationCode,
        cardID: cardID,
      });
    });
  };

  console.log(card);

  const navigate = useNavigate();

  return (
    <PopUpModal open={open} setOpen={setOpen}>
      <Toaster>
        {(t) => (
          <ToastBar
            toast={t}
            icon={t.icon}
            containerStyle={{
              top: 20,
              left: 10,
              bottom: 20,
              right: 10,
            }}
            style={{
              background: "white",
              color: "black",

              zIndex: 1,
              borderRadius: 10,
            }}
          />
        )}
      </Toaster>
      <div className="flex w-full flex-col text-center space-y-2 ">
        {card?.phone ? (
          <>
            <p className="font-semibold text-2xl pb-2 w-full">
              <div className="opacity-30">
                <ProfileHeader card={card} height="20" />
              </div>
              Activate this Hypacard
            </p>
            <p className="text-center pb-4 px-10">
              Get {card?.firstName || "this cardholder"} up and running by
              sending an SMS to activate this Hypacard on their phone.
            </p>
            <div>
              <div className="w-full flex justify-center items-center">
                <button
                  onClick={() => {
                    console.log(card);
                    sendActivationSMSFunction({
                      to: card?.phone,
                      firstName: card?.firstName || "",
                      activationCode: card?.activationCode || "",
                      cardID: card?.cardID,
                      organizationID: card?.organization,
                    });

                    // toast("Link Copied", { icon: "👍" });

                    toast("Activation Instructions Sent", { icon: "✈️" });
                  }}
                  className="w-full h-20 flex flex-row justify-center items-center mt-4 rounded-md px-4 py-2 bg-blue-600 text-white shadow-xl hover:bg-blue-700 border-2 hover:text-white"
                >
                  <div className="flex justify-center items-center">
                    <PaperAirplaneIcon className="w-8 h-8 -rotate-45" />
                  </div>
                  <div className="flex justify-start items-start flex-col text-left px-2">
                    <span className="">Send SMS Activation</span>
                    {/* <span className="text-sm">
              Activation Code: {card?.activationCode}{" "}
            </span> */}
                  </div>
                </button>
              </div>
              <p className="text-gray-500 pt-2">
                An SMS with instructions will be delivered to{" "}
                {card?.firstName || "this cardholder"}
                's phone: {card?.phone}
              </p>
            </div>
          </>
        ) : (
          <>
            <p className="font-semibold text-2xl pb-2 w-full">
              <div className="opacity-30">
                <ProfileHeader card={card} height="20" />
              </div>
              Phone Number
              <br />
              required to activate
            </p>
            <p className="text-left py-4 px-4 ">
              No phone number exists for this cardholder.
              <br />
              <br />
              Please add a phone number to their profile then re-open this box
              to activate their Hypacard.
            </p>
            <>
              <div className="flex flex-col justify-start items-start bg-gray-800 text-white"></div>

              <div className="w-full flex justify-center items-center">
                <button
                  onClick={() => {
                    // navigate to edit profile
                    navigate("/cards/" + card.cardID + "/edit");
                  }}
                  className="w-full h-20 flex flex-row justify-center items-center mt-4 rounded-md px-4 py-2 bg-blue-600 text-white shadow-xl hover:bg-blue-700 border-2 hover:text-white"
                >
                  <div className="flex justify-start items-start flex-col text-left px-2">
                    <span className="">
                      Add Phone Number to {card?.firstName || "this cardholder"}
                      's Profile
                    </span>
                    {/* <span className="text-sm">
              Activation Code: {card?.activationCode}{" "}
            </span> */}
                  </div>
                </button>
              </div>
            </>
          </>
        )}

        <div className="flex justify-end space-x-2 pt-8">
          <ButtonPrimary title={"Close"} handleClick={() => setOpen(false)} />
        </div>
      </div>
    </PopUpModal>
  );
}
