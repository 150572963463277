import { functions, auth, db, app } from "../../API/Firebase/firebase";
import { Formik } from "formik";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../../Context/AuthContext";
import {
  getAuth,
  PhoneAuthProvider,
  RecaptchaVerifier,
  signInWithCredential,
  signInWithPhoneNumber,
} from "firebase/auth";
import OtpInput from "react-otp-input";
import { httpsCallable } from "firebase/functions";
import {
  doc,
  getDoc,
  setDoc,
  updateDoc,
  Firestore,
  initializeFirestore,
} from "firebase/firestore";
import { customAlphabet } from "nanoid";
import PhoneInput from "react-phone-number-input";
const alphabet = "123456789ABCDEFGHJKLMNOPQRSTUVWXYZ";
const customNanoId = customAlphabet(alphabet, 12);
const customNanoId10 = customAlphabet(alphabet, 10);

/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
export default function SignInPhoneIndex() {
  const [verificationId, setVerificationId] = useState();
  const auth = getAuth(app);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { signIn, user, createBasicUserDoc } = UserAuth();
  const userAuth = user;
  const [countryCode, setCountryCode] = useState("AU");
  const getCountryCode = async () => {
    const response = await fetch("https://ipapi.co/json/");
    const data = await response.json();
    setCountryCode(data.country);
    console.log(countryCode, "country code");
  };

  useEffect(() => {
    getCountryCode();
  }, []);
  const [firstName, setFirstName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [expandForm, setExpandForm] = useState(false);

  const appVerifier = window.recaptchaVerifier;

  const [phone, setPhone] = useState(countryCode);

  const generateRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          // ...
        },
        "expired-callback": () => {
          // Response expired. Ask user to solve reCAPTCHA again.
          // ...
        },
      },
      auth
    );
  };

  const addRoleToNewSignUp = async (user) => {
    console.log("addRoleToNewSignUp USER INFO IN FUNCTION:", user);

    if (!auth.currentUser.role || auth.currentUser.role === undefined) {
      console.log("user doc created");
      console.log("userCREATEPHONEUSER", user);
      const addRole = httpsCallable(functions, "addRole");
      addRole({ phoneNumber: user.phoneNumber, role: 50 })
        .then((result) => {
          console.log("addRole:", result);
          return result.user;
        })
        .then(user.getIdToken(true))
        .then(user.getIdTokenResult(true))
        .then(console.log("user ID TOKEN", user))
        .catch((error) => {
          console.log("Error > addRole:", error);
        });
    } else {
      return user;
    }
  };

  const addNewUserDocument = (user) => {
    const createUserDocument = httpsCallable(functions, "createUserDocument");
    // console.log("!!!createUserDocument", user);
    createUserDocument({
      uid: user.uid,
      email: user?.email,
      displayName: user?.displayName || firstName,
      photoURL: user?.photoURL,
      phoneNumber: user?.phoneNumber,
    });
    return user;
  };

  const requestPhoneCode = async (values) => {
    setFirstName(values?.firstName);
    setPhoneNumber(phoneValue);
    // Prevent page from submitting and refreshing
    console.log("requestPhoneCode", values);
    if (phoneValue?.length === 12) {
      setExpandForm(true);
      generateRecaptcha();
      let appVerifier = window.recaptchaVerifier;

      try {
        const phoneProvider = new PhoneAuthProvider(auth);

        const verificationId = await phoneProvider.verifyPhoneNumber(
          phoneValue,
          appVerifier
        );

        setVerificationId(verificationId);

        // signInWithPhoneNumber(auth, phoneValue, appVerifier)
        // .then(
        //(confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        //   window.confirmationResult = confirmationResult;
        // navigate("/signin/verify");
        //  console.log("SMS sent");
        //   }
        // ...
        // )
      } catch (error) {
        console.log(error);
        setError(error.message);
      }
    }
  };

  const [otp, setOtp] = useState("");

  const verifyOTP = async (code) => {
    const orgID = customNanoId();
    const cardID = customNanoId10();
    try {
      const credential = PhoneAuthProvider.credential(verificationId, code);
      const authResult = await signInWithCredential(auth, credential);
      // console.log("AUTHRESULT", authResult._tokenResponse);
      const user = authResult.user;
      // check registration if new user firebase
      console.log("USER", user);
      if (authResult._tokenResponse.isNewUser) {
        // Signed in
        console.log("authResult", authResult);

        // ...

        addRoleToNewSignUp(user)
          .then(() => addNewUserDocument(user))
          .then(() => {
            const createAccountDoc = httpsCallable(
              functions,
              "createAccountDoc"
            );
            createAccountDoc({
              uid: user.uid,
              orgID: orgID,
            });
            return user;
          })
          .then(() => {
            const createOrgDoc = httpsCallable(functions, "createOrgDoc");
            createOrgDoc({
              uid: user.uid,
              orgID: orgID,
            });
            return user;
          })
          .then(() => {
            const createCardDoc = httpsCallable(functions, "createCardDoc");
            createCardDoc({
              uid: user.uid,
              orgID: orgID,
              cardID: cardID,
              phoneNumber: user.phoneNumber,
            });
            return user;
          })
          .then(() => {
            const addCardDocToAccountDoc = httpsCallable(
              functions,
              "addCardDocToAccountDoc"
            );
            addCardDocToAccountDoc({
              uid: user.uid,
              orgID: orgID,
              cardID: cardID,
              phoneNumber: user.phoneNumber,
            });
            return user;
          })
          .then(() => {
            const cardId = cardID;
            console.log("DONE", cardId);
            navigate("/create/details", {
              state: { cardId, phoneNumber: user?.phoneNumber },
            });
          });
      } else {
        console.log("user already exists");
        navigate("/cards");
      }
    } catch (err) {
      console.log("error", err);
    }
  };
  const [code, setCode] = useState("");
  const handleCodeChange = (code) => setCode(code);
  const [phoneValue, setPhoneValue] = useState("");

  useEffect(() => {
    if (code.length === 6) {
      console.log("code", code);
      // verify otp
      setOtp(code);
      verifyOTP(code);
    }
  }, [code]);

  const handleSubmit = async (e) => {
    // Prevent page from submitting and refreshing
    // await signIn(email, password)
    //   .then(() => {
    //     // Redirect to login page
    //     navigate("/cards");
    //   })
    //   .catch((error) => {
    //     setError(error.message);
    //     // console.log("error signing in", error);
    //   });
  };

  // .then(() => {
  //   navigate("/cards");
  // })
  // .catch((error) => {
  //   setError(error.message);
  // });

  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-gray-50">
        <body class="h-full">
        ```s
      */}
      <div className="flex min-h-full flex-col justify-start py-12 sm:px-6 lg:px-8">
        <div
          className="w-full flex justify-end px-4 sm:px-6 lg:px-8"
          onClick={() => navigate("/")}
        >
          <p className="text-sm text-gray-400">Cancel</p>
        </div>
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-12 w-auto"
            src="https://firebasestorage.googleapis.com/v0/b/hypa-27b28.appspot.com/o/assets%2FhypacardIcon.png?alt=media&token=76590290-74ed-49c4-86aa-dae9f5ff20f5"
            alt="Your Company"
          />
          {expandForm === true ? (
            <>
              <h1 class="text-2xl font-bold">SMS Verification</h1>
              <div class="flex flex-col mt-4">
                <span>Enter the 6 digit code you received at</span>
                <span class="font-bold">{phoneNumber}</span>
              </div>
            </>
          ) : (
            <>
              <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
                Sign Up / Log In
              </h2>
              <div class="flex flex-col mt-4">
                <span>
                  Enter your mobile to
                  <br /> create and access your Hypacard
                </span>
                <span class="font-bold">{phoneNumber}</span>
              </div>
            </>
          )}
        </div>
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-4 px-4  sm:rounded-lg justify-center flex">
            <Formik
              initialValues={{ phone: "" }}
              onSubmit={(values) => {
                requestPhoneCode(phoneValue);
              }}
            >
              {({ values, handleChange, handleSubmit, handleBlur }) => (
                <form className="space-y-2" onSubmit={handleSubmit}>
                  {expandForm !== true ? (
                    <>
                      {/* <div>
                        <div className="mt-1">
                          <input
                            onChange={handleChange}
                            onBlur={handleBlur}
                            id="firstName"
                            name="firstName"
                            type="firstName"
                            autoComplete="firstName"
                            placeholder="First name (e.g. Jill)"
                            required
                            className="h-16 block w-full appearance-none rounded-xl border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm bg-slate-100"
                          />
                        </div>
                      </div> */}

                      <div>
                        <div className="mt-1">
                          <div className="col-span-6 sm:col-span-6">
                            <PhoneInput
                              defaultCountry={countryCode || "AU"}
                              placeholder="Enter phone number..."
                              value={phoneValue}
                              onChange={setPhoneValue}
                              style={{
                                width: "100%",

                                borderRadius: "10px",
                              }}
                              className="mt-1 block w-full  text-base"
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  ) : null}
                  {expandForm === true ? (
                    <div>
                      {/* <div className="mt-1">
                        <input
                          onChange={verifyOTP}
                          id="otp"
                          name="otp"
                          placeholder="Enter code"
                          type="number"
                          value={otp}
                          autoComplete="OTP"
                          required
                          className="h-16 block w-full appearance-none rounded-xl border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm bg-slate-100"
                        />
                      </div> */}

                      <OtpInput
                        value={code}
                        onChange={handleCodeChange}
                        numInputs={6}
                        separator={<span style={{ width: "8px" }}></span>}
                        isInputNum={true}
                        shouldAutoFocus={true}
                        inputStyle={{
                          border: "1px solid gray",
                          borderRadius: "8px",
                          width: "54px",
                          height: "54px",
                          fontSize: "16px",
                          color: "#000",
                          fontWeight: "400",
                          caretColor: "blue",
                        }}
                        focusStyle={{
                          border: "1px solid #000",
                          outline: "none",
                        }}
                      />
                    </div>
                  ) : null}

                  <div className="h-14 hidden">
                    {error && (
                      <p className="text-red-500 text-xs text-center">
                        Your email or password is incorrect. Please try again.
                        <br /> <br />
                        Reset your password if the problem continues.
                      </p>
                    )}
                  </div>

                  <div className="flex items-center justify-between"></div>
                  {expandForm !== true ? (
                    <>
                      <div>
                        <button
                          type="submit"
                          className={`flex w-full justify-center rounded-full border border-transparent  py-4 px-4 text-sm font-medium text-white shadow-sm  focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 ${
                            phoneValue !== undefined && phoneValue.length >= 12
                              ? "bg-blue-700 cursor-pointer hover:bg-blue-800"
                              : "bg-blue-200 cursor-not-allowed"
                          }`}
                        >
                          <p>Send my SMS code</p>
                        </button>
                      </div>
                    </>
                  ) : null}
                  <div id="recaptcha-container"></div>
                </form>
              )}
            </Formik>

            {/* UPDATE: SOCIAL LOGINS */}
            {/* <div className="mt-6">
              <div className="relative">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="bg-white px-2 text-gray-500">
                    Or continue with
                  </span>
                </div>
              </div>

              <div className="mt-6 grid grid-cols-3 gap-3">
                <div>
                  <a
                    href="#"
                    className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-500 shadow-sm hover:bg-gray-50"
                  >
                    <span className="sr-only">Sign in with Facebook</span>
                    <svg
                      className="h-5 w-5"
                      aria-hidden="true"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fillRule="evenodd"
                        d="M20 10c0-5.523-4.477-10-10-10S0 4.477 0 10c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V10h2.54V7.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V10h2.773l-.443 2.89h-2.33v6.988C16.343 19.128 20 14.991 20 10z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </a>
                </div>

                <div>
                  <a
                    href="#"
                    className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-500 shadow-sm hover:bg-gray-50"
                  >
                    <span className="sr-only">Sign in with Twitter</span>
                    <svg
                      className="h-5 w-5"
                      aria-hidden="true"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                    </svg>
                  </a>
                </div>

                <div>
                  <a
                    href="#"
                    className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-500 shadow-sm hover:bg-gray-50"
                  >
                    <span className="sr-only">Sign in with GitHub</span>
                    <svg
                      className="h-5 w-5"
                      aria-hidden="true"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 0C4.477 0 0 4.484 0 10.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0110 4.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.203 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.942.359.31.678.921.678 1.856 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0020 10.017C20 4.484 15.522 0 10 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div> */}
          </div>
          <div className="w-full justify-center items-center flex">
            <button
              onClick={() => navigate("/signin")}
              className="flex justify-center rounded-full border border-transparent py-2 px-4 text-sm font-medium text-gray-400 hover:text-white shadow-sm hover:bg-gray-400 focus:outline-none"
            >
              <p>Log In With Email Instead</p>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
