import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { saveVCard } from "../../Functions/Card/VCard";
import hypacardIcon from "../../Assets/Images/hypacardIcon.png";
// Modal Bottom Sheet
import Sheet from "react-modal-sheet";

import { isAndroid, isBrowser, isIOS } from "react-device-detect";
import AddContactBrowser from "../../Assets/Images/AddContactBrowser.png";
import AddContactAndroid from "../../Assets/Images/AddContactAndroid.png";
import AddContactIos from "../../Assets/Images/AddContactIos.png";

// React Confetti
import { useWindowSize, useTimeout } from "react-use/lib";
import Confetti from "react-confetti";

function BelowFold({ card, setOpen }) {
  // Check URL for http or https

  var pattern = /^((http|https|ftp):\/\/)/;

  const navigate = useNavigate();
  const [showLinks, setShowLinks] = useState(false);
  const [instructionsModalIsOpen, setInstructionsModalIsOpen] = useState(false);
  const [confettiIsOpen, setConfettiIsOpen] = useState(false);
  const { width, height } = useWindowSize();

  useEffect(() => {
    if (card?.links?.length > 0) {
      setShowLinks(true);
    } else setShowLinks(false);
  }, [card]);

  const confettiTimer = () => {
    setConfettiIsOpen(true);
  };

  const [isComplete] = useTimeout(10000);

  const handleLinkClick = (link) => {
    console.log("link", link);

    if (!pattern.test(link)) {
      const url = "https://" + link;
      window.open(url, "_blank");
    } else {
      window.open(link, "_blank");
    }
  };

  return (
    <div className="mx-auto md:w-1/3 z-100">
      {confettiIsOpen ? (
        <Confetti
          width={width}
          height={height}
          numberOfPieces={250}
          recycle={!isComplete()}
        />
      ) : (
        <> </>
      )}
      <DownloadInstructionsModal
        card={card}
        isOpen={instructionsModalIsOpen}
        setOpen={setInstructionsModalIsOpen}
        saveVCard={saveVCard}
        confettiTimer={confettiTimer}
      />
      {/* Top of profile action buttons */}
      <div className=" w-full mt-2 flex flex-col py-4 ">
        <div className="grid grid-cols-8 gap-2">
          <div className="col-span-7 ">
            <button
              type="button"
              onClick={() => setOpen(true)}
              style={{
                backgroundColor: card?.primaryBrandColor || "#000000",
                color: card?.companyPrimaryTextColor || "#ffffff",
              }}
              className={`flex w-full justify-center rounded-2xl border border-gray-300 
              }] py-6 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-5 h-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                />
              </svg>
              <span className="ml-2">Exchange Contact Details</span>
            </button>
          </div>
          <div className="col-span-1">
            <button
              type="button"
              onClick={() => {
                setInstructionsModalIsOpen(true);
              }}
              className="w-full flex justify-center items-center text-center rounded-2xl border border-gray-200 bg-white py-6 text-sm font-medium text-gray-500  hover:bg-blue-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-5 h-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
                />
              </svg>
            </button>
          </div>
        </div>

        <div className="justify-stretch mt-10 flex flex-col space-y-4">
          {showLinks ? (
            <div className="flex flex-col w-full space-y-2 ">
              <p className="text-left text-gray-500">Links</p>
              {card?.links.map((link) => (
                <button
                  type="button"
                  onClick={() => {
                    handleLinkClick(link.url);
                  }}
                  className="inline-flex justify-between rounded-xl bg-gradient-to-tr border border-gray-100 from-gray-100 to-white px-4 py-6 text-xl font-medium text-black shadow-lg hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                >
                  <span className="truncate ml-2">{link.name}</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M8.25 4.5l7.5 7.5-7.5 7.5"
                    />
                  </svg>
                </button>
              ))}{" "}
            </div>
          ) : null}
        </div>
      </div>
      <div className="w-full justify-center items-center flex pt-6 pb-44 text-center ">
        <a
          type="button"
          href="https://hypa.to/download"
          target="_blank"
          rel="noreferrer"
          className="inline-flex justify-between rounded-xl  py-6 text-base font-medium text-blue-800/30  hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
        >
          <span className="">Create Your Own Hypacard</span>
          <img className="ml-1 h-4 w-4 opacity-30" src={hypacardIcon} alt="" />
        </a>
      </div>
    </div>
  );
}

export default BelowFold;

const DownloadInstructionsModal = ({
  card,
  isOpen,
  setOpen,
  saveVCard,
  confettiTimer,
}) => {
  return (
    <div className="h-full flex items-center justify-center py-0 px-4 sm:px-6 lg:px-8">
      <div>
        <Sheet
          isOpen={isOpen}
          onClose={() => setOpen(false)}
          snapPoints={[0.7, 0.7, 0, 0]}
          initialSnap={1}
        >
          <Sheet.Container>
            <Sheet.Header></Sheet.Header>
            <Sheet.Content>
              <DownloadInstructions
                card={card}
                setOpen={setOpen}
                saveVCard={saveVCard}
                confettiTimer={confettiTimer}
              />
            </Sheet.Content>
          </Sheet.Container>

          <Sheet.Backdrop />
        </Sheet>
      </div>
    </div>
  );
};

function DownloadInstructions({ card, setOpen, saveVCard, confettiTimer }) {
  const handleClick = () => {
    setOpen(false);
    saveVCard(card);
    confettiTimer();
  };

  return (
    <div className="flex flex-col h-screen items-center">
      {isBrowser ? (
        <>
          <>
            <div className="flex px-4 items-center">
              <div className="flex pb-2 items-start text-left ">
                <p className="font-semibold text-zinc-800 text-xl text-left text-blue-700">
                  Open Download
                  <br />
                  <span className="text-slate-700">to import contact</span>
                </p>
              </div>
            </div>
            <div className="justify-center items-center flex flex-col px-4">
              <div className="flex w-full justify-center items-center">
                <div className="flex justify-center items-center pt-1">
                  <img
                    src={AddContactBrowser}
                    alt="Add Contact Steps"
                    className="w-full justify-center items-center"
                  />
                </div>
              </div>
              <div className="flex items-start w-full justify-end items-center justify-center w-full md:w-full py-2">
                <button
                  onClick={() => {
                    handleClick();

                    // contactCard(true);
                  }}
                  className="mt-1 inline-flex items-center w-full py-3 border rounded-xl shadow-sm text-sm font-medium text-white bg-gradient-to-br from-black via-zinc-900 to-zinc-800 hover:to-zinc-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 min-w-max"
                >
                  <p className="text-gray-100 font-semibold text-center text-lg justify-center items-center min-w-64 w-full">
                    OK
                  </p>
                </button>
              </div>
            </div>
          </>
        </>
      ) : (
        <></>
      )}
      {isIOS ? (
        <>
          <>
            <div className="text-left  w-full pl-6">
              <div className="pb-2 items-start text-left justify-left">
                <div className="font-base text-zinc-800 text-lg">
                  <p className="text-left">
                    <span className="text-blue-700"> Create New Contact</span>
                    <span className="font-normal">
                      <br />
                      to save {card?.firstName}'s contact info
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="items-start w-full py-2 px-2 min-w-max">
              <div className="flex w-full justify-center items-center">
                <div className="flex justify-center items-center pt-1">
                  <div className="flex justify-center items-center h-52">
                    <img
                      src={AddContactIos}
                      alt="Add Contact Steps"
                      className="w-full justify-center items-center"
                    />
                  </div>
                </div>
              </div>
              <div className="flex items-start w-full justify-end items-center justify-center py-2 min-w-max px-4">
                <button
                  onClick={() => {
                    handleClick();
                    // contactCard(true);
                  }}
                  className="mt-1 inline-flex items-center w-full py-3 border rounded-xl shadow-sm text-sm text-white  bg-gradient-to-br from-black via-zinc-900 to-zinc-800 hover:to-zinc-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  <p className="text-gray-100 font-semibold text-center text-xl justify-center items-center w-full">
                    OK
                  </p>
                </button>
              </div>
            </div>
          </>
        </>
      ) : (
        <></>
      )}

      {isAndroid ? (
        <>
          <>
            <div className="flex w-full px-4 md:w-1/3">
              <div className="pb-2 items-start text-left justify-left md:items-center md:justify-center ">
                <p className="font-semibold text-zinc-800 text-xl text-left md:items-center md:justify-center justify-left text-blue-700">
                  Download and Open File
                  <br />
                  <span className="text-slate-700">to save contact</span>
                </p>
              </div>
            </div>
            <div className="items-center w-full py-2 px-4 min-w-max">
              <div className="flex w-full justify-center items-center">
                <div className="flex justify-center items-center pt-1">
                  <img
                    src={AddContactAndroid}
                    alt="Add Contact Steps"
                    className="w-full justify-center items-center"
                  />
                </div>
              </div>
              <div className="items-start w-full py-2 min-w-max px-2">
                <button
                  onClick={() => {
                    handleClick();
                    // contactCard(true);
                  }}
                  className="mt-1 inline-flex items-center w-full py-3 border rounded-xl shadow-sm text-sm font-medium text-white bg-gradient-to-br from-black via-zinc-900 to-zinc-800 hover:to-zinc-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 min-w-max"
                >
                  <p className="text-gray-100 font-semibold text-center text-xl justify-center items-center w-full">
                    OK
                  </p>
                </button>
              </div>
            </div>
          </>
        </>
      ) : (
        <></>
      )}
    </div>
  );
}
