import { Field, FieldArray, Formik } from "formik";
// Form validation
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";

import { useState, useEffect } from "react";

import { nanoid } from "nanoid";

import { PulseLoader } from "react-spinners";
import { UserAuth } from "../../Context/AuthContext";
import ProfilePictureImage from "../../Components/Profile/ProfilePictureImage";
import { CheckIcon, ExclamationCircleIcon } from "@heroicons/react/20/solid";
import { arrayUnion, doc, setDoc, updateDoc } from "firebase/firestore";
import { db } from "../../API/Firebase/firebase";

const createTeamSchema = Yup.object().shape({
  title: Yup.string()
    .min(2, "Too Short")
    .max(50, "Too long")
    .required("Team Name is required"),
  description: Yup.string()
    .min(2, "Too Short")
    .max(50, "Too long")
    .required("Team Description is required"),
  // add selectedCards to the validation schema and make sure it has at least one member
  selectedCards: Yup.array().min(1, "Please select at least one team member"),
});

export function CreateTeamInputForm() {
  const { cards, userProfile } = UserAuth();
  const navigate = useNavigate();
  const organizationID = userProfile.organizations[0];

  const [selectedCardsAlert, setSelectedCardsAlert] = useState(false);
  const handleSubmit = (values) => {
    if (selectedCards.length === 0) {
      setSelectedCardsAlert(true);
      return;
    } else {
      setSelectedCardsAlert(false);
      console.log(values);
      // map through each card id in the selectedCards array and add to a new array
      const teamMemberIDs = [];
      selectedCards.map((card) => {
        teamMemberIDs.push({
          // add key as value and value as the cardID
          [card.cardID]: card.cardID,
          cardID: card.cardID,
          profilePictureUrl: card?.profilePictureUrl || "",
          firstName: card?.firstName || "",
          lastName: card?.lastName || "",
        });
      });
      console.log("memberIDS", teamMemberIDs);

      // Create a new team document in the organizations collection and then adding the team document id to the user's teams array
      const teamId = nanoid();
      const teamDoc = {
        id: teamId,
        title: values.title,
        description: values.description,
        members: teamMemberIDs,
        createdAt: new Date(),
        updatedAt: new Date(),
      };
      console.log("teamDOCC", teamDoc);
      const teamDocRef = doc(
        db,

        "teams",
        teamId
      );
      const setTeamDoc = async () => {
        await setDoc(teamDocRef, teamDoc, { merge: true });
      };

      const teamOrganizationDocRef = doc(
        db,
        "organizations",
        organizationID,
        "teams",
        teamId
      );
      const setTeamOrganizationDoc = async () => {
        await setDoc(teamOrganizationDocRef, teamDoc, { merge: true });
        console.log("Team doc written with ID: ", teamId);
      };

      const userTeamObject = {
        id: teamId,
        title: values.title,
        description: values.description,
        dateCreated: new Date(),
      };
      // map through each card id in the selectedCards array and add the team id to the card's teams array
      selectedCards.map((card) => {
        const cardDocRef = doc(db, "cards", card.cardID);
        const setCardDoc = async () => {
          await updateDoc(
            cardDocRef, //arrayUnion
            { teams: arrayUnion(userTeamObject) },

            { merge: true }
          );
        };
        setTeamDoc()
          .then(setTeamOrganizationDoc())
          .then(setCardDoc())
          .then(navigate("/teams/" + teamId));
        console.log("Card doc written with ID: ", card.cardID);
        return null;
      });
    }
  };

  function TextFieldLinks({
    label,
    name,
    value,
    icon,
    onChangeText,
    onBlur,
    remove,
    index,
    ...props
  }) {
    return (
      <div className="flex w-full">
        <div className="col-span-6 sm:col-span-2">
          <label
            htmlFor="first-name"
            className="block text-sm font-medium text-gray-700"
          >
            {label}
          </label>
          <Field
            type="text"
            name={name}
            placeholder={label}
            onChangeText={onChangeText}
            onBlur={onBlur}
            value={value}
            autoCapitalize="none"
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
          />
        </div>
      </div>
    );
  }

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedCards, setSelectedCards] = useState([]);

  useEffect(() => {
    console.log("SelectedCards", selectedCards);
  }, [selectedCards]);

  return (
    <>
      <div>
        <div className="md:grid md:grid-cols-3 md:gap-6"></div>
      </div>

      <Formik
        initialValues={{
          title: "",
          description: "",
        }}
        enableReinitialize={true}
        validateOnMount={true}
        validationSchema={createTeamSchema}
        onSubmit={async (values, { setSubmitting }) => {
          handleSubmit(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,

          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            <div class="fixed bottom-0 w-full md:grid md:grid-cols-2 ">
              <div className="" aria-hidden="true">
                <div className="border-t border-gray-200" />
                <div className="mb-4 w-40 z-100 float-center px-5 py-3  justify-center rounded-md border border-transparent bg-blue-600  px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
                  {isSubmitting ? (
                    <PulseLoader color={"white"} loading={true} size={8} />
                  ) : (
                    <button
                      type="submit"
                      disabled={""}
                      className=" text-sm font-medium text-white shadow-sm hover:bg-blue-700 \"
                    >
                      Create Team
                    </button>
                  )}
                </div>
              </div>
            </div>

            <div className="mt-10 sm:mt-0">
              <div className="md:grid md:grid-cols-3 md:gap-6">
                <div className="md:col-span-1">
                  <div className="px-4 sm:px-0 text-left">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                      Team Information
                    </h3>
                    <p className="mt-1 text-sm text-gray-600"></p>
                  </div>
                </div>
                <div className="mt-5 md:col-span-2 md:mt-0">
                  <div className="overflow-hidden sm:rounded-md text-left">
                    <div className="bg-white px-4 py-5 sm:p-6">
                      <div className="grid grid-cols-6 gap-6">
                        <div className="col-span-6 sm:col-span-6">
                          <label
                            htmlFor="first-name"
                            className="block text-sm font-medium text-gray-700 flex flex-row justify-start items-center"
                          >
                            Team name{" "}
                            <p className="ml-2 text-sm text-red-400 flex flex-row">
                              {errors.title &&
                                touched.title &&
                                errors.title && (
                                  <ExclamationCircleIcon
                                    className="mr-1.5 h-5 w-5 flex-shrink-0 text-red-400"
                                    aria-hidden="true"
                                  />
                                )}
                              {errors.title && touched.title && errors.title}
                            </p>
                          </label>
                          <input
                            type="text"
                            name="title"
                            placeholder="Add your team name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.title}
                            id="title"
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                          />
                        </div>

                        <div className="col-span-6 sm:col-span-6">
                          <div className="col-span-3 sm:col-span-2">
                            <label
                              htmlFor="first-name"
                              className="block text-sm font-medium text-gray-700 flex flex-row justify-start items-center"
                            >
                              Team description{" "}
                              <p className="ml-2 text-sm text-red-400 flex flex-row">
                                {errors.description &&
                                  touched.description &&
                                  errors.description && (
                                    <ExclamationCircleIcon
                                      className="mr-1.5 h-5 w-5 flex-shrink-0 text-red-400"
                                      aria-hidden="true"
                                    />
                                  )}
                                {errors.description &&
                                  touched.description &&
                                  errors.description}
                              </p>
                            </label>
                            <textarea
                              type="textarea"
                              name="description"
                              rows={3}
                              placeholder="Include a team description"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.description}
                              id="description"
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            />
                            <div className="mt-1 flex rounded-md shadow-sm"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="hidden sm:block" aria-hidden="true">
              <div className="py-5">
                <div className="border-t border-gray-200" />
              </div>
            </div>

            <div className="mt-10 sm:mt-0">
              <div className="md:grid md:grid-cols-3 md:gap-6">
                <div className="md:col-span-3">
                  <div className="px-4 sm:px-0 text-left">
                    <h3 className="text-lg font-medium leading-6 text-gray-900 flex flex-row items-center">
                      Add Team Members{" "}
                      <p className="ml-2 text-sm text-red-400 flex flex-row">
                        {
                          // create error if selectedCards is empty

                          selectedCardsAlert && (
                            <>
                              <ExclamationCircleIcon
                                className="mr-1.5 h-5 w-5 flex-shrink-0 text-red-400"
                                aria-hidden="true"
                              />
                              <p>At least one team member is required</p>
                            </>
                          )
                        }
                      </p>
                    </h3>
                    <p className="mt-1 text-sm text-gray-600">
                      <p className="mt-2 text-sm text-gray-600"></p>
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-full text-left py-4 flex flex-row flex-wrap pt-2">
                {cards.map((card) => (
                  <div
                    onClick={() => {
                      // If card exists in selectedCards, then remove it
                      if (selectedCards.includes(card)) {
                        setSelectedCards(
                          selectedCards.filter((c) => c !== card)
                        );

                        if (selectedCards.length === 0) {
                          setSelectedCardsAlert(true);
                        }

                        return;
                      }
                      // Else add it
                      else {
                        setSelectedCardsAlert(false);
                        setSelectedCards([...selectedCards, card]);
                      }
                    }}
                    className="pb-8"
                  >
                    <div
                      // Format so that if card exists in selectedCards, then it has opacity 100
                      className={`${
                        selectedCards.includes(card)
                          ? "opacity-100"
                          : "opacity-50"
                      }  w-60 text-left mx-2 flex flex-row justify-start text-center items-center cursor-pointer hover:opacity-100
                      hover:ring-2 hover:ring-blue-500 hover:ring-offset-4 rounded-full`}
                    >
                      <div
                        className="relative h-20  rounded-full 
                                "
                      >
                        <div
                          // Format so that if card exists in selectedCards, then it has a blue tick in the top right corner of the image
                          className={`${
                            selectedCards.includes(card)
                              ? "opacity-100"
                              : "opacity-0"
                          } z-20 absolute top-0 left-0 h-6 w-6 rounded-full bg-green-500 flex items-center justify-center`}
                        >
                          <CheckIcon
                            className="h-4 w-4 text-white"
                            aria-hidden="true"
                          />
                        </div>
                        <ProfilePictureImage
                          height="20"
                          card={card}
                          noLogo={true}
                          noBorder={true}
                        />
                      </div>
                      <div className="flex flex-col justify-start items-center">
                        <div className="pt-2 text-sm truncate ml-2">
                          {card?.firstName} {`\n`}
                          {card?.lastName}
                        </div>
                        {card?.role ? (
                          <div className="truncate mt-2 text-sm text-white font-semibold p-1 px-2 bg-blue-700 rounded-full">
                            {card?.role}
                          </div>
                        ) : (
                          <div className="h-8"></div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}
